import React from "react";
import { FooterStyles } from "./Footer.style";

const Footer = () => {
  return (
    <>
      <FooterStyles>
        <section className="contact-area" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="contact-content text-center">
                  <a href="#">
                    <h1>Kesh Fashion</h1>
                  </a>
                  <p className="contact-quote">
                    "If you don't care your customer, Somebody else will"
                  </p>
                  <div className="hr"></div>
                  <h6>Kesh Fashion House, Pune.</h6>
                  <h6>+91 2345 6789 12</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <p>Copyright &copy; 2022 Kesh Fashion, All Rights Reserved.</p>
        </footer>
      </FooterStyles>
    </>
  );
};

export default Footer;
