import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
// import { SlidebarData } from "./SlidebarData";
import Slidemenu from "./Slidemenu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/userSlice";
import Logo from "components/common/Logo";
import UserData from "./UserData";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
const Nav = styled.div`
  position: relative;
  background-color: var(--color-primary);
  height: 50px;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  .logo-span {
    // position: absolute;
    // right: 10%;
  }
  .main-nav-bar {
    display: flex;
    align-items: space-between;
  }
  a {
    color: var(--light) !important;
    font-size: 1.4rem;
  }
`;
const NavIcon = styled(Link)`
  margin-left: 2rem;
  margin-top: 0.5rem;
  font-size: 1.6rem;
  color: #fff;
  /* margin-top: 3rem; */
`;
const SlidebarNav = styled.nav`
  background-color: var(--color-dark);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;
const SlidebarWrap = styled.nav`
  width: 100%;
`;
const IconStyle = styled.nav`
  margin-left: 8%;
  margin-top: -6%;
`;
const Divstyle = styled.ul`
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.6rem;
  gap: 1rem;
  // position: absolute;
  // right: 10px;
  li {
    list-style: none;
    // padding: 0 0.5rem;
  }
  li:hover {
    cursor: pointer;
    color: green;
  }
`;
function Slidebar() {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const { loading, error, user, userToken, isAuthenticated } = useSelector(
    (state) => state?.user
  );

  const dispatch = useDispatch();
  let authLinks;

  const [SlidebarData, setSlidebarData] = useState([
    {
      title: "Dashboard",
      path: "/",
      icon: <AiIcons.AiFillHome />,
    },
    {
      title: "Job Card",
      path: "/jobcard",
      icon: <AiIcons.AiFillHome />,
    },
    {
      title: "Cloth Inventory",
      path: "/inventory",
      icon: <MdIcons.MdOutlineInventory2 />,
     
    },
   
    {
      title: "Material Inventory",
      path: "/material",
      icon: <IoIcons.IoIosPaper />,
    },
    {
      title: "Caps Requirement",
      path: "/caps",
      icon: <IoIcons.IoIosPaper />,
    },
    {
      title: "Reports",
      path: "/reports",
      icon: <FaIcons.FaClipboardList />,
    },
    {
      title: "Created Cap",
      path: "/createdcap",
      icon: <FaIcons.FaCartPlus />,
    },
    {
      title: "Inventory Report",
      path: "/invenreport",
      icon: <FaIcons.FaCartPlus />,
    },
  ]);

  useEffect(() => {
    const userx = JSON.parse(localStorage.getItem("user"));
    console.log("aplha", userx);
    if (userx && userx?.roll) {
      if (
        userx?.roll === "designer" ||
        userx?.roll === "account" ||
        userx?.roll === "admin"
      ) {
      } else {
        setSlidebarData([
          {
            title: "Job Card",
            path: "/jobcard",
            icon: <AiIcons.AiFillHome />,
          },
        ]);
      }
    } else {
      setSlidebarData([
        {
          title: "Dashboard",
          path: "/",
          icon: <AiIcons.AiFillHome />,
        },
        {
          title: "Job Card",
          path: "/jobcard",
          icon: <AiIcons.AiFillHome />,
        },
        {
          title: "Cloth Inventory",
          path: "/inventory",
          icon: <MdIcons.MdOutlineInventory2 />,
         
        },
       
        {
          title: "Material Inventory",
          path: "/material",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Caps Requirement",
          path: "/caps",
          icon: <IoIcons.IoIosPaper />,
        },
        {
          title: "Reports",
          path: "/reports",
          icon: <FaIcons.FaClipboardList />,
        },
        {
          title: "Created Cap",
          path: "/createdcap",
          icon: <FaIcons.FaCartPlus />,
        },
        {
          title: "Inventory Report",
          path: "/invenreport",
          icon: <FaIcons.FaCartPlus />,
        },
      ]);
    }
  }, [localStorage.getItem("user")]);

  if (user) {
    authLinks = (
      <Divstyle>
        <li
          style={{
            textAlign: "right",
            color: "#fff",

            fontSize: "1.3rem",
          }}>
          Logged In as: <b>{user.user}</b>
          <span
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
            }}></span>
        </li>
        <li style={{ fontSize: "1.3rem" }} onClick={() => dispatch(logout())}>
          Logout
        </li>
      </Divstyle>
    );
  } else {
    authLinks = (
      <div
        className="login-btn"
        style={{
          textAlign: "right",
          color: "#fff",
          padding: " 0 2rem ",
          fontSize: "1.6rem",
        }}>
        <Link to="/login">Login</Link>
      </div>
    );
  }

  //
  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        event.target.closest("#slidebar") ||
        event.target.closest("#navbar")
      ) {
        return;
      } else {
        setSidebar(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  const showSidebar = () => {
    console.log("hit");
    setSidebar(!sidebar);
  };
  const Autoclose = () => setSidebar(false);
  return (
    <>
      <Style>
        <Nav id="navbar" className="main-nav-bar">
          {isAuthenticated?

<NavIcon>
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>:null
          }
          <span className="logo-span">
            <Logo />
          </span>
          {authLinks}
        </Nav>

        <SlidebarNav id="slidebar" sidebar={sidebar}>
          <SlidebarWrap>
            <NavIcon>
              <IconStyle>
             <AiIcons.AiOutlineClose onClick={showSidebar} />
              </IconStyle>
            </NavIcon>
            <div id="check_pppp" onClick={showSidebar}>
              {SlidebarData.map((item, index) => {
                return (
                  <Slidemenu item={item} key={index} onClick={Autoclose} />
                );
              })}
            </div>
          </SlidebarWrap>
        </SlidebarNav>
      </Style>
    </> //
  );
}

export default Slidebar;

const Style = styled.div``;
