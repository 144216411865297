import React from "react";
import styled from "styled-components";

const Logo = () => {
  return (
    <>
      <Style>
        <div className="logo">
          <h2>Versatile Apparels</h2>
        </div>
      </Style>
    </> //
  );
};

export default Logo;
const Style = styled.div`
  display: flex;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 130px;
    height: 70px; */
    /* margin-bottom: 1rem; */
    h2{
      font-weight: 600;
      color: white;
    }
    .img {
      width: 130px;
      height: 40px;
    }
  }
`;
