import { getUser, logout } from "features/auth/userSlice";
import {
  useFetchClientJobQuery,
  useFetchCoordinatorsJobQuery,
  useFetchDeliveryDateJobQuery,
  useFetchInventoryQuery,
  useFetchJobcardsQuery,
  useFetchTopCardCountQuery,
  useGetClientsQuery,
} from "features/store";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import ForMat from "./charts/forMat";
import ForJobCard from "./charts/forJobCard";
import ForClothtype from "./charts/forClothtype";
import "./charts/charts.css";
import BarGraph from "./charts/barGraph";
import Mountain from "./charts/Mountain";
import Loader from "components/common/Loader";

const Dashboard = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();


  const { data: top_cards, isLoading: loading_top_cards } = useFetchTopCardCountQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: delivery_cards, isLoading: deliveryloading_cards } = useFetchDeliveryDateJobQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: coordinator_cards, isLoading: coordinator_loading_cards } = useFetchCoordinatorsJobQuery({
    refetchOnMountOrArgChange: true,
  });
  const { data: client_cards, isLoading: client_loading_cards } = useFetchClientJobQuery({
    refetchOnMountOrArgChange: true,
  });
  // console.log(clients)
  // console.log(
  //   "cards",
  //   cards?.filter((item) => item.current_stage)
  // );

  const [data_bar, setdata_bar] = useState();
  const [data_bar_client, setdata_bar_client] = useState();

useEffect(() => {
if(coordinator_cards){
  const newDataForCount = coordinator_cards.map((item) => ({
    year: item?.job_coordinator,
    value: item?.job_count,
  }));
  setdata_bar(newDataForCount)
}
}, [coordinator_cards]);
useEffect(() => {
if(client_cards){
  const newDataForCount = client_cards.map((item) => ({
    year: item?.client,
    value: item?.job_count,
  }));
  setdata_bar_client(newDataForCount)
}
}, [client_cards]);

  
  // const bar_graph=()=>{
  //   const clientCounts = {};
  // const new_cards = cards?.filter((item)=>item.client)
  // new_cards?.forEach(item => {
  //   const client = item.client;
  //   clientCounts[client] = (clientCounts[client] || 0) + 1;
  // });

  // const uniqueClients = Object.keys(clientCounts).map(client => ({
  //   client: parseInt(client),
  //   count: clientCounts[client],
  // }));

  // console.log(uniqueClients);
  // setdata_bar(uniqueClients)
  // }





  const { data: doted, isLoadingdoted } = useFetchInventoryQuery({
    cloth_type: "Doted",
  });
  const { data: flexi, isLoadingflexi } = useFetchInventoryQuery({
    cloth_type: "Flexi stock",
  });
  
  const { data: twill, isLoadingtwill } = useFetchInventoryQuery({
    cloth_type: "Twill Cotton",
  });
  const { data: grandel, isLoadinggrandel } = useFetchInventoryQuery({
    cloth_type: "Grandle",
  });
  const { data: mesh, isLoadingmesh } = useFetchInventoryQuery({
    cloth_type: "Mesh [net]",
  });
  const { data: CAP, isLoading:isLoadingCAP } = useFetchInventoryQuery({
    type: "CAP MATERIAL",
  });

  // console.log(colors?.filter((item)=>item.cloth_type))
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const cardsForjob = [
    {
      type: "Completed",
      value: top_cards?.filter((item) => item.current_stage === "completed")[0]?.no_of_card||0,
    },
    {
      type: "Embroidary",
      value: top_cards?.filter((item) => item.current_stage === "embroidary")[0]?.no_of_card||0,
    },
    {
      type: "Creation",
      value: top_cards?.filter((item) => item.current_stage === "creation")[0]?.no_of_card||0,
    },
    {
      type: "Secondary Process",
      value: top_cards?.filter((item) => item.current_stage === "secendory_process")[0]?.no_of_card||0,
    },
    {
      type: "Production",
      value: top_cards?.filter((item) => item.current_stage === "production")[0]?.no_of_card||0,
    },
    {
      type: "Account",
      value: top_cards?.filter((item) => item.current_stage === "account")[0]?.no_of_card||0,
    },
  ];
  const data = [
    {
      type: "Flexi Stocks",
      value: parseFloat(flexi?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0)?.toFixed(4)),
    },
    {
      type: "Twill Cotton",
      value: parseFloat(twill?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0)?.toFixed(4)),
    },
    {
      type: "Dotted Stocks",
      value: parseFloat(doted?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0)?.toFixed(4)),
    },
    {
      type: "Grandle Stocks",
      value: parseFloat(grandel?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0)?.toFixed(4)),
    },
    {
      type: "Mesh [net]",
      value: parseFloat(mesh?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0)?.toFixed(4)),
    },
    {
      type: "Material",
      value: parseFloat(CAP?.map((item) => item.quantity)?.reduce((a, b) => a + b, 0)?.toFixed(4)),
    },
  ];
console.log(data)
  const data1 = [
    {
      type: "BUTTON",
      value: CAP?.filter((item) => item.color_cloth.cap_material === "BUTTON")
        .map((item) => item.quantity)
        ?.reduce((a, b) => a + b, 0),
    },
    {
      type: "FUSING",
      value: CAP?.filter((item) => item.color_cloth.cap_material === "FUSING")
        .map((item) => item.quantity)
        ?.reduce((a, b) => a + b, 0),
    },
    {
      type: "SWEATE BAND",
      value: CAP?.filter(
        (item) => item.color_cloth.cap_material === "SWEATE BAND"
      )
        .map((item) => item.quantity)
        ?.reduce((a, b) => a + b, 0),
    },
    {
      type: "VISOR TYPE",
      value: CAP?.filter(
        (item) => item.color_cloth.cap_material === "VISOR TYPE,"
      )
        .map((item) => item.quantity)
        ?.reduce((a, b) => a + b, 0),
    },
  ];

useEffect(() => {
  const userx=JSON.parse(localStorage.getItem('user'))
  console.log("aplha",userx)
 if(userx?.roll==="designer"||userx?.roll==="account"||userx?.roll==="admin"){
}else{
  
  navigate('/jobcard')
 }
}, [localStorage.getItem('user')]);
  // console.log(CAP?.filter((item)=>item.color_cloth.cap_material==="BUTTON").map((item)=>item.quantity)?.reduce((a, b) => a + b, 0))
  // console.log(CAP?.filter((item)=>item.color_cloth.cap_material==="FUSING").map((item)=>item.quantity)?.reduce((a, b) => a + b, 0))
  // console.log(CAP?.filter((item)=>item.color_cloth.cap_material==="SWEATE BAND").map((item)=>item.quantity)?.reduce((a, b) => a + b, 0))
  // console.log(CAP?.filter((item)=>item.color_cloth.cap_material==="VISOR TYPE,").map((item)=>item.quantity)?.reduce((a, b) => a + b, 0))
  const [color, setColor] = useState("6px 11px 45px -5px rgba(0,0,0,0.64) ");
  const colorSyn = () => {
    if(color==="6px 11px 45px -5px rgba(0,0,0,0.64) inset"){
      setColor("6px 11px 45px -5px rgba(0,0,0,0.64) ")
    }else{
      setColor("6px 11px 45px -5px rgba(0,0,0,0.64) inset")

    }
  };
  const vap="ssdsd"
  return (
    <div style={{ margin: "5rem 8rem", background: color }} className="dash">
      {/* {vap.length}s */}
      {loading ? (
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      ) : (
        <>
          {/* <div style={{cursor:"pointer"}} onClick={colorSyn}>
            Logged in as : <span>{user.user}</span> Role is: {user.roll} email is: {user.email}
          </div> */}
          { client_loading_cards? (
            <Loader/>
          ) : (
            <>

              <div
                className="s-dash-board"
                style={{
                  display: "flex",
                  // flexWrap: "wrap",
                  boxShadow: color,

                  justifyContent: "space-between",
                  marginTop: "100px",
                }}
              >
                <div className="pie-dash" style={{ flex: "1" }}>
                  <h2 className="h2-inthe-charts">
                    ALL CLOTH TYPE IN INVENTORY
                  </h2>
                  <ForMat data={data} />
                  <br />
                </div>
                <div className="pie-dash" style={{ flex: "1" }}>
                  <h2 className="h2-inthe-charts">MATERIALS</h2>
{isLoadingCAP?<Loader/>:

                  <ForMat data={data1} />
}
                  <br />
                </div>
              </div>
              <div className="do-it-flex s-dash-board "    style={{ boxShadow: color,}}>
              <div
                className="bar-inside-flex"
             
              >
                <h2 className="h2-inthe-charts">
                  ALL JOB CO-ORDIATORS AND THERE ORDERS
                </h2>
                {data_bar ? <BarGraph data={data_bar} /> : <p>loading</p>}
              </div>

              <div
                className=" bar-inside-flex"
              >
                <h2 className="h2-inthe-charts">
                  ALL CLIENTS AND THERE ORDERS
                </h2>
                {data_bar_client ? (
                  <BarGraph data={data_bar_client} />
                ) : (
                  <p>loadings</p>
                )}
              </div>
              </div>
            
              <div className="do-it-flex s-dash-board" style={{boxShadow: color}}>
                <div style={{ width: "40%" }}>
                  <h2 className="h2-inthe-charts">Job Cards</h2>

                  <ForMat data={cardsForjob} />
                  <br />
                </div>
                <div style={{ width: "60%" }}>
                  <h2 className="h2-inthe-charts">
                    ALL JOB CARDS WITH ITS DELIVERY DATE
                  </h2>
                  {delivery_cards ? <Mountain data={delivery_cards} /> : <p>loadings</p>}
                </div>
              </div>
              <div style={{ height: "200px" }}></div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Dashboard;
