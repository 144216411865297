import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { fetchColor } from "features/jobinv/colorSlice";

import "./jobcardform.css";
import {
  Form,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  DatePicker,
  InputNumber,
  TreeSelect,
  Switch,
  Checkbox,
  Upload,
  Space,
  Row,
  Col,
  Slider,
  message,
  Divider,
  Skeleton,
} from "antd";
import styled from "styled-components";
import SelectType from "./SelectType";
import Title from "antd/lib/skeleton/Title";
import { useDispatch, useSelector } from "react-redux";
import {
  allDropDowns,
  // CreateJobCard,
  fetchClients,
  roleSelector,
  UpdateJobCard,
} from "features/jobinv/JobCardSlice";
import Loader from "components/common/Loader";
import moment from "moment/moment";
import { useForm } from "antd/es/form/Form";
import { ROLES, STAGES } from "components/common/Constants";
import ImageViewer from "components/ImageViewer";
import {
  useCreateCapMutation,
  useCreateJobcardMutation,
  useFetchCapsQuery,
  useFetchColorsQuery,
  useGetClientsQuery,
  useGetDropdownsQuery,
  useUpdateJobcardMutation,
  useFetchManufacture_capQuery,
  useUpdateManufacturerMutation,
  useGetManufacture_capQuery,
} from "features/store";
import { useNavigate } from "react-router-dom";
import useFormItemStatus from "antd/es/form/hooks/useFormItemStatus";
import { useDeferredValue } from "react";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import { SyncLoader } from "react-spinners";
import { PaymentFieldArray } from "components/State";
const { RangePicker } = DatePicker;
const { Option } = Select;

const handleFormSubmit = (
  values,
  createJobcard,
  thisfun,
  statusofcap,
  colors
) => {
  const formatedValues = {
    ...values,
    date: values.date?.format("YYYY-MM-DD") || "",
    deliver_date: values.deliver_date?.format("YYYY-MM-DD") || "",
    img1: values.img1?.target.files[0],
    img2: values.img2?.target.files[0],
    img3: values.img3?.target.files[0],
    img4: values.img4?.target.files[0],
    left_image: values.left_image?.target.files[0],
    right_image: values.right_image?.target.files[0],
    front_image: values.front_image?.target.files[0],
    color_image: values.color_image?.target.files[0],
    back_image: values.back_image?.target.files[0],
    current_stage: STAGES.CREATION,
  };

  // console.log(formatedValues)
  thisfun(formatedValues);

  //  createJobcard(formatedValues);
};

// const handleFormUpdate = (values, updateJobcard) => {
//   const formatedValues = {
//     ...values,
//     date: values.date?.format("YYYY-MM-DD") || "",
//     deliver_date: values.deliver_date?.format("YYYY-MM-DD") || "",
//   };

//   //if no file selected; then no need to update the image
//   if (!values.img1?.target?.files?.length > 0) delete formatedValues.img1;
//   else {
//     formatedValues.img1 = values.img1?.target.files[0];
//   }
//   updateJobcard(formatedValues)

//   // delete formatedValues.img2;
//   // delete formatedValues.img3;
//   // delete formatedValues.img4;
//   // delete formatedValues.left_image;
//   // delete formatedValues.right_image;
//   // delete formatedValues.front_image;
//   // delete formatedValues.color_image;
//   // delete formatedValues.back_image;

//   // dispatch(updateJobcard(formatedValues));
// };
const handleFormUpdate = (values, updateJobcard, thisfun) => {
  const formatedValues = {
    ...values,
    date: values.date?.format("YYYY-MM-DD") || "",
    deliver_date: values.deliver_date?.format("YYYY-MM-DD") || "",
  };
  //   //if no file selected; then no need to update the image
  if (!values.img1?.target?.files?.length > 0) delete formatedValues.img1;
  else {
    formatedValues.img1 = values.img1?.target.files[0];
  }
  //   updateJobcard(formatedValues)
  //if no file selected; then no need to update the image
  // if (!values.image?.target?.files?.length > 0) delete formatedValues.image;
  // if (!values.image2?.target?.files?.length > 0) delete formatedValues.image2;
  // else {
  //   formatedValues.image = values.image?.target.files[0];
  //   // formatedValues.image2 = values.image2?.target.files[0];
  // }
  console.log(formatedValues);

  // updateJobcard(formatedValues);
  thisfun(formatedValues);
};

const JobCardForm = ({ formValues }) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(null);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  // const { loading } = useSelector((state) => state.JobCard);
  // const {selections, error } = useSelector((state) => state.JobCard);
  const [form] = Form.useForm();
  const { data: selections, isLoading: dropdownLoading } =
    useGetDropdownsQuery();

  const { data: clients, isLoading: loading } = useGetClientsQuery();
  const colorx = useSelector((state) => state.color);

  // to get colors send cloth__cloth_type e.g Flexi stock
  const [a, setA] = useState(null);
  const shouldFetchColors=true
  const { data: colorss, isFetching: colorloading } = useFetchColorsQuery({
    skip: a, // Skip the initial fetch if shouldFetchColors is false
  });
const [colors, setcolors] = useState();
  // const { data: colors, isLoading: colorloading } = useFetchCapsQuery({

  // });
  const colorf = useSelector((state) => state.color);



  useEffect(() => {
if(a!==null){


  setcolors(colorss)
}
  }, [a,colorss]);
  const [for_counter, setFor_counter] = useState(0);

  const getCapName = (data) => {
    // console.log(formValues?.style)

    if (formValues && formValues?.style && for_counter === 0) {
      setA(formValues?.style);
      setFor_counter(1);
    } else {
      if (data === "BUCKET HAT") {
        setA("BUCKET HAT");
      } else if (data === "	5 PART CAP") {
        setA("5 PART CAP");
      } else {
        // setA("BASE BALL CAP");
        setA(data);
      }
    }
  };

  useEffect(() => {
    console.log("cap", a);
  }, [a]);
  const f1 = "FRONT" ;
  const f2 = "BACK" ;
  const f3 = "MIDDLE" ;
  const f4 = "TOP" ;
  const f5 = "UNDER" ;
  const f6 = "PIPING";
  const visor = colors
  ? [...new Set(colors?.filter(item => item?.cap_material === "VISOR TYPE"))]
  : [];
  console.log(visor);
  const capSideTypes = [];
  visor.forEach((item) => {
    if (item.cap_data !== null) {
      item.cap_data?.forEach((capItem) => {
        if (capItem.cap_type === a) {
          capSideTypes.push({
            color: item.color,
            type: item.type,
          });
        }
      });
    }
  });
  const tyeps = capSideTypes.map((it) => it.type + " : " + it.color);

  const sweat = [
    ...new Set(
      colors?.filter(
        (item) => item.cap_material === "SWEATE BAND," && item.color === null
      )
    ),
  ];

  const result = colors
    ?.filter((item) => item.cap_material === "SWEATE BAND" && item.cap_data)
    .map((item) => {
      const capData = item.cap_data?.find((capItem) => capItem.cap_type === a);
      if (capData) {
        return {
          type: item.type,
          color: item.color,
        };
      }
    })
    .filter(Boolean);
  console.log("lion", result);

  const sweat_band = result?.map((it) => it.type + " : color = " + it.color);

  const fushing = colors
    ?.filter((item) => item.cap_material === "FUSING" && item.cap_data)
    .map((item) => {
      const capData = item.cap_data?.find((capItem) => capItem.cap_type === a);
      if (capData) {
        return {
          type: item.type,
          color: item.color,
        };
      }
    })
    .filter(Boolean);

  const taper_data = colors
    ?.filter((item) => item.cap_material === "TAPER" && item.cap_data)
    .map((item) => {
      const capData = item.cap_data?.find((capItem) => capItem.cap_type === a);
      if (capData) {
        return {
          type: item.type,
          color: item.color,
        };
      }
    })
    .filter(Boolean);








  const back_closer = colors
    ?.filter((item) => item.cap_material === "BACK CLOSER" && item.cap_data)
    .map((item) => {
      const capData = item.cap_data?.find((capItem) => capItem.cap_type === a);
      if (capData) {
        return {
          type: item.type,
          quantity: capData.quantity,
        };
      }
    })
    .filter(Boolean);

  const back_closer_data = [...new Set(back_closer?.map((it) => it.type))];
  const cloth_type = [
    ...new Set(
      colors
        ?.filter((item) => {
          return item.cap_data?.some((cap) => {
            return cap.cap_type === a;
          });
        })
        .map((item) => item.type)
    ),
  ];

  // console.log(cloth_type)
  console.log("closer", back_closer_data);

  const [back_closer_colors, setBack_closer_colors] = useState([]);
  const [back_closer_type, setBack_closer] = useState([]);
  const getCloser = (value) => {
    console.log(
      colors?.filter((item) => item?.type === value).map((it) => it.color)
    );
    setBack_closer(value);
    setBack_closer_colors(
      colors?.filter((item) => item?.type === value).filter((it)=>it.cap_data?.some((op)=>op.cap_type===a)).map((it) => it.color)
    );
  };

  const [for_closer, setFor_closer] = useState();
  const [for_piping, setFor_piping] = useState();

  const getCloser_color = (value) => {



    
    const filteredItems = colors?.filter(
      (item) => item.type === back_closer_type && item.color === value
    );

    // Extract the id and cap_data?.id values from the filtered items
    const result = filteredItems.map((item) => ({
      cloth_id: item.id,
      cap_id: item.cap_data[0].id,
    }));

    console.log(result);
    if (formValues) {
      console.log();
      function findCapMaterialWithVisorType(data) {
        for (const item of data.manufacturedcapdata_set) {
          const capMaterial = item.cap_material;
          if (capMaterial && capMaterial.cap_material === "BACK CLOSER") {
            return item.id;
          }
        }
        return null; // Return null if no cap material with visor type found
      }

      const visorTypeId = findCapMaterialWithVisorType(
        manufacture_capdata?.filter(
          (item) => item.id === formValues?.manufacture_cap
        )[0]
      );
      console.log("Visor Type ID:", visorTypeId);

      if (visorTypeId) {
        result[0].id = visorTypeId;
      }
    }
    console.log(result[0]);

    setFor_closer(result[0]);
  };

  const foam_data = colors
    ?.filter(
      (item) =>
        item.cap_data &&
        item.cap_data?.some((it)=>it.cap_type === a) &&
        item.cap_material === "FOAM"
    )
    .map((ite) => ite.color);

  const nonon_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "NONON"
  )
  .map((ite) => ite.color);
  const buckle_belt_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "BUCKLE BELT"
  )
  .map((ite) => ite.color);
  
  const buckle_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "BUCKLE"
  )
  .map((ite) => ite.type);



  const binder_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "BINDER"
  )
  .map((ite) => ite.color);
  const button_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "BUTTON"
  )
  .map((ite) => ite.color);

  const brim_foam_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "BRIM FOAM"
  )
  .map((ite) => ite.color);
  
  const o_ring_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "O-RING"
  )
  .map((ite) => ite.color);
  const snappack_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "SNAPACK"
  )
  .map((ite) => ite.color);
  const sweat_band_holding_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "SWEAT BAND HOLDING"
  )
  .map((ite) => ite.color);
  const sizing_strip =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "SIZING STRIP"
  )
  .map((ite) => ite.color);
  const carton_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "CARTON"
  )
  .map((ite) => ite.type);
  const plastic_bag_data =  colors
  ?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some((it)=>it.cap_type === a) &&
      item.cap_material === "PLASTIC BAG"
  )
  .map((ite) => ite.type);

  const fushing_data = fushing?.map(
    (it) => it.type + " : color = " + it.color
  );
  const taper_data_extract = taper_data?.map(
    (it) => it.type + " : color = " + it.color
  );

  const cloth_type1 = [
    ...new Set(
      colors
        ?.filter((item) => {
          return item.cap_data?.some((cap) => {
            return cap.cap_side_type === f1 && cap.cap_type === a;
          });
        })
        .map((item) => item.cloth_type)
    ),
  ];

  const cloth_type2 = [
    ...new Set(
      colors
        ?.filter((item) => {
          return item.cap_data?.some((cap) => {
            return cap.cap_side_type === f2 && cap.cap_type === a;
          });
        })
        .map((item) => item.cloth_type)
    ),
  ];
  const cloth_type3 = [
    ...new Set(
      colors
        ?.filter((item) => {
          return item.cap_data?.some((cap) => {
            return cap.cap_side_type === f3 && cap.cap_type === a;
          });
        })
        .map((item) => item.cloth_type)
    ),
  ];
  const cloth_type4 = [
    ...new Set(
      colors
        ?.filter((item) => {
          return item.cap_data?.some((cap) => {
            return cap.cap_side_type === f4 && cap.cap_type === a;
          });
        })
        .map((item) => item.cloth_type)
    ),
  ];
  const cloth_type5 = [
    ...new Set(
      colors
        ?.filter((item) => {
          return item.cap_data?.some((cap) => {
            return cap.cap_side_type === f5 && cap.cap_type === a;
          });
        })
        .map((item) => item.cloth_type)
    ),
  ];
  const cloth_type6 = [
    ...new Set(
      colors
        ?.filter((item) => {
          return (
            item?.cap_material==="PIPING" &&
            
            item.cap_data?.some((cap) => {
              return cap.cap_type === a;
            })
          );
        })
        .map((item) => item.type)
        .filter((op) => op !== null)
    ),
  ];

  const [q_tity, setQ_tity] = useState(1);
  const [cap_M, setCap_M] = useState();
  const [formval, setFormval] = useState();
  const [createCap, creatCapResponseInfo] = useCreateCapMutation();
  const [topid, setTopid] = useState();
  const [update_fronttype, setupdate_fronttype] = useState();
  const [update_backtype, setupdate_backtype] = useState();
  const [update_middle, setupdate_middle] = useState();
  const [update_top, setupdate_top] = useState();
  const [update_under, setupdate_under] = useState();
const [man_id, setMan_id] = useState();
  const { data: manufacture_capdata, isLoading: manufacture_caploading } =
    useGetManufacture_capQuery({id:formValues?.manufacture_cap});
  // console.log(creatCapResponseInfo)
  //  console.log(manufacture_capdata.filter((item)=>item.id===71))
  useEffect(() => {
    if(formValues){

    console.log(manufacture_caploading);
    console.log("make it effect");


    
    if (manufacture_caploading === false) {
      if (manufacture_capdata && formValues) {
        setQ_tity(formValues?.quantity);

        console.log("formValue", formValues?.quantity);

        console.log(
          manufacture_capdata
        );

        setTopid(
          manufacture_capdata?.id
        );
        // setTopid(
        //   manufacture_capdata.id
        // );

        // console.log(formValues?.manufacture_cap)

     
        if (
          a === "BUCKET HAT" ||
          a === "UMPIRE HAT" ||
          a === "REVERSIBLE BUCKET HAT"
        ) {
     
    
          const targetData =manufacture_capdata;
          console.log("tigerssssss",targetData)
          let desiredManufacturedCapDataId = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "FRONT");
            if (desiredCapData) {
              desiredManufacturedCapDataId = desiredCapData.id;
            }
          }
          setupdate_fronttype(desiredManufacturedCapDataId)
          
          let top = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "TOP");
            if (desiredCapData) {
              top = desiredCapData.id;
            }
          }
          setupdate_top(
          top
          );
          let under = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "UNDER");
            if (desiredCapData) {
              under = desiredCapData.id;
            }
          }
          setupdate_under(
 under
          );




          const filteredData1 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "FRONT"
          );
          
          const capIds1 = filteredData1?.map(item => item.cap);
          const clothIds1 = filteredData1?.map(item => item.cap_material.id);
          
          
            setCap_data_id1(capIds1[0])
          setcloth_id1(clothIds1[0])


          console.log("tiger",capIds1[0],clothIds1[0])

          const filteredData2 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "TOP"
          );
          
          const capIds2 = filteredData2.map(item => item.cap);
          const clothIds2 = filteredData2.map(item => item.cap_material.id);

          setCap_data_id4(capIds2[0])
          setcloth_id4(clothIds2[0])
          console.log("tiger",capIds2[0],clothIds2[0])

          const filteredData5 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "UNDER"
          );
          
          const capIds5 = filteredData5.map(item => item.cap);
          const clothIds5 = filteredData5.map(item => item.cap_material.id);

          setCap_data_id5(capIds5[0])
          setcloth_id5(clothIds5[0])


          console.log("tiger",capIds5[0],clothIds5[0])













          // setCap_data_id1(
          //   manufacture_capdata &&
          //     manufacture_capdata?.filter(
          //       (item) => item?.id === formValues?.manufacture_cap
          //     )[0].manufacturedcapdata_set[0]?.cap
          // );
       
          // setCap_data_id4(
          //   manufacture_capdata &&
          //     manufacture_capdata?.filter(
          //       (item) => item?.id === formValues?.manufacture_cap
          //     )[0].manufacturedcapdata_set[1]?.cap
          // );
          // setCap_data_id5(
          //   manufacture_capdata &&
          //     manufacture_capdata?.filter(
          //       (item) => item?.id === formValues?.manufacture_cap
          //     )[0].manufacturedcapdata_set[2]?.cap
          // );

          // setcloth_id1(
          //   manufacture_capdata &&
          //     manufacture_capdata.filter(
          //       (item) => item.id === formValues?.manufacture_cap
          //     )[0].manufacturedcapdata_set[0]?.cap_material?.id
          // );
          
        
          // setcloth_id4(
          //   manufacture_capdata &&
          //     manufacture_capdata.filter(
          //       (item) => item.id === formValues?.manufacture_cap
          //     )[0].manufacturedcapdata_set[1]?.cap_material?.id
          // );
          // setcloth_id5(
          //   manufacture_capdata &&
          //     manufacture_capdata.filter(
          //       (item) => item.id === formValues?.manufacture_cap
          //     )[0].manufacturedcapdata_set[2]?.cap_material?.id
          // );
        } else {
         
      
          const targetData = manufacture_capdata;
          console.log("tigerssssss",targetData)

          let desiredManufacturedCapDataId = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "FRONT");
            if (desiredCapData) {
              desiredManufacturedCapDataId = desiredCapData.id;
            }
          }
          setupdate_fronttype(desiredManufacturedCapDataId)
          let back = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "BACK");
            if (desiredCapData) {
              back = desiredCapData.id;
            }
          }
          setupdate_backtype(back)
          let middle = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "MIDDLE");
            if (desiredCapData) {
              middle = desiredCapData.id;
            }
          }
          setupdate_middle(middle)
          
          let top = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "TOP");
            if (desiredCapData) {
              top = desiredCapData.id;
            }
          }
          setupdate_top(
          top
          );
          let under = null;
          if (targetData) {
            const desiredCapData = targetData?.manufacturedcapdata_set.find(item => item.cap_side_type === "UNDER");
            if (desiredCapData) {
              under = desiredCapData.id;
            }
          }
          setupdate_under(
 under
          );








          const filteredData1 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "FRONT"
          );
          
          const capIds1 = filteredData1?.map(item => item.cap);
          const clothIds1 = filteredData1?.map(item => item.cap_material.id);
          
          
            setCap_data_id1(capIds1[0])
          setcloth_id1(clothIds1[0])




          const filteredData2 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "BACK"
          );
          
          const capIds2 = filteredData2.map(item => item.cap);
          const clothIds2 = filteredData2.map(item => item.cap_material.id);

          setCap_data_id2(capIds2[0])
          setcloth_id2(clothIds2[0])

          const filteredData3 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "MIDDLE"
          );
          
          const capIds3 = filteredData3.map(item => item.cap);
          const clothIds3 = filteredData3.map(item => item.cap_material.id);

          setCap_data_id3(capIds3[0])
          setcloth_id3(clothIds3[0])

          const filteredData4 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "TOP"
          );
          
          const capIds4 = filteredData4.map(item => item.cap);
          const clothIds4 = filteredData4.map(item => item.cap_material.id);

          setCap_data_id4(capIds4[0])
          setcloth_id4(clothIds4[0])

          const filteredData5 = targetData?.manufacturedcapdata_set.filter(
            item => item.cap_side_type === "UNDER"
          );
          
          const capIds5 = filteredData5.map(item => item.cap);
          const clothIds5 = filteredData5.map(item => item.cap_material.id);

          setCap_data_id5(capIds5[0])
          setcloth_id5(clothIds5[0])






        }
      }
    }
  }

  }, [manufacture_capdata]);

  const thisfun = async (formatedValues) => {
   



    const result = [];

    if (visior !== null&&visior!==undefined&&visior!==[]) {
      result.push(visior);
    }
    
    if (fusing !== null&&fusing!==undefined&&fusing!==[]) {
      result.push(fusing);
    }
    if (taper_obj !== null&&taper_obj!==undefined&&taper_obj!==[]) {
      result.push(taper_obj);
    }
    
    if (sweat_bands !== null&&sweat_bands!==undefined&&sweat_bands!==[]) {
      // result.push(sweat_bands);
      //SWEAT BAND
    }
    if (for_closer !== null&&for_closer!==undefined&&for_closer!==[]) {
      // Back Closer
      // result.push(for_closer);
    }
    if (for_piping !== null&&for_piping!==undefined&&for_piping!==[]) {
      // result.push(for_piping);
      //PIPING
    }
    if (foam !== null&&foam!==undefined&&foam!==[]) {
      result.push(foam);
    }
    if (strip_obj !== null&&strip_obj!==undefined&&strip_obj!==[]) {
      result.push(strip_obj);
    }
    if (binder_obj !== null&&binder_obj!==undefined&&binder_obj!==[]) {
      result.push(binder_obj);
    }
    if (nonon !== null&&nonon!==undefined&&nonon!==[]) {
      result.push(nonon);
    }
    if (bulkle_belt_obj !== null&&bulkle_belt_obj!==undefined&&bulkle_belt_obj!==[]) {
      // result.push(bulkle_belt_obj);
      //BUCKLE BELT
    }
    if (bulkle_obj !== null&&bulkle_obj!==undefined&&bulkle_obj!==[]) {
      result.push(bulkle_obj);
    }
    if (interLINING !== null&&interLINING!==undefined&&interLINING!==[]) {
      result.push(interLINING);
    }
    
    if (elastic_obj !== null&&elastic_obj!==undefined&&elastic_obj!==[]) {
      result.push(elastic_obj);
    }
    if (full_closer_obj !== null&&full_closer_obj!==undefined&&full_closer_obj!==[]) {
      result.push(full_closer_obj);
    }
    if (brim_foam_obj !== null&&brim_foam_obj!==undefined&&brim_foam_obj!==[]) {
      result.push(brim_foam_obj);
    }
    if (button_obj !== null&&button_obj!==undefined&&button_obj!==[]) {
      // result.push(button_obj);
    }
    if (carton_obj !== null&&carton_obj!==undefined&&carton_obj!==[]) {
      result.push(carton_obj);
    }
    if (plastic_obj !== null&&plastic_obj!==undefined&&plastic_obj!==[]) {
      result.push(plastic_obj);
    }
    if (o_ring_obj !== null&&o_ring_obj!==undefined&&o_ring_obj!==[]) {
      result.push(o_ring_obj);
    }
    if (snappack_obj !== null&&snappack_obj!==undefined&&snappack_obj!==[]) {
      result.push(snappack_obj);
    }
    
    if (sweat_band_holding_obj !== null&&sweat_band_holding_obj!==undefined&&sweat_band_holding_obj!==[]) {
      result.push(sweat_band_holding_obj);
    }
    
    if (button_color_obj !== null&&button_color_obj!==undefined&&button_color_obj!==[]) {
      result.push(button_color_obj);
    }
    
    console.log(result);

    

const without =result;



// console.log(...without)











console.log("qqqqqqqqq",q_tity)


    setFormval(formatedValues);

    if(a==="BUCKET HAT"||a==="UMPIRE HAT"||a==="REVERSIBLE BUCKET HAT"){
      const r = {
        "cap_type": a,
        "manufacture_quantity": q_tity,
        "manufactured_cap_data": [
          {
            "cap_id": cap_data_id1,
            "cloth_id": cloth_id1,
          },
         
          {
            "cap_id": cap_data_id4,
            "cloth_id": cloth_id4,
          },
          {
            "cap_id": cap_data_id5,
            "cloth_id": cloth_id5,
          }
          
          
        ]
      };
      r.manufactured_cap_data?.push(...without);

    if (!formValues) {

r.manufactured_cap_data = r.manufactured_cap_data?.filter(cap => cap.length !== 0);
// console.log("lion",r)
const filteredData = {
  ...r,
  manufactured_cap_data: r.manufactured_cap_data?.filter(
    (item) => item.cap_id !== null && item.cloth_id !== null && item.cap_id !== undefined
  ),
};
      createCap(filteredData);
    }

    }else{

    const r = {
      "cap_type": a,
      "manufacture_quantity": q_tity,
      "manufactured_cap_data": [
        {
          "cap_id": cap_data_id1,
          "cloth_id": cloth_id1,
        },
        {
          "cap_id": cap_data_id2,
          "cloth_id": cloth_id2,
        },
        {
          "cap_id": cap_data_id3,
          "cloth_id": cloth_id3,
        },
        {
          "cap_id": cap_data_id4,
          "cloth_id": cloth_id4,
        },
        {
          "cap_id": cap_data_id5,
          "cloth_id": cloth_id5,
        }
        
        
      ]
    };
    r.manufactured_cap_data?.push(...without);

    if (!formValues) {
      r.manufactured_cap_data = r.manufactured_cap_data?.filter(cap => cap.length !== 0);
      // console.log("lion",r)
      const filteredData = {
        ...r,
        manufactured_cap_data: r.manufactured_cap_data?.filter(
          (item) => item.cap_id !== null && item.cloth_id !== null && item.cap_id !== undefined
        ),
      };
            createCap(filteredData);
      
    }
  }






    
    if (formValues) {
      if(a==="BUCKET HAT"||a==="UMPIRE HAT"||a==="REVERSIBLE BUCKET HAT"){

      const r_updatte = {
        "id": topid,
        "cap_type": a,
        "manufacture_quantity": q_tity,
        "manufactured_cap_data": [
          {
            "id": update_fronttype,

            "cap_id": cap_data_id1,
            "cloth_id": cloth_id1,
          },
          {
            "id": update_top,

            "cap_id": cap_data_id4,
            "cloth_id": cloth_id4,
          },
          {
            "id": update_under,
            "cap_id": cap_data_id5,
            "cloth_id": cloth_id5,
          }
        ]
      };
      r_updatte.manufactured_cap_data?.push(...without);
      r_updatte.manufactured_cap_data = r_updatte.manufactured_cap_data?.filter(item => Object.keys(item).length !== 0);
      const formattedData = {
        ...r_updatte,
  manufactured_cap_data: r_updatte.manufactured_cap_data.map(item => {
    const { id, ...rest } = item;
    if (id !== null) {
      return item;
    } else {
      return rest;
    }
  }),
      };
console.log("royal",formattedData)


const filteredData = formattedData.manufactured_cap_data.filter(item => {
  return item.cap_id !== null && item.cloth_id !== null && item.id === undefined &&item.cloth_id!==undefined &&item.cap_id!==undefined;
});


      const filteredManufacturedCapData = formattedData.manufactured_cap_data.filter(item => {
        return item.id !== undefined && (item.cap_id !== null || item.cloth_id !== null);
      });
      
      const newData = {
        ...formattedData,
        manufactured_cap_data: filteredManufacturedCapData,
      };

      newData.manufactured_cap_data.push(...filteredData);
console.log("royal",newData)

      updateManufacture_cap(newData);
    }
     else{

      const r_updatte = {
        "id": topid,
        "cap_type": a,
        "manufacture_quantity": q_tity,
        "manufactured_cap_data": [
          {
            "id": update_fronttype,

            "cap_id": cap_data_id1,
            "cloth_id": cloth_id1,
          },
          {
            "id": update_backtype,

            "cap_id": cap_data_id2,
            "cloth_id": cloth_id2,
          },
          {
            "id": update_middle,
            "cap_id": cap_data_id3,
            "cloth_id": cloth_id3,
          },
          {
            "id": update_top,
            "cap_id": cap_data_id4,
            "cloth_id": cloth_id4,
          },
          {
            "id": update_under,
            "cap_id": cap_data_id5,
            "cloth_id": cloth_id5,
          }
        ]
      };
      r_updatte.manufactured_cap_data?.push(...without);
      r_updatte.manufactured_cap_data = r_updatte.manufactured_cap_data?.filter(item => Object.keys(item).length !== 0);
      console.log("L tigeraasdasdasdasdasadsadasd",r_updatte)
   
      const formattedData = {
        ...r_updatte,
  manufactured_cap_data: r_updatte.manufactured_cap_data.map(item => {
    const { id, ...rest } = item;
    if (id !== null) {
      return item;
    } else {
      return rest;
    }
  }),
      };
console.log("royal",formattedData)


const filteredData = formattedData.manufactured_cap_data.filter(item => {
  return item.cap_id !== null && item.cloth_id !== null && item.id === undefined &&item.cloth_id!==undefined &&item.cap_id!==undefined;
});


      const filteredManufacturedCapData = formattedData.manufactured_cap_data.filter(item => {
        return item.id !== undefined && (item.cap_id !== null || item.cloth_id !== null);
      });
      
      const newData = {
        ...formattedData,
        manufactured_cap_data: filteredManufacturedCapData,
      };

      newData.manufactured_cap_data.push(...filteredData);
console.log("royal",newData)

      updateManufacture_cap(newData);
    }

      // r_updatte.manufactured_cap_data?.push(...without);

      // updateManufacture_cap(r_updatte);
    }


    // console.log(r)

    // console.log("r")

    







  
  console.log("working");
  
  console.log(creatCapResponseInfo);
};


  useEffect(() => {
    console.log("dummys",creatCapResponseInfo)
    if (creatCapResponseInfo.data) {
      console.log(creatCapResponseInfo.data.data.id);
      formval.manufacture_cap = creatCapResponseInfo.data.data.id;
      console.log(formval);
    }
    if (creatCapResponseInfo.status === "fulfilled") {
      createJobcard(formval);
    }
    if (creatCapResponseInfo.status === "rejected") {
      console.log(creatCapResponseInfo?.error?.data?.message)
      message.error(creatCapResponseInfo?.error?.data?.message);
    }
  }, [creatCapResponseInfo]);

  // console.log(formValues?.manufacture_cap);

  // if(formValues&&formValues?.manufacture_cap){
  //   fetchManufacture_cap(formValues?.manufacture_cap)

  // }

  // useEffect(() => {
  // if(fetchResponseInfo){
  //   console.log("efect")
  //   console.log(fetchResponseInfo)
  // }
  // }, [fetchResponseInfo]);

  const getFabric1 = (data) => {
    setFrontColor("pending");
    console.log("hellos")
    // dispatch(fetchColor(data));
    // console.log(colorx.users.filter((item)=>item.cap_data!==null&&item.cap_data?.quantity!==0).map((c)=>c.color))
    // console.log(colorx.users)

    console.log(a);
    // console.log(colorx.users.filter((items)=>items.cap_data))
    //FOR SELECTING ACCORDING TO ITS CAP TYPE
    console.log(
      colors
        .filter(
          (items) =>
            items.cap_data &&
            items.cap_data?.cap_type === a &&
            items.cloth_type === data
        )
        .map((c) => c.color)
    );

    // options={colorx.users.filter((item)=>item.cap_data!==null&&item.cap_data?.quantity!==0).map((c)=>c.color)}
    // options={colorx.users.map((colorx) => colorx.color)}
    setFronttype(data);
  };
  // console.log(filter(colorx.users.map((colorx) => colorx.color&&colorx.cap_data!==null)))
  // console.log(colors)
  const [quantity1, setquantity1] = useState();
  const [quantity2, setquantity2] = useState();
  const [quantity3, setquantity3] = useState();
  const [quantity4, setquantity4] = useState();
  const [quantity5, setquantity5] = useState();
  const [quantity6, setquantity6] = useState();
  const getColor1 = (data) => {
    setFrontColor(data);

    const x = colors?.filter(
      (item) => item.cloth_type === fronttype && item.color === data
    );
    const filteredIds = colors
      ?.filter(
        (item) =>
          item.cloth_type === fronttype &&
          item.color === data &&
          item.cap_data?.some(
            (cap) => cap.cap_type === a && cap.cap_side_type === f1
          )
      )
      .flatMap((item) =>
        item.cap_data?.filter(
          (cap) => cap.cap_type === a && cap.cap_side_type === f1
        )
      )
      .map((cap) => cap.id);
if(filteredIds[0]!==undefined){

  setCap_data_id1(filteredIds[0]);
}
if(filteredIds[0]===undefined){
setCap_data_id1(null)
}
    try {
      setcloth_id1(
        x.filter(
          (item) => item.cloth_type === fronttype && item.color === data
        )[0].id
      );

      setquantity1(
        colors
          ?.filter(
            (item) =>
              item.cloth_type === fronttype &&
              item.color === data &&
              item.cap_data?.some(
                (cap) => cap.cap_type === a && cap.cap_side_type === f1
              )
          )
          .flatMap((item) =>
            item.cap_data?.filter(
              (cap) => cap.cap_type === a && cap.cap_side_type === f1
            )
          )
          .map((cap) => cap.quantity)
      );
    } catch (e) {
      setquantity1("null");
    }
    console.log(
      "cap_id :",filteredIds[0],
      "cloth_id :",x.filter(
        (item) => item?.cloth_type === fronttype && item?.color === data
      )[0]?.id)
  };









const hello=(data)=>{

  console.log(data)
}















  const getFabric2 = (data) => {
    setbackColor("pending");
    // dispatch(fetchColor(data));
    setbacktype(data);
  };
  const getColor2 = (data) => {
    setbackColor(data);

    const x = colors?.filter(
      (item) => item.cloth_type === backtype && item.color === data
    );

    // setCap_data_id2(
    //   x.filter(
    //     (item) =>
    //     item.cloth_type === backtype &&
    //     item.color === data &&
    //     item.cap_data?.cap_type === a &&
    //     item.cap_data?.cap_side_type === f2
    //     )[0].cap_data?.id
    //     );

    const filteredIds = colors
      ?.filter(
        (item) =>
          item.cloth_type === backtype &&
          item.color === data &&
          item.cap_data?.some(
            (cap) => cap.cap_type === a && cap.cap_side_type === f2
          )
      )
      .flatMap((item) =>
        item.cap_data?.filter(
          (cap) => cap.cap_type === a && cap.cap_side_type === f2
        )
      )
      .map((cap) => cap.id);

      if(filteredIds[0]!==undefined){

        setCap_data_id2(filteredIds[0]);
      }
      if(filteredIds[0]===undefined){
      setCap_data_id2(null)
      }

    try {
      setcloth_id2(
        x.filter(
          (item) => item.cloth_type === backtype && item.color === data
        )[0].id
      );
      setquantity2(
        colors
          ?.filter(
            (item) =>
              item.cloth_type === backtype &&
              item.color === data &&
              item.cap_data?.some(
                (cap) => cap.cap_type === a && cap.cap_side_type === f2
              )
          )
          .flatMap((item) =>
            item.cap_data?.filter(
              (cap) => cap.cap_type === a && cap.cap_side_type === f2
            )
          )
          .map((cap) => cap.quantity)
      );
    } catch (e) {
      setquantity2("null");
    }
  };
  const getFabric3 = (data) => {
    setmiddleColor("pending");
    // dispatch(fetchColor(data));
    setmiddletype(data);
  };
  const getColor3 = (data) => {
    setmiddleColor(data);

    const x = colors?.filter(
      (item) => item.cloth_type === middletype && item.color === data
    );

    const filteredIds = colors
      ?.filter(
        (item) =>
          item.cloth_type === middletype &&
          item.color === data &&
          item.cap_data?.some(
            (cap) => cap.cap_type === a && cap.cap_side_type === f3
          )
      )
      .flatMap((item) =>
        item.cap_data?.filter(
          (cap) => cap.cap_type === a && cap.cap_side_type === f3
        )
      )
      .map((cap) => cap.id);
      if(filteredIds[0]!==undefined){

        setCap_data_id3(filteredIds[0]);
      }
      if(filteredIds[0]===undefined){
      setCap_data_id3(null)
      }
    setCap_data_id3(filteredIds[0]);

    try {
      setcloth_id3(
        x.filter(
          (item) => item.cloth_type === middletype && item.color === data
        )[0].id
      );
      setquantity3(
        colors
          ?.filter(
            (item) =>
              item.cloth_type === middletype &&
              item.color === data &&
              item.cap_data?.some(
                (cap) => cap.cap_type === a && cap.cap_side_type === f3
              )
          )
          .flatMap((item) =>
            item.cap_data?.filter(
              (cap) => cap.cap_type === a && cap.cap_side_type === f3
            )
          )
          .map((cap) => cap.quantity)
      );
    } catch (e) {
      setquantity3("null");
    }
  };
  const getFabric4 = (data) => {
    settopColor("pending");
    // dispatch(fetchColor(data));
    settoptype(data);
  };
  const getColor4 = (data) => {
    settopColor(data);

    const x = colors?.filter(
      (item) => item.cloth_type === toptype && item.color === data
    );
    const filteredIds = colors
      ?.filter(
        (item) =>
          item.cloth_type === toptype &&
          item.color === data &&
          item.cap_data?.some(
            (cap) => cap.cap_type === a && cap.cap_side_type === f4
          )
      )
      .flatMap((item) =>
        item.cap_data?.filter(
          (cap) => cap.cap_type === a && cap.cap_side_type === f4
        )
      )
      .map((cap) => cap.id);

      if(filteredIds[0]!==undefined){

        setCap_data_id4(filteredIds[0]);
      }
      if(filteredIds[0]===undefined){
      setCap_data_id4(null)
      }

    try {
      setcloth_id4(
        x.filter(
          (item) => item.cloth_type === toptype && item.color === data
        )[0].id
      );
      setquantity4(
        colors
          ?.filter(
            (item) =>
              item.cloth_type === toptype &&
              item.color === data &&
              item.cap_data?.some(
                (cap) => cap.cap_type === a && cap.cap_side_type === f4
              )
          )
          .flatMap((item) =>
            item.cap_data?.filter(
              (cap) => cap.cap_type === a && cap.cap_side_type === f4
            )
          )
          .map((cap) => cap.quantity)
      );
    } catch (e) {
      setquantity4("null");
    }
  };
  const getFabric5 = (data) => {
    setunderColor("pending");
    // dispatch(fetchColor(data));
    setundertype(data);
  };
  const getColor5 = (data) => {
    setunderColor(data);

    const x = colors?.filter(
      (item) => item.cloth_type === undertype && item.color === data
    );
    const filteredIds = colors
      ?.filter(
        (item) =>
          item.cloth_type === undertype &&
          item.color === data &&
          item.cap_data?.some(
            (cap) => cap.cap_type === a && cap.cap_side_type === f5
          )
      )
      .flatMap((item) =>
        item.cap_data?.filter(
          (cap) => cap.cap_type === a && cap.cap_side_type === f5
        )
      )
      .map((cap) => cap.id);

      if(filteredIds[0]!==undefined){

        setCap_data_id5(filteredIds[0]);
      }
      if(filteredIds[0]===undefined){
      setCap_data_id5(null)
      }

    try {
      setcloth_id5(
        x.filter(
          (item) => item.cloth_type === undertype && item.color === data
        )[0].id
      );
      setquantity5(
        colors
          ?.filter(
            (item) =>
              item.cloth_type === undertype &&
              item.color === data &&
              item.cap_data?.some(
                (cap) => cap.cap_type === a && cap.cap_side_type === f5
              )
          )
          .flatMap((item) =>
            item.cap_data?.filter(
              (cap) => cap.cap_type === a && cap.cap_side_type === f5
            )
          )
          .map((cap) => cap.quantity)
      );
    } catch (e) {
      setquantity5("null");
    }
  };
  const getFabric6 = (data) => {
    setpipingColor("pending");
    // dispatch(fetchColor(data));

    setpipingtype(data);
  };
  const getColor6 = (value) => {
    setpipingColor(value);

        
    const filteredItems = colors?.filter(
      (item) => item.type === pipingtype && item.color === value
    );

    // Extract the id and cap_data?.id values from the filtered items
    const result = filteredItems.map((item) => ({
      cloth_id: item.id,
      cap_id: item.cap_data[0].id,
    }));

    console.log(result);
    if (formValues) {
      console.log();
      function findCapMaterialWithVisorType(data) {
        for (const item of data.manufacturedcapdata_set) {
          const capMaterial = item.cap_material;
          if (capMaterial && capMaterial.cap_material === "PIPING") {
            return item.id;
          }
        }
        return null; // Return null if no cap material with visor type found
      }

      const visorTypeId = findCapMaterialWithVisorType(
        manufacture_capdata
      );
      console.log("Visor Type ID:", visorTypeId);

      if (visorTypeId) {
        result[0].id = visorTypeId;
      }
    }
    console.log(result[0]);
    setFor_piping(result[0]);

    // console.log(formx)
  };
  // console.log(formValues)

  const getUpadtedcolor = () => {
    if (formValues == undefined) {
    } else {
      setFrontColor(formValues?.main_fabric_Front_color);
      setbackColor(formValues?.main_fabric_Back_color);
      setmiddleColor(formValues?.main_fabric_Middle_color);
      settopColor(formValues?.main_fabric_Top_color);
      setunderColor(formValues?.main_fabric_Under_color);
      setpipingColor(formValues?.piping_color);
      // setpipingColor(formValues?.piping_color);
    }
  };





const button="CAP BUTTON"

const [button_obj, setButton_obj] = useState();
const getbutton=(data)=>{
  function findCapIdAndClothId(capMaterial, capType) {
    let result = null;
    
    // Loop through the data array to find the matching entry
    for (const entry of colors) {
        if (entry.cap_material === capMaterial) {
            // Loop through the cap_data array of the current entry to find the matching cap_type
            for (const capData of entry.cap_data) {
                if (capData.cap_type === capType) {
                    result = {
                        cap_id: capData.id,
                        cloth_id: capData.color_cloth
                    };
                    break;
                }
            }
            // If we found the matching cap_data, break out of the outer loop as well
            if (result !== null) {
                break;
            }
        }
    }
    
    return result;
}


const result = findCapIdAndClothId(data, a);
console.log(result)
setButton_obj(result)

}


























  const [visior, setvisior] = useState();
  const [fusing, setfusing] = useState();
  const [taper_obj, settaper_obj] = useState();
  const [sweat_bands, setsweat_bands] = useState();
  const [full_closer_obj, setFull_closer_obj] = useState();
  const [elastic_obj, setElastic_obj] = useState();
  const visor_type = "visor_type";
  const BINDER = "BINDER";
  const STRIP = "SIZING STRIP";
  const fusing_type = "FUSING";
  const foam_type = "FOAM";
  const brim_foam_init = "BRIM FOAM";
  const o_ring_init = "O-RING";
  const snap_pack_init = "SNAPACK";
  const sweat_band_holding_init = "SWEAT BAND HOLDING";

  const nonon_type = "NONON";
  const taper = "TAPER";
  const sweatsss = "sweat_type";
  const full_closer_init = "FULL CLOSURE ELASTIC";
  const elastic_init = "ELASTIC";
  const interlining_init = "INTERLINING";
  const buckel_init="BUCKLE"
  const carton_init="CARTON"
  const plastic_init="PLASTIC BAG"
  const buckle_belt_init="BUCKLE BELT"
  const [appen, setAppen] = useState([]);

  const [binder_obj, setBinder_obj] = useState();
  const [brim_foam_obj, setBrim_foam_obj] = useState();
  const [strip_obj, setStrip_obj] = useState();
  const [foam, setFoam] = useState();
  const [nonon, setNonon] = useState();
  const [carton_obj, setCarton_obj] = useState();
  const [plastic_obj, setPlastic_obj] = useState();

const fun_for_type=(type,data)=>{
 try {
  
   
    
    if (formValues) {
  if (type === "CARTON" || type === "PLASTIC BAG") {
    function findCapMaterialWithVisorType(data) {
      for (const item of data.manufacturedcapdata_set) {
        const capMaterial = item.cap_material;
        if (capMaterial && capMaterial.cap_material === type) {
          return item.id;
        }
      }
      return null; // Return null if no cap material with visor type found
    }

    const visorTypeId = findCapMaterialWithVisorType(
      manufacture_capdata
    );
    console.log("Visor Type ID:", visorTypeId);

    // foam extraction login
    const filteredItems = colors?.filter(
      (item) =>
        item.cap_data &&
        item.cap_data?.some(
          (capData) =>
            item.type === data &&
            capData.cap_type === a &&
            item.cap_material === type

        )
    );

    const results = filteredItems.map((item) => ({
      cloth_id: item.id,
      cap_id: item.cap_data?.find(
        (capData) =>
          item.type === data &&
          capData.cap_type === a &&
            item.cap_material === type

      ).id,
    }));
    if (visorTypeId !== null) {
      results[0].id = visorTypeId;
      console.log("new foam result", results);
    }

    if (type === "CARTON") {
      setCap_M();
      console.log("alpha", results[0]);
      setCarton_obj(results[0]);
    }
    if (type === "PLASTIC BAG") {
      setCap_M();
      console.log("alpha", results[0]);
      setPlastic_obj(results[0]);
    }

  }
}else{
  if (type === "CARTON" || type === "PLASTIC BAG") {

  const filteredItems = colors?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some(
        (capData) =>
          item.type === data &&
          capData.cap_type === a &&
          item.cap_material === type

      )
  );

  const results = filteredItems.map((item) => ({
    cloth_id: item.id,
    cap_id: item.cap_data?.find(
      (capData) =>
        item.type === data &&
        capData.cap_type === a &&
          item.cap_material === type

    ).id,
  }));

  console.log("off",results);

  if (type === "CARTON") {
    setCap_M();
    console.log("alpha", results[0]);
    setCarton_obj(results[0]);
  }
  if (type === "PLASTIC BAG") {
    setCap_M();
    console.log("alpha", results[0]);
    setPlastic_obj(results[0]);
  }}
}
} catch (error) {
  console.log("error",error)
 }  
}


  const objfun = (type, data) => {

    try {
      
    
    if (formValues) {
      console.log("inif");
      console.log(type);
      if(type==="BUCKLE"){
        function findCapMaterialWithVisorType(data) {
          for (const item of data.manufacturedcapdata_set) {
            const capMaterial = item.cap_material;
            if (capMaterial && capMaterial.cap_material === type) {
              return item.id;
            }
          }
          return null; // Return null if no cap material with visor type found
        }

        const visorTypeId = findCapMaterialWithVisorType(
          manufacture_capdata
        );
        console.log("Visor Type ID:", visorTypeId);
        const filteredItems = colors?.filter(
          (item) =>
            item.cap_data &&
            item.cap_data?.some(
              (capData) =>
                item.type === data &&
                capData.cap_type === a &&
                item.cap_material === type
      
            )
        );
      
        const results = filteredItems.map((item) => ({
          cloth_id: item.id,
          cap_id: item.cap_data?.find(
            (capData) =>
              item.type === data &&
              capData.cap_type === a &&
                item.cap_material === type
      
          ).id,
        }));
        if (visorTypeId !== null) {
          results[0].id = visorTypeId;
          console.log("new foam result", results);
        }
        if (type === "BUCKLE") {
      
          setBulkle_obj(results[0]);
          console.log("alpha", results[0]);
        }
      }
      
      if (type===snap_pack_init||type==="BUTTON"||type===o_ring_init||type===sweat_band_holding_init||type==="BUCKLE BELT"||type === "FOAM" || type === "NONON"||type==="BINDER"||type==="SIZING STRIP"||type==="INTERLINING"||type==="ELASTIC"||type==="FULL CLOSURE ELASTIC"||type==="BRIM FOAM") {
        function findCapMaterialWithVisorType(data) {
          for (const item of data.manufacturedcapdata_set) {
            const capMaterial = item.cap_material;
            if (capMaterial && capMaterial.cap_material === type) {
              return item.id;
            }
          }
          return null; // Return null if no cap material with visor type found
        }

        const visorTypeId = findCapMaterialWithVisorType(
          manufacture_capdata
        );
        console.log("Visor Type ID:", visorTypeId);

        // foam extraction login
        const filteredItems = colors?.filter(
          (item) =>
            item.cap_data &&
            item.cap_data?.some(
              (capData) =>
                item.color === data &&
                capData.cap_type === a &&
                item.cap_material === type

            )
        );

        const results = filteredItems.map((item) => ({
          cloth_id: item.id,
          cap_id: item.cap_data?.find(
            (capData) =>
              item.color === data &&
              capData.cap_type === a &&
                item.cap_material === type

          ).id,
        }));
        if (visorTypeId !== null) {
          results[0].id = visorTypeId;
          console.log("new foam result", results);
        }

        if (type === "BUTTON") {
          setButton_color_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === o_ring_init) {
          setO_ring_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === snap_pack_init) {
          setsnappack_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === sweat_band_holding_init) {
          setSweat_band_holding_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "BUCKLE BELT") {
          setBulkle_belt_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "FOAM") {
          setFoam(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "NONON") {
          setNonon(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "BINDER") {
          setBinder_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "SIZING STRIP") {
          setStrip_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "ELASTIC") {
          setInterLINING(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "FULL CLOSURE ELASTIC") {
          setFull_closer_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "INTERLINING") {
          setElastic_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "BRIM FOAM") {
          setBrim_foam_obj(results[0]);
          console.log("alpha", results[0]);
        }

      }

      if (type === "visor_type") {
        function findCapMaterialWithVisorType(data) {
          for (const item of data.manufacturedcapdata_set) {
            const capMaterial = item.cap_material;
            if (capMaterial && capMaterial.cap_material === "VISOR TYPE") {
              return item.id;
            }
          }
          return null; // Return null if no cap material with visor type found
        }

        const visorTypeId = findCapMaterialWithVisorType(
          manufacture_capdata
        );
        console.log("Visor Type ID:", visorTypeId);

        const separatedParts = data.split(":");

        const beforeColon = separatedParts[0].trim();
        const afterColon = separatedParts[1].trim();

        const desiredItems = colors?.filter(
          (item) =>
            item.type === beforeColon &&
            item.cap_data?.some(
              (cap) => cap.cap_type === a && item.color === afterColon
            )
        );

        const result = desiredItems.map((item) => {
          const { id, cap_data } = item;
          const matchingCapDataIds = cap_data
            .filter((cap) => cap.cap_type === a && item.color === afterColon)
            .map((cap) => cap.id);

          return { cloth_id: id, cap_id: matchingCapDataIds[0] };
        });

        if (visorTypeId !== null) {
          result[0].id = visorTypeId;
        }
        console.log(result[0]);

        setvisior(result[0]);
      }

      if (type === "FUSING"||type==="TAPER") {
        function findCapMaterialWithVisorType(data) {
          for (const item of data.manufacturedcapdata_set) {
            const capMaterial = item.cap_material;
            if (capMaterial && capMaterial.cap_material === type) {
              return item.id;
            }
          }
          return null; // Return null if no cap material with visor type found
        }

        const visorTypeId = findCapMaterialWithVisorType(
          manufacture_capdata
        );
        console.log("Visor Type ID:", visorTypeId);

        console.log();
        // Separate "fusing 67" and "quantity = 12"
        const parts = data.split(" : ");

        // Extract quantity value
        const quantityPart = parts[1].split(" = ");
        const quantityCount = quantityPart[1];

        console.log(parts[0]);
        if (parts[0] === "null") {
          parts[0] = null;
        }
        console.log(quantityCount);
        const desiredItems = colors?.filter(
          (item) =>
            item.type === parts[0] &&
            item.color === quantityCount &&
            item.cap_material===type&&
            item.cap_data?.some((cap) => cap.cap_type === a)
        );
        console.log(desiredItems);
        const result = desiredItems.map((item) => {
          const { id, cap_data } = item;
          const matchingCapDataIds = cap_data
            .filter(
              (cap) =>
                cap.cap_type === a &&
                // cap.quantity === parseFloat(quantityCount)
                item.color === quantityCount
            )
            .map((cap) => cap.id);

          return { cloth_id: id, cap_id: matchingCapDataIds[0] };
        });

        if (visorTypeId !== null) {
          result[0].id = visorTypeId;
        }

        console.log(result[0]);
        if(type==="FUSING"){
console.log("selected",result[0])
          setfusing(result[0]);
        }
        if(type==="TAPER"){
settaper_obj(result[0])
        }
      }

      if (type === "sweat_type") {
        function findCapMaterialWithVisorType(data) {
          for (const item of data.manufacturedcapdata_set) {
            const capMaterial = item.cap_material;
            if (capMaterial && capMaterial.cap_material === "SWEATE BAND") {
              return item.id;
            }
          }
          return null; // Return null if no cap material with visor type found
        }

        const visorTypeId = findCapMaterialWithVisorType(
          manufacture_capdata
        );
        console.log("Visor Type ID:", visorTypeId);

        const parts = data.split(" : ");

        // Extract quantity value
        const quantityPart = parts[1].split(" = ");
        const quantityCount = quantityPart[1];

        console.log(parts[0]);
        console.log(quantityCount);
        console.log(a);

        const desiredItems = colors?.filter(
          (item) =>
            item.type === parts[0] &&
            item.cap_data?.some(
              (cap) => cap.cap_type === a && item.color === quantityCount
            )
        );

        console.log(desiredItems);
        console.log(
          colors?.filter(
            (item) =>
              item.type === parts[0] &&
              item.cap_data?.filter(
                (cap) => cap.cap_type === a && item.color === quantityCount
              )
          )
        );
        const result = desiredItems.map((item) => {
          const { id, cap_data } = item;
          const matchingCapDataIds = cap_data
            .filter((cap) => cap.cap_type === a && item.color === quantityCount)
            .map((cap) => cap.id);

          return { cloth_id: id, cap_id: matchingCapDataIds[0] };
        });

        if (visorTypeId !== null) {
          result[0].id = visorTypeId;
        }

        setsweat_bands(result[0]);
      }
    } else {

if(type==="BUCKLE"){
  const filteredItems = colors?.filter(
    (item) =>
      item.cap_data &&
      item.cap_data?.some(
        (capData) =>
          item.type === data &&
          capData.cap_type === a &&
          item.cap_material === type

      )
  );

  const results = filteredItems.map((item) => ({
    cloth_id: item.id,
    cap_id: item.cap_data?.find(
      (capData) =>
        item.type === data &&
        capData.cap_type === a &&
          item.cap_material === type

    ).id,
  }));
  if (type === "BUCKLE") {
    setCap_M();

    setBulkle_obj(results[0]);
    console.log("alpha", results[0]);
  }
}


      //ONLY FOR COLOR SELECTION
      if (type==="SNAPACK"||type==="BUTTON"||type==="O-RING"||type===sweat_band_holding_init||type==="BUCKLE BELT"||type === "FOAM" || type === "NONON"||type==="BINDER"||type==="SIZING STRIP"||type==="INTERLINING"||type==="ELASTIC"||type==="FULL CLOSURE ELASTIC"||type==="BRIM FOAM") {


        const filteredItems = colors?.filter(
          (item) =>
            item.cap_data &&
            item.cap_data?.some(
              (capData) =>
                item.color === data &&
                capData.cap_type === a &&
                item.cap_material === type

            )
        );

        const results = filteredItems.map((item) => ({
          cloth_id: item.id,
          cap_id: item.cap_data?.find(
            (capData) =>
              item.color === data &&
              capData.cap_type === a &&
                item.cap_material === type

          ).id,
        }));

        console.log("off",results);

        if (type === "BUTTON") {
          setCap_M();
          console.log("alpha", results[0]);
          setButton_color_obj(results[0]);
        }
        if (type === sweat_band_holding_init) {
          setCap_M();
          console.log("alpha", results[0]);
          setSweat_band_holding_obj(results[0]);
        }

        if (type === "O-RING") {
          setCap_M();
          console.log("alpha", results[0]);
          setO_ring_obj(results[0]);
        }
        if (type === "SNAPACK") {
          setCap_M();
          console.log("alpha", results[0]);
          setsnappack_obj(results[0]);
        }
        if (type === "BUCKLE BELT") {
          setCap_M();
          console.log("alpha", results[0]);
          setBulkle_belt_obj(results[0]);
        }

        if (type === "FOAM") {
          setCap_M();
          console.log("alpha", results[0]);
          setFoam(results[0]);
        }
        if (type === "NONON") {
          setCap_M();
          console.log("alpha", results[0]);
          setNonon(results[0]);
        }
        if (type === "BINDER") {
          setCap_M();

          setBinder_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "SIZING STRIP") {
          setCap_M();

          setStrip_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "ELASTIC") {
          setCap_M();

          setInterLINING(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "FULL CLOSURE ELASTIC") {
          setCap_M();

          setFull_closer_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "INTERLINING") {
          setCap_M();

          setElastic_obj(results[0]);
          console.log("alpha", results[0]);
        }
        if (type === "BRIM FOAM") {
          setCap_M();

          setBrim_foam_obj(results[0]);
          console.log("alpha", results[0]);
        }
   
      }

      if (type === "visor_type") {
        const separatedParts = data.split(":");

        const beforeColon = separatedParts[0].trim();
        const afterColon = separatedParts[1].trim();

        const desiredItems = colors?.filter(
          (item) =>
            item.type === beforeColon &&
            item.cap_data?.some(
              (cap) => cap.cap_type === a && item.color === afterColon
            )
        );

        const result = desiredItems.map((item) => {
          const { id, cap_data } = item;
          const matchingCapDataIds = cap_data
            .filter((cap) => cap.cap_type === a && item.color === afterColon)
            .map((cap) => cap.id);

          return { cloth_id: id, cap_id: matchingCapDataIds[0] };
        });

        console.log(result[0]);
        setvisior(result[0]);
      }

      if (type === "FUSING"||type==="TAPER") {

        // Separate "fusing 67" and "quantity = 12"
        const parts = data.split(" : ");
        // Extract quantity value
        if (parts[0] === "null") {
          console.log("tiger",parts[0])
          parts[0] = null;
          console.log("tiger",parts[0])
        }

        const quantityPart = parts[1].split(" = ");
        const quantityCount = quantityPart[1];

        // console.log(parts[0]);
        console.log(quantityCount);

        const desiredItems = colors?.filter(
          (item) =>
            item.type === parts[0] &&
            item.color === quantityCount &&
            item.cap_material===type&&
            item.cap_data?.some((cap) => cap.cap_type === a)
        );

        const result = desiredItems.map((item) => {
          const { id, cap_data } = item;
          const matchingCapDataIds = cap_data
            .filter(
              (cap) =>
                cap.cap_type === a &&
                // cap.quantity === parseFloat(quantityCount)
                item.color === quantityCount
            )
            .map((cap) => cap.id);

          return { cloth_id: id, cap_id: matchingCapDataIds[0] };
        });
        if(type==="FUSING"){
console.log("selectedd",result[0])
          setfusing(result[0]);
        }
        if(type==="TAPER"){
settaper_obj(result[0])
        }
      }

      if (type === "sweat_type") {
        const parts = data.split(" : ");

        // Extract quantity value
        const quantityPart = parts[1].split(" = ");
        const quantityCount = quantityPart[1];

        const desiredItems = colors?.filter(
          (item) =>
            item.type === parts[0] &&
            item.cap_data?.some(
              (cap) => cap.cap_type === a && item.color === quantityCount
            )
        );

        const result = desiredItems.map((item) => {
          const { id, cap_data } = item;
          const matchingCapDataIds = cap_data
            .filter((cap) => cap.cap_type === a && item.color === quantityCount)
            .map((cap) => cap.id);

          return { cloth_id: id, cap_id: matchingCapDataIds[0] };
        });

        setsweat_bands(result[0]);
      }
      // console.log(visior)

      console.log(data);
      // setMat(arr=>[...arr,])
    }
  
  
      
  } catch (error) {
    console.log("error",type,data)
    
  }
  };

  // colors
  const [frontcolor, setFrontColor] = useState(null);
  const [backcolor, setbackColor] = useState(null);
  const [middlecolor, setmiddleColor] = useState(null);
  const [topcolor, settopColor] = useState(null);
  const [undercolor, setunderColor] = useState(null);
  const [pipingcolor, setpipingColor] = useState(null);
  // fabric type


useEffect(() => {
if(formValues){
  setFronttype(formValues?.main_fabric_Front_type)
  setbacktype(formValues?.main_fabric_Back_type)
  setmiddletype(formValues?.main_fabric_Middle_type)
  settoptype(formValues?.main_fabric_Top_type)
  setundertype(formValues?.main_fabric_Under_type)
  setpipingtype(formValues?.piping_type)
  // setBack_closer(formValues?.back_close_type)
  setBack_closer_colors(
    colors?.filter((item) => item?.type === formValues?.back_close_type).filter((it)=>it.cap_data?.some((op)=>op.cap_type===a)).map((it) => it.color)
  );
}
}, [formValues]);



  const [fronttype, setFronttype] = useState(null);
  const [backtype, setbacktype] = useState(null);
  const [middletype, setmiddletype] = useState(null);
  const [toptype, settoptype] = useState(null);
  const [undertype, setundertype] = useState(null);
  const [pipingtype, setpipingtype] = useState(null);
  //cap_data_id which is cap_data obj id
  const [cap_data_id1, setCap_data_id1] = useState(null);
  const [cap_data_id2, setCap_data_id2] = useState(null);
  const [cap_data_id3, setCap_data_id3] = useState(null);
  const [cap_data_id4, setCap_data_id4] = useState(null);
  const [cap_data_id5, setCap_data_id5] = useState(null);
  const [cap_data_id6, setCap_data_id6] = useState(null);
  //cloth_id whuch is id=1
  const [cloth_id1, setcloth_id1] = useState(null);
  const [cloth_id2, setcloth_id2] = useState(null);
  const [cloth_id3, setcloth_id3] = useState(null);
  const [cloth_id4, setcloth_id4] = useState(null);
  const [cloth_id5, setcloth_id5] = useState(null);
  const [cloth_id6, setcloth_id6] = useState(null);

  const offset = 2;
  const span = 9;
  const qu_span = 2;
  // send all jobcard data to this createJobcard
  const [createJobcard, createResponseInfo] = useCreateJobcardMutation();

  // send cap data to createCap

  // update jobcard
  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();
  const [updateManufacture_cap, updateManuResponseInfo] =
    useUpdateManufacturerMutation();

  useEffect(() => {
    // console.log("effectssss")
    // console.log(updateManuResponseInfo.isSuccess===true)
    if(formValues&& updateManuResponseInfo.status==="rejected"){

message.error(updateManuResponseInfo.error.data.message)
    }
    if (formValues && updateManuResponseInfo.isSuccess === true) {
      updateJobcard(formval);
    }
  }, [updateManuResponseInfo]);
  // const clients = useSelector((state) => state.JobCard.clients);

  const role = useSelector(roleSelector);

  const showPriority = () => [ROLES.ADMIN].includes(role);

  const showAdmin = () => showPriority();

  useEffect(() => {
    dispatch(allDropDowns());
    // dispatch(fetchColor(""))
    getUpadtedcolor();
    getCapName();
  }, []);

  useEffect(() => {
    setRender(false);
    console.log(formValues);
    if (formValues) {
      setInitialValues({
        ...formValues,
        date: formValues?.date ? moment(formValues?.date, "DD/MM/YYYY") : null,
        deliver_date: formValues?.deliver_date
          ? moment(formValues?.deliver_date, "DD/MM/YYYY")
          : null,
      });
      console.log("here");
      console.log({
        ...formValues,
        date: formValues?.date ? moment(formValues?.date, "DD/MM/YYYY") : null,
        deliver_date: formValues?.deliver_date
          ? moment(formValues?.deliver_date, "DD/MM/YYYY")
          : null,
      });
    }
    setRender(true);
  }, [formValues]);

  const [buckelbelt_quantity, setBuckelbelt_quantity] = useState();
  const [bulkle_belt_obj, setBulkle_belt_obj] = useState([]);
const [o_ring_obj, setO_ring_obj] = useState([]);
const [snappack_obj, setsnappack_obj] = useState([]);
const [button_color_obj, setButton_color_obj] = useState([]);
const [sweat_band_holding_obj, setSweat_band_holding_obj] = useState();
const [buckel, setBuckel] = useState();
  const [bulkle_obj, setBulkle_obj] = useState([]);

  const buckle = (data) => {
    console.log(data);
    // const splitString = data.split(":");

    // Accessing the values before and after the colon
    // const belt_type = splitString[0].trim();
    // const belt_color = splitString[1].trim();
    const belt_type = data;
    console.log(belt_type);
    const quantities = colors?.reduce((acc, item) => {
      if (
        item.cap_data &&
        item.cap_data?.some(
          (capData) =>
            capData.cap_type === a &&
            item.cap_material === "BUCKLE" &&
            item.type === belt_type
        )
      ) {
        const matchingCapData = item.cap_data?.filter(
          (capData) =>
            capData.cap_type === a &&
            item.cap_material === "BUCKLE" &&
            item.type === belt_type
        );

        matchingCapData.forEach((capData) => {
          const entry = {
            cloth_id: item.id,
            cap_id: capData.id,
            quantity: capData.quantity,
          };
          acc.push(entry);
        });
      }
      return acc;
    }, []);

    // console.log("p",quantities)
    setBuckel(quantities[0].quantity);
    delete quantities[0].quantity;
    setBulkle_obj(quantities[0]);
  };




const [interLINING, setInterLINING] = useState();











  useEffect(() => {
    setRender(false);
    if (selections) {
      setRender(true);
    }
  }, [selections]);
  const {
    error: failedToUpdate,
    isSuccess: updateSuccess,
    isLoading: updating,
  } = updateResponseInfo;
  const {
    error,
    isSuccess: createSuccess,
    isLoading: creating,
  } = createResponseInfo;
  if (error) message.error("Failed to create job cardsss");

  if (failedToUpdate) message.error("Failed to update job card");

  if (updateSuccess) {
    message.success("Jobcard updated successfully!");
    navigate("/jobcard");
  }

  if (createSuccess) {
    message.success("Jobcard created successfully!");
    navigate("/jobcard");
  }
  return render &&
    !(loading || dropdownLoading) &&
    Object.keys(selections).length > 0 ? (
    <Style>
      {(updating || creating) && <Loader />}
      {/* <button onClick={thisfun}>LOTS OF FUNN</button> */}
      <Form
        form={form}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        onFinish={(values) => {
          if (formValues) {
            values.main_fabric_Front_color = frontcolor;
            values.main_fabric_Back_color = backcolor;
            values.main_fabric_Middle_color = middlecolor;
            values.main_fabric_Top_color = topcolor;
            values.main_fabric_Under_color = undercolor;
            values.piping_color = pipingcolor;
            handleFormUpdate(
              {
                ...values,
                id: formValues?.id,
              },
              updateJobcard,
              thisfun
            );
          } else {
            values.main_fabric_Front_color = frontcolor;
            values.main_fabric_Back_color = backcolor;
            values.main_fabric_Middle_color = middlecolor;
            values.main_fabric_Top_color = topcolor;
            values.main_fabric_Under_color = undercolor;

            values.piping_color = pipingcolor;

            handleFormSubmit(
              {
                ...values,
              },
              createJobcard,
              thisfun,
              creatCapResponseInfo.status,
              colors
            );
          }
        }}
        initialValues={initialValues}
      >
        {showPriority() && (
          <Form.Item name="priority" label="Priority">
            <Slider
              style={{
                width: "500px",
                marginLeft: "2rem",
              }}
              marks={{
                0: {
                  style: {
                    color: "#1a1a1a",
                  },
                  label: "None",
                },
                1: {
                  style: {
                    color: "#00ff2a",
                  },
                  label: "Low",
                },
                2: {
                  style: {
                    color: "#00ccff",
                  },
                  label: "Medium",
                },
                3: {
                  style: {
                    color: "#f5864f",
                  },
                  label: "High",
                },
                4: {
                  style: {
                    color: "#ff3c01",
                  },
                  label: "Critical",
                },
              }}
              defaultValue={formValues?.priority}
              max={4}
            />
          </Form.Item>
        )}

        <Form.Item label="Client" name="client" rules={[{ required: true }]}>
          <Select
            showSearch
            optionFilterProp="children"
            onChange={(value) => {}}
            onSearch={(value) => {}}
          >
            {clients?.map((client) => (
              <Select.Option value={client.id}>
                {client.company_name} , {client.address}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Date" name="date">
          <DatePicker format={"DD/MM/YYYY"} />
        </Form.Item>
        <Form.Item
          label="Delivery Date"
          name="deliver_date"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
        >
          <DatePicker format={"DD/MM/YYYY"} />
        </Form.Item>

        {/* <Form.Item label="Chekbox" name="disabled" valuePropName="checked">
          <Checkbox>Checkbox</Checkbox>
        </Form.Item> */}

        <Form.Item
          label="Job Cordinator"
          name="job_coordinator"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Quantity"
          name="quantity"
          rules={[{ required: true, message: "Required Field!" }]}
        >
          <InputNumber onChange={(value) => setQ_tity(value)} />
        </Form.Item>

        <Form.Item label="Cap Cost" name="rate">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Emb Cost" name="emb_cost">
          <InputNumber />
        </Form.Item>
        <SelectType label="Billing Type" name="billing_type" options={PaymentFieldArray} />

        <SelectType
          label="Style"
          name="style"
          value={getCapName}
          options={selections.style}
        />
        <SelectType label="Size" name="size" options={selections.size} />
        {/* <Form.Item
          label="Artwork"
          name="artwork"
          rules={[
            {
              required: true,
              message: "Required Field!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}
    
        {colors===null||colors===undefined||colorloading ? (
            <div style={{paddingTop:"100px"}}>
{colorloading||manufacture_caploading?
  
  <Skeleton/>:<h2>Please select the cap</h2>
}
            </div>
                ) : (<>
           

        <div className="section">
          <div className="title">Style Information</div>

          <div className="sub-section">
        
              
    <div className="title">Main Fabric</div>

            <Row>
              <Col span={span} offset={offset}>
              
                  <SelectType
                    label="Front Type"
                    name="main_fabric_Front_type"
                    // number={number}\\
                    value={getFabric1}
                    options={cloth_type1}
                    required={true}

                    //store data on an api in array format
                    // options={selections.main_fabric_type_array}
                  />
              </Col>
              {/* //Loading effect because if we dont load the color dropbox the empty 
              array of color is maped and there are no colors 
              in the dropbox */}
              <Col span={span}>
                {frontcolor !== "pending" && frontcolor !== null ? (
                  <p className="cursor-none" onClick={()=>setFrontColor("pending")} name="main_fabric_Front_color">
                    Front Color :<span> {frontcolor}</span>
                  </p>
                ) : (
                  <>
                    {colorx.loading || frontcolor === null ? (
                      <div className="cursor-none">Please Set The Type</div>
                    ) : (
                      <p>
                        {frontcolor === "pending" ? (
                          <SelectType
                            label="Front Color"
                            name="main_fabric_Front_color"
                            value={getColor1}
                            //select colors from that array of the object and only show color in the drop box
                            // options={colorx.users}
                            
                            options={colors
                              ?.filter(
                                (item) =>
                                  item.cloth_type === fronttype &&
                                  item.cap_data?.some(
                                    (cap) =>
                                      cap.cap_type === a &&
                                      cap.cap_side_type === f1
                                  )
                              )
                              .map((item) => item.color)}
                            // options={colorx.users.filter((item)=>item.cap_data!==null&&item.cap_data?.quantity!==0).map((c)=>c.color)}
                            // options={colorx.users.map((colorx) => colorx.color)}
                            required={true}
                          />
                        ) : (
                          <p className="cursor-none" >
                            Front Color :<span> {frontcolor}</span>
                          </p>
                        )}
                      </p>
                    )}
                  </>
                )}
              </Col>
              <Col span={qu_span}>Quantity : {quantity1}</Col>
            </Row>
            {a === "BUCKET HAT" ||
            a === "UMPIRE HAT" ||
            a === "REVERSIBLE BUCKET HAT" ? (
              <></>
            ) : (
              <>
                <Row>
                  <Col span={span} offset={offset}>
                    <SelectType
                      required={true}
                      label="Back Type"
                      name="main_fabric_Back_type"
                      // number={number}\\
                      value={getFabric2}
                      options={cloth_type2}
                      //store data on an api in array format
                      // options={selections.main_fabric_type_array}
                    />
                  </Col>
                  {/* //Loading effect because if we dont load the color dropbox the empty 
              array of color is maped and there are no colors 
              in the dropbox */}
                  <Col span={span}>
                    {backcolor !== "pending" && backcolor !== null ? (
                      <p className="cursor-none" onClick={()=>setbackColor("pending")}>
                        Back Color :<span> {backcolor}</span>
                      </p>
                    ) : (
                      <>
                        {colorx.loading || backcolor === null ? (
                          <div className="cursor-none">Please Set The Type</div>
                        ) : (
                          <p>
                            {backcolor === "pending" ? (
                              <SelectType
                                required={true}
                                label="Back Color"
                                name="main_fabric_Back_color"
                                value={getColor2}
                                //select colors from that array of the object and only show color in the drop box
                                options={colors
                                  ?.filter(
                                    (item) =>
                                      item.cloth_type === backtype &&
                                      item.cap_data?.some(
                                        (cap) =>
                                          cap.cap_type === a &&
                                          cap.cap_side_type === f2
                                      )
                                  )
                                  .map((item) => item.color)}
                              />
                            ) : (
                              <p className="cursor-none">
                                Back Color :<span> {backcolor}</span>
                              </p>
                            )}
                          </p>
                        )}
                      </>
                    )}
                  </Col>
                  <Col span={qu_span}>Quantity : {quantity2}</Col>
                </Row>
                <Row>
                  <Col span={span} offset={offset}>
                    <SelectType
                      required={true}
                      label="Middle Type"
                      name="main_fabric_Middle_type"
                      // number={number}\\
                      value={getFabric3}
                      options={cloth_type3}

                      //store data on an api in array format
                      // options={selections.main_fabric_type_array}
                    />
                  </Col>
                  {/* //Loading effect because if we dont load the color dropbox the empty 
              array of color is maped and there are no colors 
              in the dropbox */}
                  <Col span={span}>
                    {middlecolor !== "pending" && middlecolor !== null ? (
                      <p className="cursor-none" onClick={()=>setmiddleColor("pending")}>
                        Middle Color :<span> {middlecolor}</span>
                      </p>
                    ) : (
                      <>
                        {colorx.loading || middlecolor === null ? (
                          <div className="cursor-none">Please Set The Type</div>
                        ) : (
                          <p>
                            {middlecolor === "pending" ? (
                              <SelectType
                                required={true}
                                label="Middle Color"
                                name="main_fabric_Middle_color"
                                value={getColor3}
                                //select colors from that array of the object and only show color in the drop box
                                options={colors
                                  ?.filter(
                                    (item) =>
                                      item.cloth_type === middletype &&
                                      item.cap_data?.some(
                                        (cap) =>
                                          cap.cap_type === a &&
                                          cap.cap_side_type === f3
                                      )
                                  )
                                  .map((item) => item.color)}
                              />
                            ) : (
                              <p className="cursor-none">
                                Middle Color :<span> {middlecolor}</span>
                              </p>
                            )}
                          </p>
                        )}
                      </>
                    )}
                  </Col>
                  <Col span={qu_span}>Quantity : {quantity3}</Col>
                </Row>
              </>
            )}

            <Row>
              <Col span={span} offset={offset}>
                <SelectType
                  required={true}
                  label="Top Type"
                  name="main_fabric_Top_type"
                  // number={number}\\
                  value={getFabric4}
                  options={cloth_type4}

                  //store data on an api in array format
                  // options={selections.main_fabric_type_array}
                />
              </Col>
              {/* //Loading effect because if we dont load the color dropbox the empty 
              array of color is maped and there are no colors 
              in the dropbox */}
              <Col span={span}>
                {topcolor !== "pending" && topcolor !== null ? (
                  <p className="cursor-none" onClick={()=>settopColor("pending")}>
                    Top Color :<span> {topcolor}</span>
                  </p>
                ) : (
                  <>
                    {colorx.loading || topcolor === null ? (
                      <div className="cursor-none">Please Set The Type</div>
                    ) : (
                      <p>
                        {topcolor === "pending" ? (
                          <SelectType
                            required={true}
                            label="Top Color"
                            name="main_fabric_Top_color"
                            value={getColor4}
                            //select colors from that array of the object and only show color in the drop box
                            options={colors
                              ?.filter(
                                (item) =>
                                  item.cloth_type === toptype &&
                                  item.cap_data?.some(
                                    (cap) =>
                                      cap.cap_type === a &&
                                      cap.cap_side_type === f4
                                  )
                              )
                              .map((item) => item.color)}
                          />
                        ) : (
                          <p className="cursor-none">
                            Top Color :<span> {topcolor}</span>
                          </p>
                        )}
                      </p>
                    )}
                  </>
                )}
              </Col>
              <Col span={qu_span}>Quantity : {quantity4}</Col>
            </Row>
            <Row>
              <Col span={span} offset={offset}>
                <SelectType
                  required={true}
                  label="Under Type"
                  name="main_fabric_Under_type"
                  // number={number}\\
                  value={getFabric5}
                  options={cloth_type5}

                  //store data on an api in array format
                  // options={selections.main_fabric_type_array}
                />
              </Col>
              {/* //Loading effect because if we dont load the color dropbox the empty 
              array of color is maped and there are no colors 
              in the dropbox */}
              <Col span={span}>
                {undercolor !== "pending" && undercolor !== null ? (
                  <p className="cursor-none" onClick={()=>setunderColor("pending")}>
                    Under Color :<span> {undercolor}</span>
                  </p>
                ) : (
                  <>
                    {colorx.loading || undercolor === null ? (
                      <div className="cursor-none">Please Set The Type</div>
                    ) : (
                      <p>
                        {undercolor === "pending" ? (
                          <SelectType
                            required={true}
                            label="Under Color"
                            name="main_fabric_Under_color"
                            value={getColor5}
                            //select colors from that array of the object and only show color in the drop box
                            options={colors
                              ?.filter(
                                (item) =>
                                  item.cloth_type === undertype &&
                                  item.cap_data?.some(
                                    (cap) =>
                                      cap.cap_type === a &&
                                      cap.cap_side_type === f5
                                  )
                              )
                              .map((item) => item.color)}
                          />
                        ) : (
                          <p className="cursor-none">
                            Under Color :<span> {undercolor}</span>
                          </p>
                        )}
                      </p>
                    )}
                  </>
                )}
              </Col>
              <Col span={qu_span}>Quantity : {quantity5}</Col>
            </Row>
            <Row>
              <Col span={span} offset={offset}>
                <SelectType
                  //  required={true}

                  label="Piping Type"
                  name="piping_type"
                  // number={number}\\
                  value={getFabric6}
                  options={cloth_type6}

                  //store data on an api in array format
                  // options={selections.main_fabric_type_array}
                />
              </Col>
              {/* //Loading effect because if we dont load the color dropbox the empty 
              array of color is maped and there are no colors 
              in the dropbox */}

              <Col span={span}>
                {pipingcolor !== "pending" && pipingcolor !== null ? (
                  <p className="cursor-none" onClick={()=>setpipingColor("pending")}>
                    Piping Color :<span> {pipingcolor}</span>
                  </p>
                ) : (
                  <>
                    {colorx.loading || pipingcolor === null ? (
                      <div className="cursor-none">Please Set The Type</div>
                    ) : (
                      <p>
                        {pipingcolor === "pending" ? (
                          <SelectType
                            // required={true}

                            label="Piping Color"
                            name="piping_color"
                            value={getColor6}
                            //select colors from that array of the object and only show color in the drop box
                            // options={colors?.filter(
                            //     (items) =>
                            //       items &&
                            //       items.cap_data?.cap_type === a &&
                            //       items.cloth_type === pipingtype &&
                            //       items.cap_data?.cap_side_type === f6
                            //   )
                            //   .map((c) => c.color)}

                            options={      colors?.filter((item) => item?.type === pipingtype&&item.cap_material===f6).filter((it)=>it.cap_data?.some((op)=>op.cap_type===a)).map((it) => it.color)
                            }
                          />
                        ) : (
                          <p className="cursor-none">
                            Piping Color :<span> {pipingcolor}</span>
                          </p>
                        )}
                      </p>
                    )}
                  </>
                )}
              </Col>
              <Col span={qu_span}>Quantity : {quantity6}</Col>
            </Row>
          
          </div>
          {a=== "5 PART BASE BALL CAP" || a==="DAD HAT 5 PART BASE BALL CAP"||a==="DAD HAT 6 PART BASE BALL CAP"||a==="DAD HAT 5 PART HIP HOP CAP"||a==="DAD HAT 6 PART HIP HOP CAP"||a==="6 PART BASE BALL CAP"||a==="5 PART HIP HOP CAP"||a==="6 PART HIP HOP CAP"||a==="MANUAL"||a==="BUCKET HAT"||a=== "REVERSIBLE BUCKET HAT"||a==="UMPIRE HAT"
          
          
          ?
<>
<SelectType
            label="Fusing"
            name="fusing"
            // options={selections.fusing_array}
            options={fushing_data}
            value={(data) => objfun(fusing_type, data)}
            />
               <SelectType
                label="Foam"
                name="foam"
                // options={selections.fusing_array}
                options={foam_data}
                value={(data) => objfun(foam_type, data)}
              />
            </>
            :null
          }
          {a==="MANUAL"||a=== "5 PART BASE BALL CAP" || a==="DAD HAT 5 PART BASE BALL CAP"||a==="DAD HAT 6 PART BASE BALL CAP"||a==="DAD HAT 5 PART HIP HOP CAP"||a==="DAD HAT 6 PART HIP HOP CAP"||a==="6 PART BASE BALL CAP"||a==="5 PART HIP HOP CAP"||a==="6 PART HIP HOP CAP"?
            <>
             
             <SelectType
                label="Buckle"
                name="buckle"
                // options={selections.fusing_array}
                options={buckle_data}
                value={(data) => objfun(buckel_init, data)}
              />
              <SelectType
                label="Buckle Belt"
                name="buckle_belt"
                // options={selections.fusing_array}
                options={buckle_belt_data}
                value={(data) => objfun(buckle_belt_init, data)}
              />
              <SelectType
                label="Nonon"
                name="nonon"
                // options={selections.fusing_array}
                options={nonon_data}
                value={(data) => objfun(nonon_type, data)}
              />
               <SelectType
            label="Taper"
            name="tapper"
            options={taper_data_extract}
            value={(data) => objfun(taper, data)}

            // options={tyeps}
          />
           <SelectType
          label="Cap Button"
          name="cap_button"
          options={colors?.filter((item)=>item?.cap_material===button&&item?.cap_data?.some((it)=>it.cap_type===a)).map((op)=>op.cap_material)}
          value={getbutton}
        />
           <SelectType
          label="Button Color"
          name="button"
          options={button_data}
                value={(data) => objfun("BUTTON", data)}

        />
           <SelectType
          label="Binder"
          name="binder"
          options={binder_data}
                value={(data) => objfun(BINDER, data)}

        />
           <SelectType
          label="SIZING STRIP"
          name="sizing_strip"
          options={sizing_strip}
                value={(data) => objfun(STRIP, data)}

        />
              </>:null
          }
  
          
          {a === "UMPIRE HAT" ? (
            <SelectType
              label="BRIM FOAM"
              name="brim_foam"
              // options={selections.visor_type_array}
              options={brim_foam_data}
              value={(data) => objfun(brim_foam_init, data)}
            />
          ) : null}
             {a === "UMPIRE HAT" ?
        null: <SelectType
          label="Visior Type"
          name="visior_type"
          // options={selections.visor_type_array}
          options={tyeps}
          value={(data) => objfun(visor_type, data)}
        />}
         


          <SelectType
            label="Visor Stitch"
            name="visor_stitch"
            options={selections.visor_stitch_array}
          />

          <div className="sub-section">
            <div className="title">Sweatband</div>

            <SelectType
              label="Type"
              name="sweate_band_type"
              options={sweat_band}
              value={(data) => objfun(sweatsss, data)}
              />
          </div>
          {a === "SUN CAP ONE" ||
          a === "SUN CAP TWO" ||
         a=== "5 PART BASE BALL CAP" || a==="DAD HAT 5 PART BASE BALL CAP"||a==="DAD HAT 6 PART BASE BALL CAP"||a==="DAD HAT 5 PART HIP HOP CAP"||a==="DAD HAT 6 PART HIP HOP CAP"||a==="6 PART BASE BALL CAP"||a==="5 PART HIP HOP CAP"||a==="6 PART HIP HOP CAP"||a==="MANUAL"?  <>
          <div className="sub-section">
            <div className="title">Back Closer</div>
            <SelectType
              label="Type"
              name="back_close_type"
              // options={selections.back_closer_type}
              options={back_closer_data}
              value={getCloser}
            />
            <SelectType
              label="Color"
              name="back_closer_color"
              options={back_closer_colors}
              value={getCloser_color}
            />
            <SelectType
              label="Snappack"
              name="snapack"
              options={snappack_data}
              value={(data)=>objfun(snap_pack_init,data)}
            />
         
          </div> </> : (
           null
          )}

<SelectType
              label="O-Ring"
              name="o_ring"
              options={o_ring_data}
              value={(data)=>objfun(o_ring_init,data)}
            />

<SelectType
              label="Sweat Band Holding"
              name="sweat_band_holding"
              options={sweat_band_holding_data}
              value={(data)=>objfun(sweat_band_holding_init,data)}
            />

  {a === "BAGGY CAP" ||
          a === "DEVANAND CAP" ||
          a=== "5 PART BASE BALL CAP" || a==="DAD HAT 5 PART BASE BALL CAP"||a==="DAD HAT 6 PART BASE BALL CAP"||a==="DAD HAT 5 PART HIP HOP CAP"||a==="DAD HAT 6 PART HIP HOP CAP"||a==="6 PART BASE BALL CAP"||a==="5 PART HIP HOP CAP"||a==="6 PART HIP HOP CAP"||a==="MANUAL" ?  <>
          <div className="sub-section">
            <div className="title">Elastic</div>
            
       
            <SelectType
            
            
            label="Elastic"
            name="elastic"
            options={colors?.filter((item)=>item?.cap_material==="ELASTIC"&&item.cap_data?.some((it)=>it.cap_type===a)).map((op)=>op.color)}
            value={(data) => objfun(elastic_init, data)}

            />
            <SelectType
            
            
            label="Interlining"
            name="interlining"
            options={colors?.filter((item)=>item?.cap_material==="INTERLINING"&&item.cap_data?.some((it)=>it.cap_type===a)).map((op)=>op.color)}
            value={(data) => objfun(interlining_init, data)}

            />


{a==="DEVANAND CAP"?null:   <SelectType
            
            
            label="Full Elastic"
            name="full_closure_elastic"
            options={colors?.filter((item)=>item?.cap_material==="FULL CLOSURE ELASTIC"&&item.cap_data?.some((it)=>it.cap_type===a)).map((op)=>op.color)}
            value={(data) => objfun(full_closer_init, data)}

            />

}

          </div>
        </> : (
           null
          )}
        </div>
        <div className="sub-section">
            <div className="title">Other Materials</div>

            <SelectType
              label="Carton"
              name="carton"
              options={carton_data}
              value={(data) => fun_for_type(carton_init, data)}
              />
            <SelectType
              label="Plastic Bag"
              name="plastic_bag"
              options={plastic_bag_data}
              value={(data) => fun_for_type(plastic_init, data)}
              />
          </div>

        <div className="sub-section">
            <div className="title">Eyelet</div>

            <SelectType
              label="Front"
              name="eyelet_front"
              options={selections.eyelet_front_array}
            />
            <SelectType
              label="Back"
              name="eyelet_back"
              options={selections.eyelet_back_array}
            />
            {console.log("selections",selections)}
   
            <SelectType
              label="Middles"
              name="eyelet_middle"
              options={selections.eyelet_middle_array}
            />
          </div>
          </>)}
        <div className="section">
          <div className="title">Embroidary Placement</div>
          <Row>
            <Col span={10} offset={2}>
              <Form.Item label="Front" name="embr_Front">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Back" name="embr_Back">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item label="Left Middle" name="embr_Left_Middle">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Right Middle" name="embr_Right_Middle">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10} offset={2}>
              <Form.Item label="Visor" name="embr_Visor">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Velcro" name="embr_Velcro">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} offset={2}>
              <Form.Item label="Visor Square" name="embr_Visor_Square">
                <Input />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="One Side Emb" name="embr_One_Side_Emb">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item label="Special Instructions" name="special_instructions">
          <Input />
        </Form.Item>
        {/* <Form.Item label="Images" valuePropName="fileList">
          <Upload beforeUpload={(e) => } listType="picture-card">
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Images
              </div>
            </div>
          </Upload>
        </Form.Item> */}
        <>
          <Space
            style={{
              margin: "auto",
              width: "100vw",
              // background: "red",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {formValues && formValues?.img1 && (
              <>
                <h4>Previously Selected Img 1</h4>
                <ImageViewer
                  style={{ height: "100px", width: "100px" }}
                  src={formValues?.img1}
                />
              </>
            )}
            <Form.Item
              name={"img1"}
              label="Select New Image"
              valuePropName="fileList"
              wrapperCol={{ span: 24 }}
              labelCol={{ span: 12 }}
            >
              <input type={"file"} />
            </Form.Item>
          </Space>
        </>
        {/* <Form.Item name={"img2"} valuePropName="fileList" label="Image 2">
          <input type={"file"} />
        </Form.Item>
        <Form.Item name={"img3"} valuePropName="fileList" label="Image 3">
          <input type={"file"} />
        </Form.Item>
        <Form.Item name={"img4"} valuePropName="fileList" label="Image 4">
          <input type={"file"} />
        </Form.Item> */}
        {/* <Form.Item
          name={"front_image"}
          valuePropName="fileList"
          label="Front Image"
        >
          <input type={"file"} />
        </Form.Item>
        <Form.Item
          name={"right_image"}
          valuePropName="fileList"
          label="Right Image"
        >
          <input type={"file"} />
        </Form.Item>
        <Form.Item
          name={"left_image"}
          valuePropName="fileList"
          label="Left Image"
        >
          <input type={"file"} />
        </Form.Item>
        <Form.Item
          name={"back_image"}
          valuePropName="fileList"
          label="Back Image"
        >
          <input type={"file"} />
        </Form.Item>
        <Form.Item
          name={"color_image"}
          valuePropName="fileList"
          label="Color Image"
        >
          <input type={"file"} />
        </Form.Item> */}

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            // style={{ padding: ".5rem 2rem", fontSize: "1rem" }}
            htmlType="submit"
            size="large"
          >
            {formValues ? "Update Jobcard" : "Create Job Card"}
          </Button>
        </Form.Item>
      </Form>
    </Style>
  ) : (
    <Loader />
  );
};

const Style = styled.div`
  padding: 1rem;
  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1rem;
    z-index: 999;
  }
  .section {
    /* width: fit-content; */
    margin-bottom: 20px;
    border: 1px solid #00000068;
    .title {
      padding: 1rem;
    }
  }
  .sub-section {
    border: 1px solid #97939368;
    margin: 1rem;
  }
`;

export default JobCardForm;
