import { Button, Table } from "antd";
import { useFetchInventoryQuery, useFetchJobcardsQuery } from "features/store";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

const InvenReport = () => {
  const { data: cards, isLoading: loading } = useFetchInventoryQuery();
  //   console.log(
  //     cards?.filter((item) => item.current_stage === "completed").map((s) => s)
  //   );
  const headers = [
    { label: "Sr No.", key: "id" },
    { label: "Material Type", key: "color_cloth.material_type" },
    { label: "Cap Material", key: "color_cloth.cap_material" },
    { label: "Type", key: "color_cloth.type" },
    { label: "Cloth Type", key: "color_cloth.cloth_type" },
    { label: "Color", key: "color_cloth.color" },
    { label: "Available Quantity", key: "quantity" },
  ];
  const [completed, setCompleted] = useState([]);
  const [colth_type, setcolth_type] = useState([]);
  const [ultimate, setultimate] = useState([]);
  const getReportarray = () => {
    const result = [];

    cards.forEach((item) => {
      const { color_cloth, inventory_quantity } = item;
      const { color, cloth_type, material_type, cap_material, type } =
        color_cloth;

      inventory_quantity.forEach((inventoryItem) => {
        const { quantity, rate, created_at } = inventoryItem;

        const transformedItem = {
          color,
          cloth_type,
          material_type,
          quantity,
          rate,
          created_at,
          cap_material,
          type,
        };

        result.push(transformedItem);
      });
    });

    const uniqueClothTypes = [
      ...new Set(result.map((item) => item?.cloth_type)),
    ];
    console.log(
      uniqueClothTypes.filter((yo) => yo !== null),
      "unique"
    );
    setcolth_type(uniqueClothTypes.filter((yo) => yo !== null));
    console.log(
      uniqueClothTypes
        .filter((yo) => yo !== null)
        .map((op) => result?.filter((it) => it.cloth_type === op))
    );
    setultimate(
      uniqueClothTypes
        .filter((yo) => yo !== null)
        .map((op) => result?.filter((it) => it.cloth_type === op))
    );
    console.log(result);
    setCompleted(result);
    console.log("comp", completed);
  };
  useEffect(() => {
    if (cards) {
      getReportarray();
    }
  }, [cards]);
  // const completed =cards?.filter((item)=>item.current_stage==="completed").map((s)=>s)
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "index",
      width: "15%",
      render: (text, record, index) => index + 1,
      // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },

    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Rate",
      dataIndex: "rate",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
    {
      title: "Created At",
      dataIndex: "created_at",

      editable: true,
      render: (text) => {
        if (text && moment(text).isValid()) {
          return moment(text).format("YYYY-MM-DD");
        }
        return "N/A";
      },
    },
  ];
  return (
    <>
      {loading ? (
        <p>loading</p>
      ) : (
        <>
          <h1
            style={{
              margin: "30px 0px",
              textAlign: "center",
              fontWeight: "600",
              color: "#00afe2",
              padding: "10px",
            }}
          >
            Inventory Report
          </h1>

          <div style={{ width: "90%", margin: "auto" }}>
            <Button style={{ margin: "3rem" }}>
              {" "}
              <CSVLink
                className="export_to_excel"
                data={cards?.map((card) => {
                  return {
                    ...card,
                  };
                })}
                headers={headers}
                filename={"versatileapparels.csv"}
              >
                Export To Excel Available Quantity
              </CSVLink>
            </Button>
            <Button style={{ margin: "3rem" }}>
              {" "}
              <CSVLink
                className="export_to_excel"
                data={completed?.map((card) => {
                  return {
                    ...card
                  };
                })}
                // headers={headers}

                filename={"versatileapparels.csv"}
              >
                Export To Excel Inventory
              </CSVLink>
            </Button>
            <Style
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              {colth_type?.map((item) => (
                <div style={{ width: "40%" }}>
                  <h2
                    style={{
                      background: "#00afe2",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    {item}
                  </h2>
                  <Table
                    bordered
                    dataSource={completed?.filter(
                      (it) => it.cloth_type === item
                    )}
                    columns={columns}
                    rowClassName="editable-row"
                    className="my-table"
                    //   pagination={{
                    //     onChange: cancel,
                    //   }}
                  />
                </div>
              ))}
              {/* <Table
            bordered
            dataSource={completed}
            columns={columns}
            rowClassName="editable-row"
            className="my-table"
            //   pagination={{
            //     onChange: cancel,
            //   }}
          /> */}
            </Style>
          </div>
        </>
      )}
    </>
  );
};

export default InvenReport;

const Style = styled.div`
  .my-table {
    font-size: 16px;
  }

  .my-table .ant-table-header {
    background-color: var(--color-table-head);
  }

  .my-table .ant-table-thead > tr > th {
    background-color: var(--color-table-head);
    border: 0.1px solid #d9d9d9;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .my-table .ant-table-tbody > tr > td {
    border: 0.2px solid #ebebeb;
  }
`;
