export const PaymentFieldArray = ["Cash", "GST", "Adjustment"];

export const getUniqueColors = (data) => {
  const colorGroups = {};
  data.forEach((item) => {
    const color = item.cap_material.color;
    if (!colorGroups[color]) {
      colorGroups[color] = {
        color,
        material_types: {},
      };
    }

    const materialType = item.cap_material.material_type;
    if (!colorGroups[color].material_types[materialType]) {
      colorGroups[color].material_types[materialType] = [];
    }

    colorGroups[color].material_types[materialType].push(item);
  });

  // For each color group, further organize by material_type
  for (const colorGroup of Object.values(colorGroups)) {
    for (const [materialType, items] of Object.entries(
      colorGroup.material_types
    )) {
      if (materialType === "CLOTH MATERIAL") {
        // Separate by cap_side_type and cloth_type
        const clothGroups = {};
        for (const item of items) {
          const capSideType = item.cap_side_type;
          const clothType = item.cap_material.cloth_type;
          if (!clothGroups[capSideType]) {
            clothGroups[capSideType] = {};
          }
          if (!clothGroups[capSideType][clothType]) {
            clothGroups[capSideType][clothType] = [];
          }
          clothGroups[capSideType][clothType].push(item);
        }
        colorGroup.material_types[materialType] = clothGroups;
      } else if (materialType === "CAP MATERIAL") {
        // Separate by cap_material
        const capMaterialGroups = {};
        for (const item of items) {
          const capMaterialId = item.cap_material.cap_material;
          if (!capMaterialGroups[capMaterialId]) {
            capMaterialGroups[capMaterialId] = [];
          }
          capMaterialGroups[capMaterialId].push(item);
        }
        colorGroup.material_types[materialType] = capMaterialGroups;
      }
    }
  }

  return colorGroups;
};

export const getManufatureDataSet = (data) => {
  const updatedData = data.map((item) => {
    const manufacture_quantity = item.manufacture_cap.manufacture_quantity;
    const updatedManufacturedData = item.manufacture_cap.manufacturedcapdata_set.map(
      (capData) => {
        return {
          ...capData,
          // cap_current_man_quantity: parseFloat((capData.cap_current_man_quantity * manufacture_quantity).toFixed(3))
          cap_current_man_quantity: parseFloat(manufacture_quantity),
        };
      }
    );
    return {
      ...item,
      manufacture_cap: {
        ...item.manufacture_cap,
        manufacturedcapdata_set: updatedManufacturedData,
      },
    };
  });

  // Extract all manufacturedcapdata_set arrays
  const allManufacturedData = updatedData
    .map((item) => item.manufacture_cap.manufacturedcapdata_set)
    .flat();

  // Print the resulting array
  return allManufacturedData;
};

export const getSum = (caps) => {
  const getSumOfQuantity = (caps) => {
    let sum = 0;
    caps.forEach((cap) => {
      sum += cap.cap_current_man_quantity;
    });
    return sum;
  };

  const sumOfQuantity = getSumOfQuantity(caps);

  return sumOfQuantity;
};

export const sumData = (numbers) => {
  const sum = numbers?.reduce((acc, curr) => acc + curr, 0);
  return sum;
};

export const getUniqueColors2 = (data) => {
  const uniqueColors = new Set();
  data.forEach((item) => {
    uniqueColors.add(item.main_fabric_Front_color);
    uniqueColors.add(item.main_fabric_Back_color);
    uniqueColors.add(item.main_fabric_Middle_color);
    uniqueColors.add(item.main_fabric_Top_color);
    uniqueColors.add(item.main_fabric_Under_color);
  });
  return Array.from(uniqueColors);
};
