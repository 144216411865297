import { Button, DatePicker, Form, Space, Table } from "antd";
import Loader from "components/common/Loader";
import CardsTable from "components/MovieListing/CardsTable";
import { CardStyle } from "components/style/componentsStyle";
import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CSVLink, CSVDownload } from "react-csv";
import { ROLES, STAGES, STAGES_LIST } from "components/common/Constants";
import SkeletonButton from "antd/lib/skeleton/Button";
import { getFormalName } from "components/services";
import moment from "moment";
import { roleSelector, UpdateJobCard } from "features/jobinv/JobCardSlice";
import { useNavigate } from "react-router-dom";
import { url } from "components/common/api";
import Skeleton from "components/common/Skeleton";
import ShowCount from "components/CardListing/ShowCount";
import ShowUpdateCount from "components/CardListing/ShowUpdateCount";
import { useGetClientsQuery } from "features/store";

var axios = require("axios");

const fetchJobcardsBetweenRangeAPI = async (
  from = "2022-11-02",
  to = "2022-11-02"
) => {
  var config = {
    method: "get",
    url: `${url}/api/filterbydate?fromdate=${from}&enddate=${to}`,
  };
  const response = await axios(config);
  if (response.status == 200) return response.data;
  return false;
};

function JobCardBetweenDates(props) {
  const navigate = useNavigate();
  const role = useSelector(roleSelector);
  const [cards, setCards] = React.useState([]);
  const [tableCards, setTableCards] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [tableColumns, setTableColumns] = React.useState();

  // const clients = useSelector((state) => state.JobCard.clients);
  const{data:clients, isLoading }=useGetClientsQuery()

  const fetchCards = async (from, to) => {
    setLoading(true);
    const cards = await fetchJobcardsBetweenRangeAPI(from, to);
    if (cards) {
      setCards(cards); console.log("cards",cards)}

    else{

      alert("Failed to fetch job cards between the dates");
    } 
    setLoading(false);
  };

  const handleFinish = (values) => {
    const from = values.from.format("YYYY-MM-DD");
    const to = values.to.format("YYYY-MM-DD");
    fetchCards(from, to);
  };
  const headers = [
    { label: "Job Card No.", key: "id" },
    { label: "Date", key: "date" },
    { label: "Customer Name", key: "client" },
    { label: "Cash", key: "cash" },
    { label: "GST", key: "GST_NO" },
    { label: "Quantity", key: "quantity" },
    { label: "Rate", key: "rate" },
    { label: "Delivery Date", key: "deliver_date" },
    { label: "Style", key: "style" },
    { label: "Current Stage", key: "current_stage" },
  ];

  const dataSource =
    clients?.length > 0
      ? cards.map((card) => ({
          ...card,
          client_company: clients?.find((client) => client.id === card.client)
            ?.company_name,
          client_phone: clients?.find((client) => client.id === card.client)
            ?.phone,
          current_stage:
            card.current_stage == null ? STAGES.CREATION : card.current_stage,
        }))
      : cards;

  const underProcessCards = tableCards.filter(
    (card) => card.current_stage !== STAGES.COMPLETED
  );

  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    {
      title: "Client Company",
      dataIndex: "client_company",
      key: "client_company",
      filters: clients?.map((client) => ({
        text: client.company_name,
        value: client.company_name,
      })),
      onFilter: (value, record) => record.client_company.indexOf(value) === 0,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) =>
        moment(a.date, "DD/MM/YYYY").unix() -
        moment(b.date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Deliver Date",
      dataIndex: "deliver_date",
      key: "deliver_date",
      sorter: (a, b) =>
        moment(a.deliver_date, "DD/MM/YYYY").unix() -
        moment(b.deliver_date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "current_stage",
      key: "current_stage",
      filters: STAGES_LIST.map((stage) => ({
        text: getFormalName(stage),
        value: stage,
      })),
      onFilter: (value, record) => record.current_stage.indexOf(value) === 0,
      render: (text, record) => {
        return getFormalName(text);
      },
    },
    {
      title: "Designer",
      dataIndex: "designer_update_date",
    },
    {
      title: "Cutting",
      dataIndex: "cutting_update_date",
    },
    {
      title: "Secondary Process",
      dataIndex: "secendory_update_date",
    },
    {
      title: "Embroidary",
      dataIndex: "emboridary_update_date",
    },
    {
      title: "Production",
      dataIndex: "production_update_date",
    },
    {
      title: "Account",
      dataIndex: "emboridary_update_date",
    },
  ];

  useEffect(() => {
    let _table_columns = columns;

    if (
      [
        ROLES.CUTTING,
        ROLES.PRODUCTION,
        ROLES.SECONDARY_PROCESS,
        ROLES.EMBROIDARY,
        ROLES.ACCOUNT,
      ].includes(role)
    ) {
      if (role === ROLES.ACCOUNT) {
        setTableColumns(columns.filter((column) => column.title !== "Update"));
      } else {
        setTableColumns(
          columns
            .filter((column) => column.title !== "Update")
            .filter((column) => column.title !== "Client Company")
        );
      }
    } else {
      setTableColumns(columns);
    }
  }, [role]);

  const { date, rate } = cards;

  return (
    <div>
      <CardStyle>
        <div className="title">Report</div>
        <div style={{width:"100%"}}>
        <ShowCount  cards={cards} />

        </div>
        <Style>
          <div className="top">
            <Form layout="inline" onFinish={handleFinish}>
              <Form.Item label="From Date" name="from">
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>
              <Form.Item label="To Date" name="to">
                <DatePicker format={"DD/MM/YYYY"} />
              </Form.Item>

              {/* submit button */}
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" htmlType="submit" size="middle">
                  Search
                </Button>
              </Form.Item>
              <Button>
             
                
         

                <CSVLink
                  className="export_to_excel"
                  data={cards.map((card) => {
                    return {
                      ...card,
                      client: clients.find(
                        (client) => client.id === card.client
                      ).company_name,
                      cash:clients.find(
                        (client) => client.id === card.client
                      ).cash,
                      GST_NO:clients.find(
                        (client) => client.id === card.client
                      ).GST_NO,
                    };
                  })}
                  headers={headers}
                  filename={"versatileapparels.csv"}>
                  Export To Excel
                </CSVLink>
              </Button>
            </Form>
          </div>

          {clients && !loading ? (
            <>
              <ShowUpdateCount cards={cards} />
              <Table
                style={{ width: "100%" }}
                dataSource={dataSource}
                columns={tableColumns}
                rowClassName={"table-row"}
                clients={clients}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigate(`/detail/${record.id}`);
                    },
                  };
                }}
              />
            </> //
          ) : (
            <Skeleton times={3} />
          )}
        </Style>
      </CardStyle>
    </div>
  );
}

const Style = styled.div`
  width: 100%;
  .skeleton-with-100 {
    width: 100%;
  }
  .top {
    display: flex;
    padding: 2rem 0;
  }
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }
`;

export default JobCardBetweenDates;
