import { Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { Sort_jobCard, Sort_jobCard_completed } from 'features/auth/userSlice'
import React from 'react'
import { useDispatch } from 'react-redux'

const AscDesc = ({sort}) => {
    const dispatch=useDispatch()
    const handleSelect=(data)=>{
        if(sort==="completed"){
            dispatch(Sort_jobCard_completed(data))
        }else{
            dispatch(Sort_jobCard(data))
        }

    }
  return (
      <Select onSelect={handleSelect} style={{width:"300px",margin:"0px 10px"}} placeholder="Select Sort" defaultValue={-1}>
        <Option value={-1}>Default</Option>
        <Option value={1}>Ascending</Option>
      </Select>
  )
}

export default AscDesc
