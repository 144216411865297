import { Button } from "antd";
import CapMaterialTable from "components/inventory/CapMaterialTable";
import CapMaterialsTable from "components/inventory/CapMaterialsTable";
import CapTable from "components/inventory/CapTable";
import DottedStock from "components/inventory/DottedStock";
import FlexiStock from "components/inventory/FlexiStock";
import GrandleStock from "components/inventory/GrandleStock";
import MeshStock from "components/inventory/MeshStock";
import Popcorn from "components/inventory/Popcorn";
import TwillCotton from "components/inventory/TwillCotton";
import { inventoryData } from "features/jobinv/JobCardSlice";
import { useFetchCapsQuery, useFetchInventoryQuery } from "features/store";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Inventory = () => {
  // const { data, isLoading } = useFetchInventoryQuery();
const type=[
  "Flexi stock",
  "Twill Cotton",
  "Mesh [net]",
  "Doted",
  "Grandle",
  "POPCORN",
  "Dobby (Woven)"


]
  // const {inventory, loading} = useSelector((state)=> state.JobCard)
  // const dispatch=useDispatch()
  //
  // useEffect(() => {
  //     dispatch(inventoryData());
  //   }, []);
  return (
    <Style>
      <div>
        <div className="inventory-tables">
          {/* ALL TYPES ARE FETCHED */}
          {type.map((item)=>
          <FlexiStock item={item}/>)}
          {/* <TwillCotton /> */}
          {/* <DottedStock /> */}
          {/* <GrandleStock /> */}
          {/* <MeshStock /> */}
          {/* <Popcorn/> */}
          </div>
          <div className="cap-material">
          {/* <CapMaterialsTable /> */}
          </div>
      </div>
    </Style>
  );
};

export default Inventory;

const Style = styled.div`
.cap-material{
  margin: 2rem 5rem;
}
`;
