import React from "react";
import CapEditableTable from "./CapEditableTable";
import EditableTable from "./EditableTable";
import { useFetchInventoryQuery } from "features/store";
import { TableTitleStyles } from "./Inventory.style";

const CapMaterialsTable = () => {
  const { data: dataSource, isLoading: loading } = useFetchInventoryQuery({
    type: "CAP MATERIAL",
  });
  const new_data=dataSource?.filter((item)=>item?.color_cloth.cap_material!=="BUTTON"&&item?.color_cloth.cap_material!=="SWEATE BAND"&&item?.color_cloth.cap_material!=="PIPING"&&item?.color_cloth.cap_material!=="BUCKLE BELT"&&item?.color_cloth.cap_material!=="BACK CLOSER")
  const uniqueColors = [...new Set(new_data?.map(item => item?.color_cloth?.color))].filter((it)=>it!==null);
  const codeArry = [...new Set(new_data?.map(item => item?.color_cloth?.color_code))];
  const matType = [...new Set(new_data?.map(item => item?.color_cloth?.cap_material))].filter((it)=>it!==null);
  // const mat_sub_type = [...new Set(dataSource?.map(item => item?.color_cloth?.type).filter((it)=>it.type!==null))];
  const mat_sub_type = [...new Set(new_data?.map(item => item?.color_cloth?.type))].filter((it)=>it!==null);
console.log(dataSource?.filter((item)=>item?.color_cloth.cap_material!=="BUTTON"))
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      // render: (text, record, index) => index + 1,
      // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    // {
    //   title: "Color Code",
    //   dataIndex: ["color_cloth", "color_code"],
    //   filters: codeArry.map(client => ({
    //     text: client,
    //     value: client,
    //   })),
    //   onFilter: (value, record) => record.color_cloth.color_code === value.toUpperCase(),
    //   filterSearch: true,
    // },
    {
      title: "Material Type",
      dataIndex: ["color_cloth", "cap_material"],
      filters: matType.map(client => ({
        text: client,
        value: client,
      })),
      onFilter: (value, record) => record.color_cloth.cap_material === value.toUpperCase(),
      filterSearch: true,
    },
    {
      title: "type",
      dataIndex: ["color_cloth", "type"],
      filters: mat_sub_type.map(client => ({
        text: client,
        value: client,
      })),
      onFilter: (value, record) => record.color_cloth.type === value.toUpperCase(),
      filterSearch: true,
    },
    {
      title: "Color",
      dataIndex: ["color_cloth", "color"],
      dataIndex: ["color_cloth", "color"],
      filters: uniqueColors.map(client => ({
        text: client,
        value: client,
      })),
      onFilter: (value, record) => record.color_cloth.color === value.toUpperCase(),
      filterSearch: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
  ];
  const material = "Edit Material"
  return (
    <div>
    <TableTitleStyles>Material</TableTitleStyles>
      <EditableTable loading={loading} material={material} columns={columns} dataSource={new_data} />
    </div>
  );
};

export default CapMaterialsTable;
