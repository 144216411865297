import { Area } from '@ant-design/plots';
import React from 'react';

const Mountain = ({data}) => {
    const config = {
        data,
        xField: 'Date',
        yField: 'scales',
        xAxis: {
          range: [0, 1],
          tickCount: 5,
        },
        areaStyle: () => {
          return {
            fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
          };
        },
      };
    return (
        <div>
          <Area {...config} />
        </div>
    );
}

export default Mountain;
