import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MovieDetailStyle,
  MovieDetailSecondPgStyle,
} from "../style/componentsStyle";
import {
  fetchClients,
  getJobCard,
  getSelectedcard,
  roleSelector,
  UpdateJobCard,
} from "features/jobinv/JobCardSlice";
import { ROLES, STAGES } from "components/common/Constants";
import { Button, Space } from "antd";
import {
  getFormalName,
  getNextStage,
  getPreviousStage,
} from "components/services";
import { useReactToPrint } from "react-to-print";
import ImageViewer from "components/ImageViewer";
import Loader from "components/common/Loader";
import {
  useFetchJobcardsQuery,
  useGetClientsQuery,
  useGetJobcardQuery,
  useUpdateJobcardMutation,
} from "features/store";

const MovieDetail = () => {
  const componentRef = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const handlePrint = useReactToPrint({
    copyStyles: true,
    content: () => componentRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => alert("Print success"),
  });
  const { data } = useGetJobcardQuery(id);
  const { cards } = useFetchJobcardsQuery();
  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();
  console.log("updateJobcard: ", updateJobcard);

  const { isLoading: isUpdating, isSuccess } = updateResponseInfo;
  console.log("isSuccess: ", isSuccess);
  console.log("data: ", data);
  // const clients = useSelector((state) => state.JobCard.clients);
  const { data: clients, isLoading: loading } = useGetClientsQuery();

  // console.log("client",clientss)

  // const data = useSelector(getSelectedcard);
  const role = useSelector(roleSelector);
  const navigate = useNavigate();

  const current_stage = data?.current_stage;

  console.log("job card: ", data);

  const [client, setClient] = React.useState();

  const showRate = () =>
    [ROLES.ADMIN, ROLES.DESIGN, ROLES.ACCOUNT].includes(role);

  const showCordinator = () => showRate();

  useEffect(() => {
    if (data && clients?.length > 0) {
      const client = clients.find((client) => client.id === data.client);
      if (
        role === ROLES.ADMIN ||
        role === ROLES.DESIGN ||
        role === ROLES.ACCOUNT ||
        role === ROLES.CUTTING ||
        role === ROLES.SECONDARY_PROCESS ||
        role === ROLES.EMBROIDARY ||
        role === ROLES.PRODUCTION
      ) {
        setClient(client);
      }
    }
  }, [clients, data]);

  useEffect(() => {
    if (role === ROLES.CUTTING && data?.current_stage !== STAGES.CUTTING)
      navigate("/");

    if (role === ROLES.PRODUCTION && data?.current_stage !== STAGES.PRODUCTION)
      navigate("/");

    if (role === ROLES.ACCOUNT && data?.current_stage !== STAGES.ACCOUNT)
      navigate("/");
    if (
      role === ROLES.SECONDARY_PROCESS &&
      data?.current_stage !== STAGES.SECONDARY_PROCESS
    )
      navigate("/");
  }, [role, cards]);

  if (isSuccess) {
    return navigate("/");
  }
  return data ? (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          ref={componentRef}
          style={{ width: "100%", height: window.innerHeight }}
        >
          <div style={{ paddingTop: "1rem" }}>
            <MovieDetailStyle>
              <div>
                <section className="main_jobcard_section">
                  <h1>Job Card</h1>
                  <div className="main-content-conatiner">
                    <p className="created">
                      Created By: <span>{data.Created_by}</span>
                    </p>
                    <div className="main_content">
                      <div className="first_section">
                        <ul>
                          <li>
                            <b>Date:</b> <span>{data.date}</span>
                          </li>
                          {client && (
                            <li>
                              <b>Party Name:</b>
                              <span>{client?.company_name}</span>
                            </li>
                          )}
                          {client && (
                            <li>
                              <b>GST NO.:</b> <span> {client?.GST_NO}</span>
                            </li>
                          )}

                          <li>
                            <b>Job Coordinator:</b>
                            <span>{data.job_coordinator}</span>
                          </li>
                          <li>
                            <b>Quantity: </b>
                            <span>{data.quantity} pieces</span>
                          </li>
                          <li>
                            <b>Size </b>: <span>{data.size}</span>
                          </li>
                          {data?.emb_cost?

                          <li>
                                <b>Emb Cost:</b> <span>{data?.emb_cost}</span>
                              </li>
                            :null}
                        </ul>
                      </div>

                      <div className="first_section">
                        <ul>
                          <li>
                            <b>Jobcard No.:</b> <span>{data.id}</span>
                          </li>
                          <li>
                            <b>Delivery Date:</b>
                            <span>{data.deliver_date}</span>
                          </li>
                          <li>
                            <b>Style:</b> <span>{data.style}</span>
                          </li>
                          
                          {client && (
                            <li>
                              <b>Mob No.:</b> <span>{client?.phone}</span>
                            </li>
                          )}
                          {showRate() && (
                            <>
                              <li>
                                <b>Rate:</b> <span>{data.rate}</span>
                              </li>
                              {data.billing_type ? (
                                <li>
                                  <b>Billing Type:</b> <span>{data.billing_type}</span>
                                </li>
                              ) : null}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h1 className="style_info">Style Information</h1>
                  <div className="styleinfo">
                    <div className="styleinfoone one">
                      <h3>Main Fabric and Color</h3>
                      <div className="style styleinfo-box">
                        <p>
                          Front: {data.main_fabric_Front_type},
                          {data.main_fabric_Front_color}
                        </p>
                        <p>
                          Middle: {data.main_fabric_Middle_type},
                          {data.main_fabric_Middle_color}
                        </p>
                        <p>
                          Back: {data.main_fabric_Back_type},
                          {data.main_fabric_Back_color}
                        </p>
                        <p>
                          Top: {data.main_fabric_Top_type},
                          {data.main_fabric_Top_color}
                        </p>
                        <p>
                          Under: {data.main_fabric_Under_type},
                          {data.main_fabric_Under_color}
                        </p>
                      </div>
                    </div>
                    <div className="styleinfoone ">
                      <h3>Visor Type</h3>
                      <div className="style styleinfo-box">
                        <p>{data.visior_type}</p>
                      </div>
                    </div>
                    <div className="styleinfoone ">
                      <h3>PIPING Type & Color</h3>
                      <div className="style styleinfo-box">
                        <p>{data.piping_type},</p>
                        <p>{data.piping_color}</p>
                      </div>
                    </div>
                  </div>

                  <div className="firstcontent">
                    <div className="thirdsection">
                      <ul>
                        <li>
                          <b>Fusing :</b> <span>{data.fusing}</span>
                        </li>
                        <li>
                          <b>Taper:</b> <span>{data.tapper}</span>
                        </li>

                        <li>
                          <b>Sweatband:</b>
                          <span>{data.sweate_band_type}</span>
                        </li>

                        {/* {data.nonon ? (
                          <li>
                            <b>NONON:</b>
                            <span>{data.nonon}</span>
                          </li>
                        ) : null}
                        {data.full_closure_elastic ? (
                          <li>
                            <b>FULL CLOSURE ELASTIC:</b>
                            <span>{data.full_closure_elastic}</span>
                          </li>
                        ) : null}
                        {data.elastic ? (
                          <li>
                            <b>ELASTIC:</b>
                            <span>{data.elastic}</span>
                          </li>
                        ) : null} */}
                      </ul>
                      <ul>
                        <li className="elylet_data">
                          <b>Eyelet :</b> <span></span>
                          <ul>
                            <li>Front {data.eyelet_front}</li>
                            <li>Back {data.eyelet_back}</li>
                            <li>Middle {data.eyelet_middle}</li>
                          </ul>
                        </li>
                        <li>
                          <b>Visor Stitch:</b>
                          <span>{data.visor_stitch}</span>
                        </li>
                        {data.button ? (
                          <li>
                            <b>Button:</b>
                            <span>{data.button}</span>
                          </li>
                        ) : null}
                      </ul>
                      <ul>
                        {" "}
                        {data.back_close_type ? (
                          <>
                            <li>
                              <b>BACK CLOSER TYPE:</b>
                              <span>{data.back_close_type}</span>
                            </li>
                            <li>
                              <b>BACK CLOSER COLOR:</b>
                              <span>{data.back_closer_color}</span>
                            </li>
                          </>
                        ) : null}
                        {data.snapack ? (
                          <li>
                            <b>SNAPACK :</b>
                            <span>{data.snapack}</span>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </section>
                <section>
                  <h1 className="style_info">Embroidary Placement</h1>
                  <div className="emb">
                    <div>
                      <div className="emblabe">
                        <b>Front:</b> <span>{data.embr_Front}</span>
                      </div>
                      <div className="emblabe">
                        <b>Back:</b> <span>{data.embr_Back}</span>
                      </div>
                      <div className="emblabe">
                        <b>Left Middle:</b>
                        <span>{data.embr_Left_Middle}</span>
                      </div>
                      <div className="emblabe">
                        <b>Right Middle:</b>
                        <span> {data.embr_Right_Middle}</span>
                      </div>
                    </div>
                    <div>
                      <div className="emblabe">
                        <b>Visor:</b>
                        <span>{data.embr_Visor}</span>
                      </div>
                      <div className="emblabe">
                        <b>Visor Square:</b>
                        <span>{data.embr_Visor_Square}</span>
                      </div>
                      <div className="emblabe">
                        <b>Velcro:</b>
                        <span>{data.embr_Velcro}</span>
                      </div>
                      <div className="emblabe">
                        <b>One Side:</b>
                        <span>{data.embr_One_Side_Emb}</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="secondcontent">
                    <div className="party special-instruction">
                      Special Instruction:
                    </div>

                    <div className="partycontent special-instruction-p">
                      {data.special_instructions}
                    </div>
                  </div>
                </section>
                <section>
                  <div className="firstcontent">
                    <div className="thirdsection">
                      <ul>
                        {data.interlining ? (
                          <li>
                            <b>INTERLINING:</b>
                            <span>{data.interlining}</span>
                          </li>
                        ) : null}
                        {data.sizing_strip ? (
                          <li>
                            <b>SIZING STRIP:</b>
                            <span>{data.sizing_strip}</span>
                          </li>
                        ) : null}
                        {data?.sweat_band_holding ? (
                          <li>
                            <b>Sweat Band Holding:</b>
                            <span>{data.sweat_band_holding}</span>
                          </li>
                        ) : null}

                        {/* <li>
                          <b>Overlock :</b> <span>{data?.carton}</span>
                        </li> 
                        <li>
                          <b>Packing :</b> <span>{data?.carton}</span>
                        </li> 
                        <li>
                          <b>Insert :</b> <span>{data?.carton}</span>
                        </li>
                        */}
                      </ul>
                      <ul>
                        <li>
                          <b>Carton :</b> <span>{data?.carton}</span>
                        </li>
                        {/* <li>
                          <b>Label:</b> <span>{data.nonon}</span>
                        </li>
                   
                             <li>
                          <b>Tag:</b>
                          <span>{data.nonon}</span>
                        </li>
                             <li>
                          <b>Barcode:</b>
                          <span>{data.nonon}</span>
                        </li>
                             <li>
                          <b>Polythene Sticker:</b>
                          <span>{data.nonon}</span>
                        </li>
                             <li>
                          <b>Service:</b>
                          <span>{data.nonon}</span>
                        </li>
                    */}

                        {/* {data.nonon ? (
                          <li>
                            <b>NONON:</b>
                            <span>{data.nonon}</span>
                          </li>
                        ) : null}
                        {data.full_closure_elastic ? (
                          <li>
                            <b>FULL CLOSURE ELASTIC:</b>
                            <span>{data.full_closure_elastic}</span>
                          </li>
                        ) : null}
                        {data.elastic ? (
                          <li>
                            <b>ELASTIC:</b>
                            <span>{data.elastic}</span>
                          </li>
                        ) : null} */}
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </MovieDetailStyle>
          </div>
          <div style={{ margin: "10rem 0" }}></div>
          <div>
            <MovieDetailSecondPgStyle>
              <div className="ctnter">
                <section className="whole">
                  <section className="first">
                    <div>
                      <div className="single">
                        <div className="fixed">Party Name</div>
                        <div className="content">{client?.company_name}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Order Dt.</div>
                        <div className="content">{data.date}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Dispatch Date</div>
                        <div className="content">{data.deliver_date}</div>
                      </div>

                      <div className="single">
                        <div className="fixed">Quantity</div>
                        <div className="content">{data.quantity} Pieces</div>
                      </div>
                    </div>
                    <div>
                      <div className="single">
                        <div className="fixed">Fusing</div>
                        <div className="content">{data.fusing}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">taper</div>
                        <div className="content">{data.tapper}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Button</div>
                        <div className="content">{data.button}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Style</div>
                        <div className="content">{data.style}</div>
                      </div>
                    </div>

                    <div>
                      <div className="single">
                        <div className="fixed">Job Card No.</div>
                        <div className="content">{data.id}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Eyelet</div>
                        <div className="content">{data.eyelet_front}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Sweatband</div>
                        <div className="content">{data.sweate_band_type}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Visor Stitch</div>
                        <div className="content">{data.visor_stitch}</div>
                      </div>
                      <div className="single">
                        <div className="fixed">Back Closer</div>
                        <div className="content">{data.back_close_type}</div>
                      </div>
                    </div>
                  </section>
                  <section className="second">
                    <div className="secondmain">
                      <div className="img">
                        <div className="image">
                          <img src={data.img1} alt="design" />
                        </div>
                      </div>
                    </div>
                  </section>
                </section>
              </div>
              <Space>
                <Button
                  type="primary"
                  style={{ margin: "1rem auto", display: "block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      updateJobcard({
                        id: data.id,
                        current_stage: getNextStage(data.current_stage),
                      })
                    );
                  }}
                  disabled={data.current_stage === STAGES.COMPLETED}
                >
                  {data.current_stage === STAGES.COMPLETED
                    ? "Completed"
                    : `${
                        data.current_stage === STAGES.ACCOUNT
                          ? "Mark as"
                          : "Forward to"
                      }  ${getFormalName(getNextStage(data.current_stage))}`}
                </Button>
                <Button
                  type="primary"
                  style={{ margin: "1rem auto", display: "block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateJobcard({
                      id: data.id,
                      current_stage: getPreviousStage(data.current_stage),
                    });
                  }}
                  disabled={
                    data.current_stage === STAGES.CREATION ||
                    data.current_stage === STAGES.COMPLETED
                  }
                >
                  {data.current_stage === STAGES.COMPLETED
                    ? "Completed"
                    : `Send back to ${getFormalName(
                        getPreviousStage(data.current_stage)
                      )}`}
                </Button>
              </Space>
            </MovieDetailSecondPgStyle>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            left: "50%",
            marginBottom: "1rem",
          }}
        >
          <button
            style={{
              textAlign: "center",
              background: "var(--color-primary)",
              color: "var(--light)",
              padding: ".5rem 1rem",
              border: "none",
              borderRadius: ".2rem",
              fontWeight: "500",
            }}
            onClick={handlePrint}
          >
            Print Out
          </button>
        </div>
      </div>
    </> //
  ) : (
    <Loader />
  );
};

export default MovieDetail;
