import styled from "styled-components";

export const TableTitleStyles = styled.section`
  font-size: 1.6rem;
  font-weight: 500;
  background-color: #00afe2;
  padding: 5px;
  color:white;
  margin: 2rem 0;
`;
