import JobCardBetweenDates from "components/jobcard/JobCardBetweenDates";
import React from "react";
import { ROLES, STAGES } from "components/common/Constants";
import { fetchClients, roleSelector } from "features/jobinv/JobCardSlice";
import { useSelector } from "react-redux";

const Reports = () => {
  const role = useSelector(roleSelector);

  const showReport = () =>
    [ROLES.ADMIN, ROLES.DESIGN, ROLES.ACCOUNT].includes(role);

  const showReportComp = () => showReport();

  return <div>{showReportComp() && <JobCardBetweenDates />}</div>;
};

export default Reports;
