import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Modal,
  Skeleton,
  Button,
  message,
  Select,
} from "antd";
import {
  useUpdateCapMutation,
  useUpdateInventoryMutation,
} from "features/store";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Option } from "antd/lib/mentions";

const EditableCell = ({
  editing,
  dataSourceIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  const [editingValue, setEditingValue] = useState("");

  useEffect(() => {
    if (editing) {
      setEditingValue(record[dataSourceIndex]);
    }
  }, [editing, record, dataSourceIndex]);

  const handleInputChange = (e) => {
    setEditingValue(e.target.value);
  };

  const handleInputNumberChange = (value) => {
    setEditingValue(value);
  };
  const [updateInventory] = useUpdateInventoryMutation();
  const save = async () => {
    try {
      const values = {
        ...record,
        [dataSourceIndex]: editingValue,
      };
      updateInventory(values);
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editing) {
    childNode =
      inputType === "number" ? (
        // <Form.Item style={{ margin: 0 }} name={dataSourceIndex}>
        //   {React.cloneElement(inputNode, {
        //     value: editingValue,
        //     onChange: handleInputNumberChange,
        //     editing,
        //   })}
        // </Form.Item>
        <Form.Item style={{ margin: 0 }}>{inputNode}</Form.Item>
      ) : (
        // <Form.Item style={{ margin: 0 }}>
        //   {React.cloneElement(inputNode, {
        //     value: editingValue,
        //     onChange: handleInputChange,
        //     editing,
        //   })}
        // </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          {React.cloneElement(inputNode, {
            value: editingValue,
            onChange:
              inputType === "number"
                ? handleInputNumberChange
                : handleInputChange,
          })}
        </Form.Item>
      );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditableTable = ({
  dataSource,
  columns,
  loading,
  capstitle,
  inventory,
  material,
  isCap,
}) => {
  const [new_datasource, setNew_datasource] = useState(dataSource);
  useEffect(() => {
    setNew_datasource(
      dataSource?.map((obj) => ({
        ...obj,
        quantity: Number(obj.quantity.toFixed(4)),
      }))
    );
  }, [dataSource]);
  const [rate, setRate] = useState(0);
  const [add, setadd] = useState("Add");

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState(false);

  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [label, setLabel] = useState("");

  const isEditing = (record) => record.key === editingKey;
  const [updateInventory, updateResponseInfo] = useUpdateInventoryMutation();

  const [updateCap, { isSuccess, error: caperror }] = useUpdateCapMutation();
  const {
    error: failedToUpdate,
    isSuccess: updateSuccess,
    isLoading: updating,
    isLoading: isUpdating,
  } = updateResponseInfo;
  if (isUpdating) {
    return <Skeleton />;
  }
  if (updateSuccess) message.success("Quantity Updated");
  if (failedToUpdate) message.error("update failed");

  if (isSuccess) message.success("cap updated");
  if (caperror) message.error("cap updated failed");

  const handleEdit = (record) => {
    setCurrentData(record);
    setVisible(true);
    console.log(record)
    form.setFieldsValue({...record,quantity:null,update_quantity:record.quantity});
  };
  const handleCancel = () => {
    setVisible(false);
    setCurrentData(null);
    form.resetFields();
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      console.log("values: ", values.quantity);
      console.log("royal", values);

      //       if(values.rate===null||values.rate===undefined){
      // message.error("Fill All Values")
      //       }else{
      console.log("royal", values);
      if (values.update_quantity > 0) {
      }
      values.quantity = parseFloat(values.quantity);
      const newData = { ...currentData, id: currentData.id, ...values };
      console.log("newData: ", newData);
      if (isCap) {
        await updateCap(newData);
      } else {
        console.log("newData");
        // console.log(currentData)
        newData.rate = parseInt(newData.rate);
        await updateInventory(newData);
      }
      // await axios.put(`/api/data/${currentData.id}`, newData);
      setVisible(false);
      setCurrentData(null);
      form.resetFields();
      // }else{
      //   message.error("Put Valid Quantity")
      // }
    } catch (error) {}
  };

  const cancel = () => {
    setEditingKey("");
  };
  if (loading) {
    return <Skeleton />;
  }

  const columnsWithEditAndDelete = [
    ...columns,

    {
      title: "Action",
      dataSourceIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);

        return !editable ? (
          <Button type="primary" onClick={() => handleEdit(record)}>
            Add
          </Button>
        ) : (
          <p>null</p>
        );
      },
    },
  ];
  const mergedColumns = columnsWithEditAndDelete.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataSourceIndex: col.dataSourceIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const validateInput = (_, value) => {
    if (value && value < 0) {
      return Promise.reject(new Error("Please enter a non-negative value."));
    }
    return Promise.resolve();
  };
  return (
    <>
      <Style>
        <Table
          // bordered
          dataSource={new_datasource}
          columns={mergedColumns}
          rowClassName="editable-row"
          className="my-table"
          pagination={{
            onChange: cancel,
          }}
        />
      </Style>
      <Modal
        open={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        title={inventory || capstitle || material}
      >
        <Form form={form}>
          <Form.Item>
            <Select defaultValue={add} onChange={(e) => setadd(e)}>
              <Option value="Add">Add Quantity</Option>
              <Option value="Update">Update Quantity</Option>
            </Select>
          </Form.Item>
          {add === "Add" ? (
            <>
              <Form.Item
                name="quantity"
                label={"Add Quantity"}
                rules={[{ required: true }, { validator: validateInput }]}
              >
                <Input required />
              </Form.Item>
              <Form.Item
                name="rate"
                label={"Rate"}
                rules={[{ required: true }, { validator: validateInput }]}
              >
                <Input
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  required
                />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name="update_quantity"
              label={"Update Quantity"}
              rules={[{ required: true }, { validator: validateInput }]}
            >
              <Input required />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </> //
  );
};

export default EditableTable;

const Style = styled.div`
  .my-table {
    font-size: 16px;
  }

  .my-table .ant-table-header {
    background-color: var(--color-table-head);
  }

  .my-table .ant-table-thead > tr > th {
    background-color: var(--color-table-head);
    border: 0.1px solid #d9d9d9;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .my-table .ant-table-tbody > tr > td {
    border: 0.2px solid #ebebeb;
  }
`;
