import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select, Space, Button, Form } from "antd";
import React, { useState,useEffect, useRef } from "react";
const { Option } = Select;
let index = 0;

// const SelectType = ({ options, name,value,label }) => {
const SelectType = (props) => {
  // console.log("options", options);
  const [items, setItems] = useState(props.options);
  const [_name, setName] = useState("");
  const inputRef = useRef(null);
  const selectRef = useRef(null);
// console.log(props.options)
    const handleChange =(value)=>{
      
      props.value(value);
   
   }

 useEffect(() => {
setItems(props.options)
 }, [props.options]);

  //  console.log(props.options)
  //  setItems(props.options)
  //  console.log(items)
     
    
  const onNameChange = (e) => {
    setName(e.target.value);
    // console.log(number);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, _name || `New item ${index++}`]);
    setName("");
    console.log(_name)
  };
  // setInterval(() => {
  //  console.log(number);
  // }, 5000);

  return (
    <Form.Item name={props.name} label={props.label}
    rules={[
      {
        required: props.required,
        message: "Required Field!",
      },
    ]} >
      <Select
      // defaultValue={number}
      onSelect={handleChange}
      // value={"number"}
      // value={"SDF"}
      // onSelect={"sdf?"}
        // aria-selected={"sd"}
        placeholder={`Select ${props.label}`}
        ref={selectRef}
       

        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input
                placeholder={`Please enter manual ${props.label}`}
                ref={inputRef}
                value={_name}
                onChange={onNameChange}
              />
              <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                Add {props.label}
              </Button>
            </Space>
          </>
        )}>
        {items&&items.map((item) => {
        return(

          <Option value={item} >{item}</Option>
        ) 
})}
      </Select>
    </Form.Item>
  );
};

export default SelectType;














