import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { LoginStyle, Button } from "./Page.style";
import { useDispatch, useSelector } from "react-redux";
import { getUser, userLogin } from "features/auth/userSlice";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import Header from "components/header/Header";
import useAuth from "components/common/hooks/useAuth";
import Footer from "components/footer/Footer";
import { fetchClients } from "features/jobinv/JobCardSlice";
import { BeatLoader } from "react-spinners";
const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const { loading, error, user, userToken } = useSelector(
    (state) => state.user
  );


  useEffect(() => {
    if (!error) return;
    if (error?.detail) setLoginError(error.detail);
    else setLoginError("Login Failed");
  }, [error]);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const { username, password } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setLoginError(false);
  };

  if (user && userToken) {
    return <Navigate to="/" />;
  }
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(userLogin({ username, password }));
  };

  return (
    <>
      <LoginStyle>
        <div className="login-section">
          <div className="login-container">
            <div>
              <h1 style={{ fontWeight: "800", color: "rgba(0,0,0,0.7)" }}>
                Login to your account
              </h1>

              {loginError && <div className="error">{loginError}</div>}

              <form className="form" onSubmit={onSubmit}>
                <div className="input">
                  <input
                    id="username"
                    label="username"
                    placeholder="username"
                    name="username"
                    value={username}
                    onChange={onChange}
                  />
                </div>
                <div className="input">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    required
                    value={password}
                    onChange={onChange}
                  />
                </div>

                {loading ? (
              

               <div className="form-button">
               <Button
                 type="text"
                 fullWidth
                 variant="contained"
                 color="primary">
                 <BeatLoader  size={9} color="#ffffff" />
               </Button>
             </div>
                ) : (
                  <div className="form-button">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary">
                      Login
                    </Button>
                  </div>
                )}
              </form>
            </div>
            <div className="forgot_password">
              <Link to="/forgot-password" className="">
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </LoginStyle>
      <Footer />
    </> //
  );
};

export default Login;
