import React, { Component, useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { stageArray, stageArray2 } from "./State";

export default function ShowCount({cards}) {
  // const [items, setItems] = useState(props.cards);

  // const filetrStage = (stage) => {
  //   const updatedItems = props.cards?.filter((curElem) => {
  //     return curElem.current_stage === stage;
  //   });
  //   setItems(updatedItems);
  // };

  // const countStage = (stage) => {
  //   const countStages = props.cards?.filter(
  //     (card) => card.current_stage === stage
  //   );
  //   return countStages.length;
  // };

  // const countStageQuantity = (stage) => {
  //   let quantity = 0;
  //   const stageCards = props.cards?.filter(
  //     (card) => card.current_stage === stage
  //   );
  //   stageCards.map((card) => {
  //     quantity += parseInt(card.quantity || 0);
  //   });
  //   return quantity;
  // };

  // const filteredItems = props.cards?.filter((item) => {
  //   return item.current_stage !== "completed";
  // });

  // const countQuant = filteredItems?.reduce((totalQuantity, filteredItem) => {
  //   return filteredItem.quantity + totalQuantity;
  // }, 0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalCards, setTotalCards] = useState(0);
useEffect(() => {
  if(cards){

    const filteredData = cards.filter(item => item.current_stage !== "completed");
    
    setTotalQuantity(filteredData.reduce((total, item) => total + item.quantity, 0))
    setTotalCards(filteredData.reduce((total, item) => total + item.no_of_card, 0))
  }
  
}, [cards]);
  return (
    <>
      <Style>
        <div>
          <div className="stages_card_show">
            {stageArray2.map((stage)=>
            
            <div className="content">
              {stage}
              <span>Cards:{cards.filter((item)=>item.current_stage===stage)[0]?.no_of_card||0}</span>
              <span>Quantity: {cards.filter((item)=>item.current_stage===stage)[0]?.quantity||0}</span>
            </div>
              )}
            {/* 
            <div className="content">
              cutting
              <span>Cards:{countStage("cutting")}</span>
              <span>Quantity:{countStageQuantity("cutting")}</span>
            </div>
            <div className="content">
              Secondary Process
              <span>Cards: {countStage("secendory_process")}</span>
              <span>Quantity: {countStageQuantity("secendory_process")}</span>
            </div>
            <div className="content">
              Embroidary
              <span>Cards: {countStage("embroidary")}</span>
              <span>Quantity: {countStageQuantity("embroidary")}</span>
            </div>
            <div className="content">
              Production <span>Cards: {countStage("production")}</span>
              <span>Quantity: {countStageQuantity("production")}</span>
            </div>

            <div className="content">
              Account
              <span>Cards: {countStage("account")}</span>
              <span>Quantity: {countStageQuantity("account")}</span>
            </div>
            <div className="content">
              Completed
              <span>Cards: {countStage("completed")}</span>
              <span>Quantity: {countStageQuantity("completed")}</span>
            </div>
            <div className="content">
              Total Quantity <span>{countQuant}</span>
            </div>
          */}
          <div className="content">
           Total
            <span>Cards:{totalCards}</span>
              <span>Quantity: {totalQuantity}</span>
          </div>
          </div>
        </div>
      </Style>
    </> //
  );
}

const Style = styled.div`
  text-transform: uppercase;
  margin: 1rem 2rem;

  .stages_card_show {
    display: flex;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: streach;
    justify-content: space-between;
    // grid-gap: 1em;
  }
  .content {
    background: #ffffff;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 1.2rem;
    padding: 17px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 450px) {
    .stages_card_show {
      /* display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      justify-content: center;
      grid-gap: 0.5em; */
    }
    .content {
      background: #f6f6f6;
      /* overflow: hidden; */
      /* border-radius: 10px;
      box-shadow: 0 0 6px 4px #d0d0d0;
      text-align: center;
      font-size: 1.2rem;
      padding: 1rem 0;
      width: 200px; */
      span {
        /* font-weight: bold;
        display: flex;
        justify-content: center; */
      }
    }
  }
`;
