import React from "react";

const Missing = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        flexDirection: "column",
        top: '10%',
        left: '50%',
        transform: "translateY(-50%)",
        transform: "translateX(-50%)",
        height: "auto",
        clear: "both",
        overflow: "hidden",
        Zindex: "1",
        position: "relative",
      
      }}>
      
      <img style={{height: "auto", width: "60%"}} src={`${process.env.PUBLIC_URL}/images/notfound.png`} alt="" />
    </div>
  );
};

export default Missing;
