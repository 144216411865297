import React from "react";
import CapEditableTable from "./CapEditableTable";
import EditableTable from "./EditableTable";
import { useFetchInventoryQuery } from "features/store";
import { TableTitleStyles } from "./Inventory.style";
import CapMaterialsTable from "./CapMaterialsTable";

const CapMaterialTable = ({dataSource, loading}) => {
  const otherMaterial = [...new Set(dataSource?.map(item => item?.color_cloth?.other_material_name))];

  // const material = "Edit Material"
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      // render: (text, record, index) => index + 1,
      // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },

    {
      title: "Material Name",
      dataIndex: ["color_cloth", "other_material_name"],
      filters: otherMaterial.map(client => ({
        text: client,
        value: client,
      })),
      onFilter: (value, record) => record.color_cloth.other_material_name === value.toUpperCase(),
      filterSearch: true,
    },
    {
      title: "Plastic Bag Type",
      dataIndex: ["color_cloth", "plastic_bag_type"]
    },
    {
      title: "Carton Type",
      dataIndex: ["color_cloth", "carton_type"]
    },
    {
      title: "Card Board Type",
      dataIndex: ["color_cloth", "Card board type"],
   
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
  ];
  return (
    <div>
    {/* <TableTitleStyles>Material</TableTitleStyles> */}
      {/* <EditableTable loading={loading} material={material} columns={columns} dataSource={dataSource} /> 
      */}
      <CapMaterialsTable/>
      <div>
      <TableTitleStyles>Other Material</TableTitleStyles>
        <EditableTable loading={loading} material={dataSource} columns={columns} dataSource={dataSource} />
      </div>
    </div>
  );
};

export default CapMaterialTable;
