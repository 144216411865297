import "./App.css";
import "antd/dist/antd.css";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "components/footer/Footer";
import Login from "pages/Login";
import Header from "components/header/Header";
import Missing from "pages/Missing";

import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "features/auth/userSlice";
import Home from "components/Home/Home";
import MovieDetail from "components/MovieDetail/MovieDetail";
import Auth from "components/Auth";
import JobCardForm from "components/jobcard/JobCardForm";
import CardsTable from "components/MovieListing/CardsTable";
import UpdateJobcard from "components/jobcard/UpdateJobcard";
import moment, { parseTwoDigitYear } from "moment";
import "./components/inventory/mobile-inventory.css";
import {
  GetAllClients,
  getJobCards,
  roleSelector,
} from "features/jobinv/JobCardSlice";
import { ROLES } from "components/common/Constants";
import JobCardBetweenDates from "components/jobcard/JobCardBetweenDates";
import Reports from "pages/Reports";
import Inventory from "pages/Inventory";
import Slidebar from "components/header/Slidebar";
import Dashboard from "pages/Dashboard";
import CapTable from "components/inventory/CapTable";
import MaterialsStock from "pages/MaterialsStock";
import CreatedCap from "components/inventory/CreatedCap";
import InvenReport from "components/inventory/InvenReport";

export const AppContext = createContext();

function App() {
  const role = useSelector(roleSelector);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // const { JobCards: cards, selectedCard } = useSelector(
  //   (state) => state.JobCard
  // );

  const [currentTablePage, setCurrentTablePage] = useState();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  // if an job card is updated, fetch all updated card
  // useEffect(() => {
  //   dispatch(getJobCards());
  // }, [selectedCard]);

  // useEffect(() => {
  //   // if (cards?.length === 0) dispatch(getJobCards());
  //   // roles which can see clients
  //   if (user.isAuthenticated) {
  //     dispatch(GetAllClients());
  //   }
  // }, [user]);

  // console.log("date sssss --- ", moment("10/01/2000", "DD/MM/YYYY").unix());
  // console.log("date sssss --- ", moment("11/01/2000", "DD/MM/YYYY").unix());
  return (
    <AppContext.Provider value={{ currentTablePage, setCurrentTablePage }}>
      <div className="App">
        <>
          <BrowserRouter>
            <Slidebar />
            <div style={{ paddingTop: "1px", minHeight: "calc(100vh - 80px)" }}>
              <Routes>
                {/*<Route path="/" exact element={<Home />}></Route>
        <Route path="/products" exact element={<Products />}></Route>
        <Route path="/products/:id" element={<Product />}></Route>
  <Route path="*" element={<PageNotFound />} /> */}
                <Route path="/login" element={<Login />} />
                <Route path="/inventory" element={
                
                <Auth>

                  <Inventory />
                </Auth>
                } />
                <Route
                  path="/jobcard"
                  exact
                  element={
                    <Auth>
                      <Home />
                    </Auth>
                  }
                />
                <Route
                  path="/"
                  exact
                  element={
                    <Auth>
                      <Dashboard />
                    </Auth>
                  }
                />
                <Route
                  path="/reports"
                  exact
                  element={
                    <Auth>
                      <Reports />
                    </Auth>
                  }
                />
                <Route
                  path="/create"
                  exact
                  element={
                    <Auth>
                      <JobCardForm />
                    </Auth>
                  }
                />
                <Route path="/detail/:id" element={<MovieDetail />} />
                <Route
                  exact
                  path="jobcard/update/:id"
                  element={<UpdateJobcard />}
                />
                <Route
                  path="/cardtable"
                  element={
                    <Auth>
                      <CardsTable />
                    </Auth>
                  }
                />
                <Route
                  path="caps"
                  exact
                  element={
                    <Auth>
                      <CapTable />
                    </Auth>
                  }
                />
                <Route
                  path="material"
                  exact
                  element={
                    <Auth>
                      <MaterialsStock />
                    </Auth>
                  }
                />
                <Route
                  path="/createdcap"
                  exact
                  element={
                    <Auth>
                      <CreatedCap />
                    </Auth>
                  }
                />
                <Route
                  path="/invenreport"
                  exact
                  element={
                    <Auth>
                      <InvenReport />
                    </Auth>
                  }
                />

                {/*<Route path="/register" element={<RegisterPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
<Route path="/about" exact element={<About />} />*/}
                {/* private route example */}
                {/* private route example 
        <Route element={<PrivateRoutes />}>
          <Route path="/about" exact element={<About />} />
        </Route> */}
                <Route path="*" element={<Missing />} />
              </Routes>
            </div>
          </BrowserRouter>
        </>
      </div>
    </AppContext.Provider>
  );
}

export default App;
