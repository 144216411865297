import { AppContext } from "App";
import { Button, Space, Table } from "antd";
import { ROLES, STAGES, STAGES_LIST } from "components/common/Constants";
import Loader from "components/common/Loader";
import {
  getFormalName,
  getNextStage,
  getPreviousStage,
} from "components/services";
import {
  Sort_jobCard,
  jobCard_page,
  jobCard_pageComplete,
} from "features/auth/userSlice";
import { roleSelector, UpdateJobCard } from "features/jobinv/JobCardSlice";
import {
  useFetchJobcardsQuery,
  useGetClientsQuery,
  useUpdateJobcardMutation,
} from "features/store";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function CardsTable({
  cards,
  isCompleted,
  tableKey,
  clients,
  count,
  page,
  client_company,
  fetchJob,

  selectedRowKeys,
  setSelectedRowKeys,
  recordBig,
  setRecordBig,
}) {
  const role = useSelector(roleSelector);
  const { jobcard_page, jobcard_page_complete, sort_job } = useSelector(
    (state) => state.user
  );

  // const { data: cards, isLoading } = useFetchJobcardsQuery({
  //   refetchOnMountOrArgChange: true,
  // });

  // const { data: clients } = useGetClientsQuery();

  const { currentTablePage, setCurrentTablePage } = useContext(AppContext);

  // const client_names = [
  //   ...new Set(
  //     cards
  // ];
  //       .map((card) => card.client)
  //       .map((id) => clients?.find((client) => client.id === id).company_name)
  //   ),

  // const client_names = [
  //   ...new Set(
  //     cards?.map(
  //       (card) =>
  //         clients?.find((client) => client?.id === card?.client)?.company_name
  //     )
  //   ),
  // ];
  // console.log(client_names)
  const dataSource = cards?.map((card) => ({
    ...card,
    key: card.id,
    client: client_company?.filter((item) => item.id === card.client)[0]
      ?.company_name,
    current_stage:
      card.current_stage == null ? STAGES.CREATION : card.current_stage,
  }));

  const [tableColumns, setTableColumns] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateJobcard, updateResponseInfo] = useUpdateJobcardMutation();

  const { isLoading: isUpdating } = updateResponseInfo;


  const columns = [
    {
      title: "Job Card No.",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Client Company",
      dataIndex: "client",
      key: "client",
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // sorter: (a, b) =>
      //   moment(a.date, "DD/MM/YYYY").unix() -
      //   moment(b.date, "DD/MM/YYYY").unix(),
    },
    {
      title: "Deliver Date",
      dataIndex: "deliver_date",
      key: "deliver_date",
    },
    {
      title: "Last Updated By",
      dataIndex: "last_update",
      key: "last_update",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Current Stage",
      dataIndex: "current_stage",
      key: "current_stage",
    },
    {
      title: "Select",

      key: "select",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            // navigate(-);
            if (selectedRowKeys.includes(record?.id) === true) {
              const newData = selectedRowKeys.filter((id) => id !== record?.id);
              const newDataRecord = recordBig.filter(
                (item) => item?.id !== record?.id
              );
              setSelectedRowKeys(newData);
              setRecordBig(newDataRecord);
            } else {
              // Assuming selectedRowKeys and record.id exist
              const newData = [...selectedRowKeys, record?.id];
              const newDataRecord = [...recordBig, record];
              setSelectedRowKeys(newData);
              setRecordBig(newDataRecord);
            }
          }}
        >
          {selectedRowKeys.includes(record?.id) ? <>Deselect</> : <>Select</>}
        </Button>
      ),
    },
    {
      title: "Update",

      key: "update",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            // navigate(-);
            navigate(`update/${record.id}`);
          }}
          disabled={record.current_stage === STAGES.COMPLETED}
        >
          Update
        </Button>
      ),
    },
    {
      title: "Review",

      key: "review",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            updateJobcard({
              id: record.id,
              current_stage: getPreviousStage(record.current_stage),
            });
          }}
          disabled={
            record.current_stage === STAGES.CREATION ||
            record.current_stage === STAGES.COMPLETED
          }
        >
          {record.current_stage === STAGES.COMPLETED
            ? "Completed"
            : `Send back to ${getFormalName(
                getPreviousStage(record.current_stage)
              )}`}
        </Button>
      ),
    },
    {
      title: "Forward",

      key: "forward",
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            updateJobcard({
              id: record.id,
              current_stage: getNextStage(record.current_stage),
            });
          }}
          disabled={record.current_stage === STAGES.COMPLETED}
        >
          {record.current_stage === STAGES.COMPLETED
            ? "Completed"
            : `${
                record.current_stage === STAGES.ACCOUNT
                  ? "Mark as"
                  : "Forward to"
              }  ${getFormalName(getNextStage(record.current_stage))}`}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (
      [
        ROLES.CUTTING,
        ROLES.PRODUCTION,
        ROLES.SECONDARY_PROCESS,
        ROLES.EMBROIDARY,
        ROLES.ACCOUNT,
      ].includes(role)
    ) {
      if (role === ROLES.ACCOUNT) {
        setTableColumns(
          columns.filter(
            (column) => column.title !== "Update" && column.title !== "Select"
          )
        );
      } else {
        setTableColumns(
          columns.filter(
            (column) => column.title !== "Update" && column.title !== "Select"
          )
          // .filter((column) => column.title !== "Client Company")
        );
      }
    } else {
      if (page === "jobcard_page_complete") {
        setTableColumns(
          columns.filter((column) => column.title !== "Select")
          // .filter((column) => column.title !== "Client Company")
        );
      } else {
        setTableColumns(columns);
      }
    }
  }, [role, selectedRowKeys, recordBig]);
  // useEffect(() => {

  //   setTableColumns(columns);
  // }, []);

  if (!cards) return <Loader />;
  return (
    <>
      {isUpdating && <Loader />}

      <Table
        loading={fetchJob}
        style={{ width: "100%" }}
        dataSource={dataSource}
        columns={tableColumns}
        rowClassName={"table-row"}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/detail/${record?.id}`);
            },
            className: `priority-${
              record?.priority
            } selected-table-${selectedRowKeys?.includes(record?.id)}`,
          };
        }}
        // pagination={{
        //   defaultCurrent: currentTablePage?.[tableKey],
        //   onChange: (page, pageSize) =>
        //     setCurrentTablePage((prev) => ({ ...prev, [tableKey]: page })),
        // }}
        pagination={{
          total: count,
          pageSize: 10,
          current:
            page === "jobcard_page_complete"
              ? jobcard_page_complete
              : jobcard_page,
          onChange: (data) => {
            if (page === "jobcard_page_complete") {
              dispatch(jobCard_pageComplete(data));
            } else {
              dispatch(jobCard_page(data));
            }
          },
        }}
      />
    </>
  );
}

export default CardsTable;
