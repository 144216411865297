import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "components/common/api";
import { newurl } from "components/common/api";

const allApi = createApi({
  reducerPath: "inventory",
  baseQuery: fetchBaseQuery({ baseUrl: `${url}` }),
  refetchOnMountOrArgChange: true,
  tagTypes: [
    "Card",
    "Topcard",
    "Client",
    "Location",
    "Dropdowns",
    "Inventory",
    "Caps",
    "CardCompleted",
    "CLIENT_COMPANY",
    "ClientJob",
    "Coordinator",
    "Delivery",
    "CLIENT_COMPANY_COMPLETED"
  ], //refresh when it innvalidates
  endpoints(build) {
    return {
      fetchJobcards: build.query({
        query: ({ page, current_stage ,stage_filter,client_filter,sort}) => {
          return {
            url: `/api/?page=${page}${client_filter}${stage_filter}&sort=${sort}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.results?.length
            ? [
                ...result?.results?.map(({ id }) => ({ type: "Card", id })),
                "Card",
              ]
            : ["Card"],
      }),
      fetchJobcardsCompleted: build.query({
        query: ({ page, current_stage,client_filter ,stage_filter,sort}) => {
          return {
            url: `/api/?page=${page}&current_stage=completed${client_filter}&sort=${sort}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.results?.length
            ? [
                ...result?.results?.map(({ id }) => ({ type: "CardCompleted", id })),
                "CardCompleted",
              ]
            : ["CardCompleted"],
      }),
      fetchTopCardCount: build.query({
        query: () => {
          return {
            url: `/api/card-count/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
        result.length
          ? [
              ...result.map(({ id }) => ({ type: "Topcard", id })),
              "Topcard",
            ]
          : ["Topcard"],
      }),
      
      fetchCoordinatorsJob: build.query({
        query: () => {
          return {
            url: `/api/co-ordinators-job/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
        result.length
          ? [
              ...result.map(({ id }) => ({ type: "Coordinator", id })),
              "Coordinator",
            ]
          : ["Coordinator"],
      }),
      fetchDeliveryDateJob: build.query({
        query: () => {
          return {
            url: `/api/dilevery-date-job/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
        result.length
          ? [
              ...result.map(({ id }) => ({ type: "Delivery", id })),
              "Delivery",
            ]
          : ["Delivery"],
      }),
      fetchClientJob: build.query({
        query: () => {
          return {
            url: `/api/client-job/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
        result.length
          ? [
              ...result.map(({ id }) => ({ type: "ClientJob", id })),
              "ClientJob",
            ]
          : ["ClientJob"],
      }),
      
      getJobcard: build.query({
        query: (id) => {
          return {
            url: `/api/detail/${id}`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              "content-Type": "application/json",
            },
          };
        },
        
      }),
      createJobcard: build.mutation({
        query: (createJobcardData) => {
          const { ...data } = createJobcardData;

          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          return {
            url: `/api/addjobcard`,
            method: "POST",
            body: formdata,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "CLIENT_COMPANY" },
          { type: "Card", id: arg.id },
          { type: "Topcard" },
        ],
      }),
      updateJobcard: build.mutation({
        query: (updateJobcardData) => {
          const { id, ...data } = updateJobcardData;
          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          return {
            url: `/api/update/${id}`,
            method: "PATCH",
            body: formdata,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        
        invalidatesTags: (result, error, arg) => [
          { type: "CLIENT_COMPANY" },
          { type: "Topcard" },
          { type: "CardCompleted" },
          { type: "Card", id: arg.id },
        ],
      }),
      getClients: build.query({
        query: () => {
          return {
            url: `/api/clients`,
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        providesTags: ["Client"],
      }),
      getDropdowns: build.query({
        query: () => {
          return {
            url: `/api/array`,
            method: "GET",
          };
        },
        providesTags: ["Dropdowns"],
      }),
      fetchInventory: build.query({
        query: (query) => {
          // const { type, cloth_type } = args;
          return {
            url: `/inventory/list/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
            params: { ...query },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [
                ...result.map(({ id }) => ({ type: "Inventory", id })),
                "Inventory",
              ]
            : ["Inventory"],
      }),
      updateInventory: build.mutation({
        query: (updatedItem) => {
          const { id, ...data } = updatedItem;
          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          const quantity = {
            quantity: data.quantity,
          };

          return {
            url: `/inventory/detail/${id}/`,
            method: "PUT",
            body: updatedItem,
            headers: {
              Accept: "application/json",
              // Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Inventory", id: arg.id },
        ],
      }),
      fetchCaps: build.query({
        query: ({ cap_name, page, cap_color, cloth_type, cap_material }) => {
          return {
            url: `/inventory/cap/?cap_type=${cap_name}&color=${cap_color}&cloth_type=${cloth_type}&cap_material=${cap_material}&page=${page}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.results?.length
            ? [
                ...result.results.map(({ id }) => ({ type: "Caps", id })),
                "Caps",
              ]
            : ["Caps"],
        // providesTags: (result = [], error, arg) => {
        //   const tags = result?.map(({ id }) => ({ type: "Caps", id })) || [];
        //   const resourceTag = { type: "Caps", id: "all" }; // Additional tag for the overall resource
        //   return [...tags, resourceTag];
        // }
      }),
      updateCap: build.mutation({
        query: (updatedItem) => {
          const { id, ...data } = updatedItem;
          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          return {
            url: `/inventory/cap/${id}`,
            method: "PUT",
            body: updatedItem,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Caps", id: arg.id },
          { type: "Inventory", id: arg.id },
        ],
        // invalidatesTags: (result, error, arg) => {
        //   const pageTag = [{ type: "Caps", id: arg.id },{ type: "Inventory", id: arg.id }]; // Invalidates the specific page
        //   const resourceTag = { type: "Caps", id: "all" }; // Invalidates the overall resource
        //   return [pageTag, resourceTag];
        // }
      }),
      updateManufactureCap: build.mutation({
        query: (updatedItem) => {
          const { id, ...data } = updatedItem;
          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          return {
            url: `/inventory/manufactured_caps/${id}/`,
            method: "PUT",
            body: updatedItem,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Caps", id: arg.id },
          { type: "Card", id: arg.id },
        ],
      }),
      createCap: build.mutation({
        query: (createJobcardData) => {
          const { ...data } = createJobcardData;

          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          console.log(data);
          console.log(formdata);
          return {
            url: `/inventory/manufactured_caps/`,
            method: "POST",
            body: data,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              // ...formdata.getHeaders(),
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Inventory", id: arg.id },
          { type: "Card", id: arg.id },
          { type: "Caps", id: arg.id },
        ],
      }),
      fetchColors: build.query({
        query: ({ skip }) => {
          if (skip === undefined || skip === null) {
            console.log("if", skip);
          } else {
            console.log("else", skip);
            return {
              url: `/inventory/colors/?cap_type=${skip}`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                Accept: "application/json",
              },
            };
          }
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result.map(({ id }) => ({ type: "Caps", id })), "Caps"]
            : ["Caps"],
      }),
      fetchManufacture_cap: build.query({
        query: ({ skip }) => {
          if (skip) {
            return {
              url: `/inventory/manufactured_caps/`,
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
                Accept: "application/json",
              },
            };
          }
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result.map(({ id }) => ({ type: "Caps", id })), "Caps"]
            : ["Caps"],
      }),
      getManufacture_cap: build.query({
        query: ({ id }) => {
          return {
            url: `/inventory/manufactured_caps/${id}/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result.map(({ id }) => ({ type: "Caps", id })), "Caps"]
            : ["Caps"],
      }),
      getCompanyNameFromJobcard: build.query({
        query: ({ id }) => {
          return {
            url: `/api/company-job/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result.map(({ id }) => ({ type: "CLIENT_COMPANY", id })), "CLIENT_COMPANY"]
            : ["CLIENT_COMPANY"],
      }),
      getCompanyNameFromJobcardCompleted: build.query({
        query: ({ id }) => {
          return {
            url: `/api/company-job-completed/`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              Accept: "application/json",
            },
          };
        },
        providesTags: (result = [], error, arg) =>
          result?.length
            ? [...result.map(({ id }) => ({ type: "CLIENT_COMPANY_COMPLETED", id })), "CLIENT_COMPANY_COMPLETED"]
            : ["CLIENT_COMPANY_COMPLETED"],
      }),
      updateManufacturer: build.mutation({
        query: (updatedItem) => {
          const { id, ...data } = updatedItem;
          var formdata = new FormData();
          Object.keys(data).map((form_key) =>
            formdata.append(form_key, data[form_key] || "")
          );
          console.log(updatedItem);
          return {
            url: `/inventory/manufactured_caps/${id}/`,
            method: "PUT",
            body: updatedItem,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: "Caps" },
          { type: "Inventory", id: arg.id },
          { type: "Card" },
        ],
      }),
    };
  },
});

export const {
  useFetchJobcardsQuery,
  useFetchJobcardsCompletedQuery,
  useFetchTopCardCountQuery,
  useGetJobcardQuery,
  useGetClientsQuery,
  useGetDropdownsQuery,
  useCreateJobcardMutation, //upper data in job card get posted into the database
  useUpdateJobcardMutation, //update upper data into the jobcard
  useFetchInventoryQuery,
  useUpdateInventoryMutation,
  useUpdateManufactureCapMutation,
  useFetchCapsQuery,
  useUpdateCapMutation,
  useCreateCapMutation,
  useFetchColorsQuery,
  useFetchManufacture_capQuery,
  useUpdateManufacturerMutation,
  useGetManufacture_capQuery,
  useGetCompanyNameFromJobcardQuery,

  useFetchClientJobQuery,
  useFetchCoordinatorsJobQuery,
  useFetchDeliveryDateJobQuery,
  useGetCompanyNameFromJobcardCompletedQuery,
} = allApi;

export { allApi };

// http://127.0.0.1:8000/inventory/manufactured_caps/67/ put
//WHile PUT API
// {
//   "id":3,
//   "cap_type": "BASE BALL CAP",
//   "manufacture_quantity": 10,
//   "manufactured_cap_data": [
//     {
//       "id":2,
//       "cap_id":2,
//       "cloth_id":6
//     }
//   ]
// }

// {
//   "id": 68,   =>>main id
//    "cap_type": "BASE BALL CAP",
//    "manufacture_quantity": 10,
//    "manufactured_cap_data": [
//      {
//         "id": 58, =>> front type id geting while get request by specifing id in the api
//        "cap_id":17,
//        "cloth_id":9
//      },
//       {
//       "id": 59, =>> back type id geting while get request by specifing id in the api
//        "cap_id":17,
//        "cloth_id":9
//      }
//    ]
//  }
