import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


const initialState ={

    loading :false,
    users:[],
    error:''
}

export const fetchColor = createAsyncThunk("user/fetches",(data)=>{
    if(data===undefined){

    }else{

        return axios
        // .get(`http://127.0.0.1:8000/inventory/colors/?cloth__cloth_type=${data}`)
        .get(`https://www.versatileapparels.com/inventory/colors/?cap_type=${data}`)
        .then((res)=>
        
        res.data.map(res =>(res)) 
        // res.data
        //    res.data.map(res =>(res.color))> 
        
        )
        
    }

    
})



const colorSlice = createSlice({
    name:"user",
    initialState,
    extraReducers:(builder)=>{
        builder.addCase(fetchColor.pending,(state)=>{
            state.loading=true
        })
        builder.addCase(fetchColor.fulfilled,(state,action)=>{
            state.loading=false
            state.users=action.payload
            state.error=''

        })
        builder.addCase(fetchColor.rejected,(state,action)=>{
            state.loading=false
            state.users=[]
            state.error=action.error.message
        })
    }
})

export default colorSlice.reducer;