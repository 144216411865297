import React, { useState } from "react";
import CapEditableTable from "./CapEditableTable";
import EditableTable from "./EditableTable";
import { useFetchCapsQuery } from "features/store";
import { Button, Form, Input, Select, Skeleton } from "antd";
import styled from "styled-components";
import { TableTitleStyles } from "./Inventory.style";
import { Option } from "antd/lib/mentions";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import PagenatedETable from "./PagenatedETable";

const CapTable = () => {
  const [cap_name, setCap_name] = useState("");
  const [cap_color, setCap_color] = useState("");
  const [cap_material, setCap_material] = useState("");
  const [cloth_type, setCloth_type] = useState("");
  const { cap_table_page } = useSelector((state) => state.user);
  const {
    data: caps,
    isLoading: loading,
    isFetching: fetch,
    error: error,
  } = useFetchCapsQuery({
    cap_name: cap_name,
    page: cap_table_page,
    cap_color: cap_color,
    cap_material: cap_material,
    cloth_type: cloth_type,
    
  });

  const [caps_data, setCaps_data] = useState();
  const [captype_array, setCaptype_array] = useState();
  const headers = [
    { label: "Job Card No.", key: "id" },
    { label: "Color", key: "color" },
  ];
  useEffect(() => {
    if (caps) {
      setCaps_data(caps?.results);
      fetch_cap_name();
    }
  }, [caps]);
  const [count, setCount] = useState();

  useEffect(() => {
    if (caps) {
      setCount(caps?.count);
    }
  }, [caps]);

  const isCap = true;
  const cap_type = [
    ...new Set(caps_data && caps_data.map((colorx) => colorx.cap_type)),
  ];

  const fetch_cap_name = () => {
    const cap_type = [
      ...new Set(caps_data && caps_data.map((colorx) => colorx.cap_type)),
    ];
    console.log(cap_type);
    setCaptype_array(cap_type);
  };
 

  if (loading) return <Skeleton />;

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      // render: (text, record, index) => index + 1,
      // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    {
      title: "Cap",
      dataIndex: "cap_type",
      // render: (text, record, index) => index + 1,
      // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },

    {
      title: "Cloth Type",
      dataIndex: "cloth_type",
      render: (text) => (
        <span style={{ textTransform: "uppercase" }}>{text}</span>
      ),
    },
    {
      title: "Cap Material",
      dataIndex: "cap_material",
      render: (text) => (
        <span style={{ textTransform: "uppercase" }}>{text}</span>
      ),
    },
    // {
    //   title: "Cloth Type",
    //   dataIndex: "cap_material",
    // },
    {
      title: "Color",
      dataIndex: "color",
      render: (text) => (
        <span style={{ textTransform: "uppercase" }}>{text}</span>
      ),
    },
    {
      title: "Cap Side",
      dataIndex: "cap_side_type",
      render: (text) => (
        <span style={{ textTransform: "uppercase" }}>{text}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
  ];

  const handleChange = (data) => {
    console.log(data);
    console.log(
      "data",
      caps.filter((item) => item.cap_type === data)
    );
    setCaps_data(caps.filter((item) => item.cap_type === data));
  };
  const capstitle = "Edit Cap";
  return (
    <div className="material-div">
      <div>
        {/* <Form.Item name={"Cap"} label={"Cap Name"}
   style={{width:"300px"}} 
    >
      <Select
      // defaultValue={number}
      onChange={handleChange}
      // value={"number"}
      // value={"SDF"}
      // onSelect={"sdf?"}
        // aria-selected={"sd"}
        placeholder={`Select Cap`}
     
      >
        
       
{cap_type?.map((item)=>
          <Option value={item} >{item}</Option>

)

}
{console.log("csav",caps_data?.map((card)=>card))}
       

      </Select>
    </Form.Item> */}
        <div style={{ display: "flex" }}>
          <Form.Item
            name={"cap_name"}
            label={"Cap Type"}
            style={{ width: "300px" }}
          >
            <Input
              onChange={(e) => {
                setTimeout(() => {
                  setCap_name(e.target.value);
                }, 1000);
              }}
            />
          </Form.Item>
          <Form.Item
            name={"cap_color"}
            label={"Cap Color"}
            style={{ width: "300px", marginLeft: "20px" }}
          >
            <Input
              onChange={(e) => {
                setTimeout(() => {
                  setCap_color(e.target.value);
                }, 1000);
              }}
            />
          </Form.Item>
          <Form.Item
            name={"cap_material"}
            label={"Cap Material"}
            style={{ width: "300px", marginLeft: "20px" }}
          >
            <Input
              onChange={(e) => {
                setTimeout(() => {
                  setCap_material(e.target.value);
                }, 1000);
              }}
            />
          </Form.Item>
          <Form.Item
            name={"cloth_type"}
            label={"Cloth Type"}
            style={{ width: "300px", marginLeft: "20px" }}
          >
            <Input
              onChange={(e) => {
                setTimeout(() => {
                  setCloth_type(e.target.value);
                }, 1000);
              }}
            />
          </Form.Item>
        </div>

        {caps_data ? (
          <Button>
            <CSVLink
              className="export_to_excel"
              data={caps_data?.map((card) => {
                return {
                  ...card,
                };
              })}
              // headers={headers}

              filename={"versatileapparels.csv"}
            >
              Export To Excel
            </CSVLink>
          </Button>
        ) : null}
        <TableTitleStyles>Caps Required Inventory</TableTitleStyles>
        {caps_data ? (
          <PagenatedETable
            isCap={isCap}
            capstitle={capstitle}
            columns={columns}
            dataSource={caps_data}
            count={count}
            currentPage={cap_table_page}
            loading={fetch}
            error={error}
          />
        ) : (
          <p>Loading</p>
        )}
      </div>
    </div>
  );
};

export default CapTable;

// const Style = styled.div`
// margin: 5rem;
// `;
