export const STAGES = {
  CREATION: "creation",
  CUTTING: "cutting",
  SECONDARY_PROCESS: "secendory_process",
  EMBROIDARY: "embroidary",
  PRODUCTION: "production",
  ACCOUNT: "account",
  COMPLETED: "completed",
};
export const ROLES = {
  ADMIN: "admin",
  DESIGN: "designer",
  CUTTING: "cutting",
  SECONDARY_PROCESS: "secendory_process",
  EMBROIDARY: "embroidary",
  PRODUCTION: "production",
  ACCOUNT: "accountent",
};

export const STAGES_LIST = Object.keys(STAGES).map((key) => STAGES[key]);

// edit -> admin, design
// view without client details -> cutting, production, account
