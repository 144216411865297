import { Button } from "antd";
import { fetchClients, roleSelector } from "features/jobinv/JobCardSlice";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardStyle } from "../style/componentsStyle";
import CardsTable from "components/MovieListing/CardsTable";
import { ROLES, STAGES } from "components/common/Constants";
import styled from "styled-components";
import Loader from "components/common/Loader";
import ShowCount from "./ShowCount";
import {
  useFetchJobcardsCompletedQuery,
  useFetchJobcardsQuery,
  useFetchTopCardCountQuery,
  useGetClientsQuery,
  useGetCompanyNameFromJobcardCompletedQuery,
  useGetCompanyNameFromJobcardQuery,
  useGetJobcardQuery,
} from "features/store";
import MultidropDown from "components/MovieListing/MultidropDown";
import AscDesc from "components/MovieListing/AscDesc";
import CrossButton from "atoms/CrossButton";
import {
  getManufatureDataSet,
  getSum,
  getUniqueColors,
  getUniqueColors2,
  sumData,
} from "components/State";
import { useReactToPrint } from "react-to-print";

const ItemMain = ({ lastData }) => {
  console.log("lastData", lastData);
  return <>{getSum(lastData)}</>;
};
const Cap = ({ material_types, st }) => {
  console.log("material_types 44", material_types);
  const uniqueKeys = Object.keys(material_types);

  return (
    <div style={st}>
      {uniqueKeys?.map((item) => (
        <>
          <h3 style={{ color: "black", textAlign: "center" }}>
            <span style={{ fontWeight: "900", letterSpacing: "2px" }}>
              {/* {item} {": "} */}
            </span>
            <ItemMain lastData={material_types[item]} />
          </h3>
        </>
      ))}
    </div>
  );
};

const CapList = ({ caps }) => {
  console.log("33", caps);
  const uniqueKeys = Object.keys(caps);

  return (
    <div style={{ display: "flex", color: "white" }}>
      {Object.keys(caps).map((side) => (
        <div
          style={{
            margin: "10px 0px",
            marginRight: "10px",
            padding: "10px 20px",
            background: "var(--color-primary--light)",
          }}
        >
          <h4 style={{ color: "black" }}>{side} </h4>

          <Cap material_types={caps[side]} />
        </div>
      ))}
    </div>
  );
};
function ClothMaterial({ clothMaterial }) {
  console.log("clothMaterial 22", clothMaterial);

  return (
    <div>
      {clothMaterial["CLOTH MATERIAL"] ? (
        <>
          {/* <h2>CLOTH MATERIAL</h2> */}
          <CapList caps={clothMaterial["CLOTH MATERIAL"]} />
        </>
      ) : null}
      {/* {clothMaterial["CAP MATERIAL"] ? (
        <>
          <h2>CAP MATERIAL</h2>
          <Cap st={{background:"var(--color-primary--light)",margin:"10px 0px",padding:"10px"}} material_types={clothMaterial["CAP MATERIAL"]} />
        </>
      ) : null} */}
    </div>
  );
}

function MaterialTypes({ material_types }) {
  console.log(
    "material_types?.material_types 11",
    material_types?.material_types
  );
  return (
    <div>
      {/* {material_types?.map(materialGroup => (
        <MaterialGroup key={materialGroup.type} materialGroup={materialGroup} />
      ))} */}
      <ClothMaterial clothMaterial={material_types?.material_types} />
    </div>
  );
}
const fabric = [
  { color: "main_fabric_Front_color", type: "Front" },
  { color: "main_fabric_Back_color", type: "Back" },
  { color: "main_fabric_Middle_color", type: "Middle" },
  { color: "main_fabric_Top_color", type: "Top" },
  { color: "main_fabric_Under_color", type: "Under" },
];
function ColorGroup({ color, recordBig }) {
  return (
    <div>
      <h1
        style={{
          background: "var(--color-primary)",
          color: "white",
          padding: "10px ",
        }}
      >
        {color}
      </h1>
      <div
        style={{
          margin: "10px 0px",
          marginRight: "10px",
          padding: "10px 20px",
          background: "var(--color-primary--light)",
          display:"flex",
          justifyContent:"space-between",
          
        }}
      >
        {fabric?.map((fabricType) => (
          <div>
            {sumData(
              recordBig
                ?.filter((item) => item[fabricType.color] === color)
                ?.map((it) => it?.quantity)
            ) === 0 ? null : (
              <div>
                <h4 style={{ color: "black", textAlign: "center" }}>
                  {fabricType.type}
                </h4>
                <h4 style={{ color: "black", textAlign: "center" }}>
                  {sumData(
                    recordBig
                      ?.filter((item) => item[fabricType.color] === color)
                      ?.map((it) => it?.quantity)
                  )}{" "}
                </h4>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* <div style={{padding:"10px"}}> */}
      {/* <div>
        <MaterialTypes material_types={material_types} />
      </div> */}
    </div>
  );
}

function ColorGroups({ colorGroups, recordBig }) {
  return (
    <div>
      {colorGroups?.map((color) => (
        <ColorGroup key={color} color={color} recordBig={recordBig} />
      ))}
    </div>
  );
}

const GenerateModel = ({
  data,
  performCancel,
  handlePrint,
  componentRef,
  recordBig,
}) => {
  return (
    <div className="model-con">
      <div className="model-box" style={{ width: "60vw", padding: "0px" }}>
        <CrossButton performCancel={performCancel} />
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            // left: "50%",
            top: "-36px",
            // marginBottom: "1rem",
          }}
        >
          <button
            style={{
              textAlign: "center",
              background: "var(--color-primary)",
              color: "var(--light)",
              padding: ".5rem 1rem",
              border: "none",
              borderRadius: ".2rem",
              fontWeight: "500",
              cursor: "pointer",
            }}
            onClick={handlePrint}
          >
            Print Out
          </button>
        </div>
        <div className="model-box2">
          <div className="dummy"></div>
          <div className="test" ref={componentRef}>
            <ColorGroups colorGroups={data} recordBig={recordBig} />
          </div>
        </div>
      </div>
    </div>
  );
};
const CardListing = () => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    copyStyles: true,
    content: () => componentRef.current,
    documentTitle: "emp-data",
    onAfterPrint: () => alert("Print success"),
  });
  const role = useSelector(roleSelector);
  const {
    jobcard_page,
    jobcard_page_complete,
    client_filter,
    stage_filter,
    client_filter_completed,
    sort_job_completed,
    sort_job,
  } = useSelector((state) => state.user);

  //const {
  //  JobCards: cards,
  //  loading,
  //  selectedCard,
  const {
    data: cards,
    isLoading: loading,
    isFetching: fetchJob,
  } = useFetchJobcardsQuery({
    refetchOnMountOrArgChange: true,
    page: jobcard_page,
    client_filter: client_filter,
    stage_filter: stage_filter,
    sort: sort_job,
  });
  const {
    data: cardsCompleted,
    isFetching: loadingCompleted,
  } = useFetchJobcardsCompletedQuery({
    refetchOnMountOrArgChange: true,
    page: jobcard_page_complete,
    client_filter: client_filter_completed,
    sort: sort_job_completed,
  });
  const {
    data: top_cards,
    isLoading: topcard_loading,
  } = useFetchTopCardCountQuery({ refetchOnMountOrArgChange: true });
  const {
    data: client_company,
    isLoading: clientCompany_loading,
  } = useGetCompanyNameFromJobcardQuery({ refetchOnMountOrArgChange: true });
  const {
    data: client_company_completed,
    isLoading: clientCompany_loading_completed,
  } = useGetCompanyNameFromJobcardCompletedQuery({
    refetchOnMountOrArgChange: true,
  });
  // const {data: clients}= useGetClientsQuery()

  //} = useSelector((state) => state.JobCard);

  const [tableCards, setTableCards] = React.useState([]);
  const navigate = useNavigate();
  // const clients = useSelector((state) => state.JobCard.clients);
  // const { data: selectedCard } = useGetJobcardQuery();
  // hjdf

  useEffect(() => {
    if (cards?.results?.length === 0) return;
    if (!role) return;
    // to show cards whose current_stage is same as role
    if (role === ROLES.ACCOUNT)
      setTableCards(
        cards?.results?.filter((card) => card.current_stage === STAGES.ACCOUNT)
      );
    else if (role === ROLES.CUTTING)
      setTableCards(
        cards?.results?.filter((card) => card.current_stage === STAGES.CUTTING)
      );
    else if (role === ROLES.PRODUCTION)
      setTableCards(
        cards?.results?.filter(
          (card) => card.current_stage === STAGES.PRODUCTION
        )
      );
    else if (role === ROLES.SECONDARY_PROCESS)
      setTableCards(
        cards?.results?.filter(
          (card) => card.current_stage === STAGES.SECONDARY_PROCESS
        )
      );
    else if (role === ROLES.EMBROIDARY)
      setTableCards(
        cards?.results?.filter(
          (card) => card.current_stage === STAGES.EMBROIDARY
        )
      );
    // for desiner and admin
    else setTableCards(cards?.results);
  }, [cards, role]);

  const underProcessCards = tableCards?.filter(
    (card) => card?.current_stage !== STAGES.COMPLETED
  );

  const completedCards = cardsCompleted?.results?.filter(
    (card) => card?.current_stage === STAGES.COMPLETED
  );
  const showCountcm = () =>
    [ROLES.ADMIN, ROLES.DESIGN, ROLES.ACCOUNT].includes(role);

  const showCountComp = () => showCountcm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [recordBig, setRecordBig] = useState([]);
  const [generateShow, setGenerateShow] = useState(false);
  const performCancel = () => {
    setGenerateShow(false);
  };
  // console.log(getManufatureDataSet(recordBig)?.filter((item)=>item.cap_material?.material_type==="CAP MATERIAL"))
  // console.log(getManufatureDataSet(recordBig)?.filter((item)=>item.cap_material?.material_type==="CLOTH MATERIAL"))
  console.log("recordBig", getUniqueColors2(recordBig));
  console.log("recordBig", recordBig);
  // console.log("getManufatureDataSet(recordBig)",getManufatureDataSet(recordBig));
  console.log(getUniqueColors(getManufatureDataSet(recordBig)));
  const dummt = [
    {
      id: 5665,
      manufacture_cap: {
        id: 3160,
        cap_type: "5 PART HIP HOP CAP",
        manufacture_quantity: 20,
        created_at: "12:22 PM",
        updated_at: "12:22 PM",
        manufacturedcapdata_set: [
          {
            id: 20671,
            cap: 18080,
            manufactured_cap: 3160,
            cap_material: {
              id: 193,
              color: "CT109  BLACK",
              cloth_type: "Twill Cotton",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "FRONT",
            cap_current_man_quantity: 0.025,
          },
          {
            id: 20672,
            cap: 18108,
            manufactured_cap: 3160,
            cap_material: {
              id: 193,
              color: "CT109  BLACK",
              cloth_type: "Twill Cotton",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "BACK",
            cap_current_man_quantity: 0.029,
          },
          {
            id: 20673,
            cap: 18136,
            manufactured_cap: 3160,
            cap_material: {
              id: 193,
              color: "CT109  BLACK",
              cloth_type: "Twill Cotton",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "MIDDLE",
            cap_current_man_quantity: 0.027,
          },
          {
            id: 20673,
            cap: 18136,
            manufactured_cap: 3160,
            cap_material: {
              id: 193,
              color: "CT109  BLACK",
              cloth_type: "Flexi Cotton",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "MIDDLE",
            cap_current_man_quantity: 0.027,
          },
          {
            id: 20673,
            cap: 18136,
            manufactured_cap: 3160,
            cap_material: {
              id: 193,
              color: "CT109  BLACK",
              cloth_type: "Flexi Cotton",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "MIDDLE",
            cap_current_man_quantity: 0.027,
          },
          {
            id: 20674,
            cap: 18004,
            manufactured_cap: 3160,
            cap_material: {
              id: 126,
              color: "FX9  BLACK",
              cloth_type: "Flexi stock",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "TOP",
            cap_current_man_quantity: 0.011,
          },
          {
            id: 20675,
            cap: 18044,
            manufactured_cap: 3160,
            cap_material: {
              id: 126,
              color: "FX9  BLACK",
              cloth_type: "Flexi stock",
              cap_material: null,
              material_type: "CLOTH MATERIAL",
            },
            cap_side_type: "UNDER",
            cap_current_man_quantity: 0.011,
          },
          {
            id: 20676,
            cap: 24472,
            manufactured_cap: 3160,
            cap_material: {
              id: 246,
              color: "BLACK",
              cloth_type: null,
              cap_material: "VISOR TYPE",
              material_type: "CAP MATERIAL",
            },
            cap_side_type: null,
            cap_current_man_quantity: 1,
          },
          {
            id: 20677,
            cap: 24745,
            manufactured_cap: 3160,
            cap_material: {
              id: 215,
              color: "IMP BLACK",
              cloth_type: null,
              cap_material: "FUSING",
              material_type: "CAP MATERIAL",
            },
            cap_side_type: null,
            cap_current_man_quantity: 0.025,
          },
          {
            id: 20678,
            cap: 24576,
            manufactured_cap: 3160,
            cap_material: {
              id: 453,
              color: "BLACK",
              cloth_type: null,
              cap_material: "TAPER",
              material_type: "CAP MATERIAL",
            },
            cap_side_type: null,
            cap_current_man_quantity: 1.143,
          },
        ],
      },
      date: "24/04/2024",
      job_coordinator: "ASHISH",
      quantity: 20,
      rate: "220",
      deliver_date: "03/05/2024",
      Created_by: "designer",
      current_stage: "cutting",
      style: "5 PART HIP HOP CAP",
      size: "BOOT DIE USE",
      billing_type: "GST",
      main_fabric_Front_type: "Twill Cotton",
      main_fabric_Back_type: "Twill Cotton",
      main_fabric_Middle_type: "Twill Cotton",
      main_fabric_Top_type: "Flexi stock",
      main_fabric_Under_type: "Flexi stock",
      main_fabric_Front_color: "CT109  BLACK",
      main_fabric_Back_color: "CT109  BLACK",
      main_fabric_Middle_color: "CT109  BLACK",
      main_fabric_Top_color: "FX9  BLACK",
      main_fabric_Under_color: "FX9  BLACK",
      visior_type: "HIPHOP : BLACK",
      piping_type: "",
      piping_color: "",
      priority: null,
      fusing: "null : color = IMP BLACK",
      eyelet_front: "NONE",
      eyelet_back: "DTM",
      eyelet_middle: "DTM",
      tapper: "null : color = BLACK",
      sweate_band_type: "THREE LAYER : color = BLACK",
      sweate_band_color: null,
      button: "BLACK 109",
      back_close_type: "PLASTIC SNAP BACK",
      back_closer_color: "BLACK",
      snapack: "",
      visor_stitch: "6 LINE",
      embr_Front: "3D PETISCO",
      embr_Back: "",
      embr_Left_Middle: "",
      embr_Right_Middle: "",
      embr_Visor: "",
      embr_Velcro: "",
      embr_Visor_Square: "",
      embr_One_Side_Emb: "",
      visor: null,
      velor: null,
      img1: "/media/images/petisco_logo_40_FINALOK_uOCKfBB.jpg",
      dispatch_date: null,
      special_instructions: "USE HANGERS LABLE",
      last_update: "designer",
      designer_update_date: "24/04/2024",
      cutting_update_date: null,
      secendory_update_date: null,
      accountent_update_date: null,
      production_update_date: null,
      emboridary_update_date: null,
      admin_update_date: null,
      last_update_time: "17:52:41",
      designer_update_time: "17:52:41",
      cutting_update_time: null,
      secendory_update_time: null,
      accountent_update_time: null,
      production_update_time: null,
      emboridary_update_time: null,
      admin_update_time: null,
      binder: "",
      sizing_strip: "",
      foam: "",
      brim_foam: null,
      buckle: "",
      elastic: "",
      full_closure_elastic: "",
      interlining: "",
      nonon: "",
      cap_button: "",
      sweat_band_holding: "",
      plastic_bag: "",
      carton: "",
      buckle_belt: "",
      o_ring: "",
      overlock: null,
      packing: null,
      insert: null,
      lables: null,
      tag: null,
      barcode: null,
      polythene_sticker: null,
      service: null,
      client: "HANGERS",
      key: 5665,
    },
  ];
  return (
    <Style>
      {showCountComp() && (
        <div className="countstage">
          {topcard_loading ? <>loading</> : <ShowCount cards={top_cards} />}
        </div>
      )}

      <CardStyle>
        <>
          <div className="title">Under Processing</div>
          <div style={{ display: "flex", width: "100%" }}>
            <MultidropDown client_company={client_company} />

            <MultidropDown />
          </div>
          <div style={{ width: "100%" }}>
            <AscDesc sort={"noncompleted"} />
            <Button onClick={() => setGenerateShow(true)}>
              Generate Quantity
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setRecordBig([]);
                setSelectedRowKeys([]);
              }}
            >
              Clear Selection
            </Button>
          </div>

          {loading ? (
            <Loader />
          ) : cards && cards?.results?.length > 0 ? (
            <>
              <CardsTable
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                recordBig={recordBig}
                setRecordBig={setRecordBig}
                count={cards?.count}
                tableKey={"under_processing_dashboard"}
                cards={underProcessCards}
                page={"jobcard_page"}
                client_company={client_company}
                fetchJob={fetchJob}
              />
            </>
          ) : (
            <div className="no-data"></div>
          )}
        </>
      </CardStyle>
      {(role == ROLES.ADMIN || role == ROLES.DESIGN) &&
        (completedCards?.length ? (
          <>
            <CardStyle>
              <>
                <div className="title">Completed</div>
                <div style={{ display: "flex", width: "100%" }}>
                  <MultidropDown
                    client_company={client_company_completed}
                    c={"client_company_completed"}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <AscDesc sort={"completed"} />
                </div>
                <CardsTable
                  tableKey={"complted_dashboard"}
                  cards={completedCards}
                  count={cardsCompleted?.count}
                  isCompleted
                  page={"jobcard_page_complete"}
                  client_company={client_company_completed}
                  fetchJob={loadingCompleted}
                />
              </>
            </CardStyle>
          </>
        ) : (
          <div className="no-data"></div>
        ))}
      {generateShow ? (
        <GenerateModel
          data={getUniqueColors2(recordBig)}
          recordBig={recordBig}
          performCancel={performCancel}
          handlePrint={handlePrint}
          componentRef={componentRef}
        />
      ) : null}
    </Style>
  );
};

export default CardListing;

const Style = styled.div`
  height: 100%;
  min-height: 60vh;
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    width: 400px;
    height: 400px;
    margin: auto;
    margin-top: 4rem;
    background: url(${process.env.PUBLIC_URL}/images/nothing.png) no-repeat;
    background-size: contain;
  }
`;
