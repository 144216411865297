import { Table } from 'antd';
import { useFetchJobcardsQuery } from 'features/store';
import React from 'react';
import styled from "styled-components";

const CreatedCap = () => {

  const { data: cards, isLoading: loading } = useFetchJobcardsQuery();
  console.log(cards?.filter((item)=>item.current_stage==="completed"
  ).map((s)=>s)
    )

    const completed =cards?.filter((item)=>item.current_stage).map((s)=>s)
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "index",
      render: (text, record, index) => index + 1,
      // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
    },
    
    {
      title: "Cap Name",
      dataIndex: "style",
    },
    {
      title: "Deliver Date",
      dataIndex: "deliver_date",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Front Type",
      dataIndex: "stylequantity",
      render: (text, record) => `${record.main_fabric_Front_type} : ${record.main_fabric_Front_color}`,
    },
    {
      title: "Back Type",
      dataIndex: "stylequantity",
      render: (text, record) => `${record.main_fabric_Back_type} : ${record.main_fabric_Back_color}`,
    },
    {
      title: "Middle Type",
      dataIndex: "stylequantity",
      render: (text, record) => `${record.main_fabric_Middle_type} : ${record.main_fabric_Middle_color}`,
    },
    {
      title: "Top Type",
      dataIndex: "stylequantity",
      render: (text, record) => `${record.main_fabric_Top_type} : ${record.main_fabric_Top_color}`,
    },
    {
      title: "Under Type",
      dataIndex: "stylequantity",
      render: (text, record) => `${record.main_fabric_Under_type} : ${record.main_fabric_Under_color}`,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      width: "15%",
      editable: true,
    },
  ];
    return (<>
    <h2 style={{margin:"30px 0px",textAlign:"center"}}>Created Caps</h2>
        <div style={{width:"75%",margin:"auto"}}>
              <Style>
        <Table
          bordered
          dataSource={completed}
          columns={columns}
          rowClassName="editable-row"
          className="my-table"
          //   pagination={{
              //     onChange: cancel,
              //   }}
              />
      </Style>
        </div>
              </>
    );
}

export default CreatedCap;


const Style = styled.div`
  .my-table {
    font-size: 16px;
  }

  .my-table .ant-table-header {
    background-color: var(--color-table-head);
  }

  .my-table .ant-table-thead > tr > th {
    background-color: var(--color-table-head);
    border: 0.1px solid #d9d9d9;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .my-table .ant-table-tbody > tr > td {
    border: 0.2px solid #ebebeb;
  }
`;
