import CapMaterialTable from "components/inventory/CapMaterialTable";
import { useFetchInventoryQuery } from "features/store";
import React from "react";
import styled from "styled-components";

const MaterialsStock = () => {
  const { data: dataSource, isLoading: loading } = useFetchInventoryQuery({
    type: "OTHER MATERIAL",
    // cap_material:"CARD BOARD"
  });
  return (
    <div className="material-div">
      <CapMaterialTable dataSource={dataSource} loading={loading} />
    </div>
  );
};

export default MaterialsStock;

