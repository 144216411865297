import { STAGES, STAGES_LIST } from "components/common/Constants";

export const getNextStage = (stage) => {
  let isValid = STAGES_LIST.includes(stage);
  if (!isValid) return "invalid";
  let index = STAGES_LIST.indexOf(stage);
  if (index === STAGES_LIST.length - 1) return "-";
  return STAGES_LIST[index + 1];
};

export const getPreviousStage = (stage) => {
  let isValid = STAGES_LIST.includes(stage);
  if (!isValid) return "invalid";
  let index = STAGES_LIST.indexOf(stage);
  if (index === 0) return "-";
  return STAGES_LIST[index - 1];
};

export const getFormalName = (stage) => {
  switch (stage) {
    case STAGES.CREATION:
      return "Creation";
    case STAGES.CUTTING:
      return "Cutting";
    case STAGES.EMBROIDARY:
      return "Embroidary";
    case STAGES.SECONDARY_PROCESS:
      return "Secondary Process";
    case STAGES.PRODUCTION:
      return "Production";
    case STAGES.ACCOUNT:
      return "Account";
    case STAGES.COMPLETED:
      return "Completed";
    default:
      return "-";
  }
};
