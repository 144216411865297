import {
    Table,
    Input,
    InputNumber,
    Popconfirm,
    Form,
    Button,
    Typography,
  } from "antd";
  import Loader from "components/common/Loader";
  import {
    useFetchInventoryQuery,
    useUpdateInventoryMutation,
  } from "features/store";
  import React, { useState } from "react";
  import EditableTable from "./EditableTable";
  import styled from "styled-components";
  import { TableTitleStyles } from "./Inventory.style";
  
  const Popcorn = ({ loading }) => {
    const { data: dataSource, isLoading } = useFetchInventoryQuery({
      cloth_type: "POPCORN",
    });
    const uniqueColors = [...new Set(dataSource?.map(item => item?.color_cloth?.color))];
    const codeArry = [...new Set(dataSource?.map(item => item?.color_cloth?.color_code))];
  
    // const {id, quantity, color_cloth} = dataSource
    // const {color	, cloth_type, type}=color_cloth
    const columns = [
      {
        title: "Sr. No.",
        dataIndex: "id",
        // render: (text, record, index) => index + 1
        // sorter: (a, b) => parseInt(a.id) - parseInt(b.id),
      },
      {
        title: "Type",
        dataIndex: ["color_cloth", "cloth_type"],
      },
      {
        title: "Color Code",
        dataIndex: ["color_cloth", "color_code"],
        filters: codeArry.map(client => ({
          text: client,
          value: client,
        })),
        onFilter: (value, record) => record.color_cloth.color_code === value.toUpperCase(),
        filterSearch: true,
      },
      {
        title: "Color",
        dataIndex: ["color_cloth", "color"],
        dataIndex: ["color_cloth", "color"],
        filters: uniqueColors.map(client => ({
          text: client,
          value: client,
        })),
        onFilter: (value, record) => record.color_cloth.color === value.toUpperCase(),
        filterSearch: true,
      },
      {
        title: "Previous Quantity",
        dataIndex: "previous_quantity",
      },
      {
        title: "Last Updates",
        dataIndex: "last_updated_date",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: "15%",
        editable: true,
      },
      {
        title: "Newly Added",
        width: "15%",
        editable: true,
        render:(_,record)=>(record.quantity-record.previous_quantity).toFixed(4).replace(/\.?0+$/, '')

      },
    ];
    const inventory = "invetetetet";
    return (
    
        <div className="inventory-table">
          <TableTitleStyles>POPCORN</TableTitleStyles>
          <EditableTable
            inventory={inventory}
            loading={isLoading}
            dataSource={dataSource}
            columns={columns}
          />
          
        </div>
    
    );
  };
  
  export default Popcorn;
  
  const Style = styled.div`
  
  `;
  