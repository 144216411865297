import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./auth/userSlice";
import JobCardReducer from "./jobinv/JobCardSlice";
import { allApi} from "./jobinv/AllApi";
import colorReducer from "../features/jobinv/colorSlice"
const store = configureStore({
  reducer: {
    user: userReducer,
    color:colorReducer,
    JobCard: JobCardReducer,
    [allApi.reducerPath]: allApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(allApi.middleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

export {
  
  useFetchJobcardsQuery,
  useFetchTopCardCountQuery,
  useFetchJobcardsCompletedQuery,
  useGetCompanyNameFromJobcardQuery,
  useFetchClientJobQuery,
  useFetchCoordinatorsJobQuery,
  useFetchDeliveryDateJobQuery,



  useGetJobcardQuery,
  useGetClientsQuery,
  useGetDropdownsQuery,
  useCreateJobcardMutation,
  useUpdateJobcardMutation,
  useFetchInventoryQuery,
  useUpdateInventoryMutation,
  useFetchCapsQuery,
  useFetchColorsQuery,
  useUpdateCapMutation,
  useCreateCapMutation,
  useFetchManufacture_capQuery,
  useUpdateManufacturerMutation,
  useGetManufacture_capQuery,
  useGetCompanyNameFromJobcardCompletedQuery,



} from "./jobinv/AllApi";
// export {
//   useFetchInventoryQuery, useUpdateInventoryMutation
// } from "./inventory/InventoryApi"
