import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "components/common/api";

// userActions.js
export const userLogin = createAsyncThunk(
  "user/login",
  async ({ username, password }, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        // `${url}/auth/jwt/create`,
        `${url}/api/token/`,
        { username, password },
        config
      );
      // store user's token in local storage
      const { dispatch } = thunkAPI;
      localStorage.setItem("userToken", data.access);
      localStorage.setItem("refreshToken", data.refresh);
      dispatch(getUser());
      return { data, userToken: data.access };
    } catch (err) {
      // return custom error message from API if any
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  "api/account/me",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`${url}/api/userinfo`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          Accept: "application/json",
        },
      });
      const data = await res.json();
      localStorage.setItem("user", JSON.stringify({ ...data }));
      console.log("getuser", data);
      if (res.status === 200) {
        return { ...data };
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const cap_table_page = createAsyncThunk(
  "cap_table_page",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("cap_table_page", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const jobCard_page = createAsyncThunk(
  "jobcard_page",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("jobcard_page", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const jobCard_pageComplete = createAsyncThunk(
  "jobcard_page_complete",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("jobcard_page_complete", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Sort_jobCard = createAsyncThunk(
  "Sort_jobCard",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Sort_jobCard_completed = createAsyncThunk(
  "Sort_jobCard_completed",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Client_filter = createAsyncThunk(
  "Client_filter",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Client_filter", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Client_filter_completed = createAsyncThunk(
  "Client_filter_completed",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Client_filter", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const Stage_filter = createAsyncThunk(
  "Stage_filter",
  async (username, thunkAPI) => {
    try {
      // configure header's Content-Type as JSON

      console.log("Stage_filter", username);
      return username;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
export const checkAuth = createAsyncThunk(
  "api/account/verify",
  async (_, thunkAPI) => {
    const access = localStorage.getItem("userToken");
    if (!access) return thunkAPI.rejectWithValue("No token");

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      token: access,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(
        // `${url}/api/token/verify/`,
        `${url}/api/token/verify/`,
        requestOptions
      );

      const data = await res.json();
      console.log("checkauth", data);

      if (res.status === 200) {
        const { dispatch } = thunkAPI;

        dispatch(getUser());
        // localStorage.getItem("userToken", access);
        return data;
      } else {
        localStorage.removeItem("userToken");
        localStorage.removeItem("refreshToken");
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// initialize userToken from local storage
const userToken = localStorage.getItem("userToken", "role")
  ? localStorage.getItem("userToken", "role")
  : null;

const initialState = {
  loading: true,
  user: null,
  userToken: localStorage.getItem("userToken"),
  success: false,
  isAuthenticated: false,
  error: null,
  loginStatus: "",
  loginError: "",
  cap_table_page: 1,
  jobcard_page: 1,
  jobcard_page_complete: 1,
  client_filter: "",
  client_filter_completed: "",
  stage_filter: "",
  sort_job: -1,
  sort_job_completed: -1,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state, action) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userToken");
      localStorage.removeItem("user");
      return {
        ...state,
        userToken: "",
        user: "",
        loading: false,
        cap_table_page: 1,
        isAuthenticated: false,
        jobcard_page: 1,
        jobcard_page_complete: 1,
        client_filter: "",
        stage_filter: "",
        client_filter_completed: "",
        sort_job: -1,
        sort_job_completed: -1,
      };
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(userLogin.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userToken = payload.userToken;
        state.isAuthenticated = true;
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      //   get user
      .addCase(getUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        // state.user = action.payload;
      })
      .addCase(getUser.rejected, (state) => {
        state.loading = false;
      })
      //   check authenticated or not authenticated
      .addCase(checkAuth.pending, (state) => {
        state.checkAuthLoading = true;
      })
      .addCase(checkAuth.fulfilled, (state) => {
        state.checkAuthLoading = false;
        state.isAuthenticated = true;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.checkAuthLoading = false;
        state.loading = false;
        state = action.payload;
      })
      .addCase(cap_table_page.fulfilled, (state, action) => {
        state.cap_table_page = action.payload;
      })
      .addCase(jobCard_page.fulfilled, (state, action) => {
        state.jobcard_page = action.payload;
      })
      .addCase(Client_filter.fulfilled, (state, action) => {
        state.client_filter = action.payload;
      })
      .addCase(Client_filter_completed.fulfilled, (state, action) => {
        state.client_filter_completed = action.payload;
      })
      .addCase(Stage_filter.fulfilled, (state, action) => {
        state.stage_filter = action.payload;
      })
      .addCase(Sort_jobCard.fulfilled, (state, action) => {
        state.sort_job = action.payload;
      })
      .addCase(Sort_jobCard_completed.fulfilled, (state, action) => {
        state.sort_job_completed = action.payload;
      })
      .addCase(jobCard_pageComplete.fulfilled, (state, action) => {
        state.jobcard_page_complete = action.payload;
      });
    // logout
    // .addCase(logout.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(logout.fulfilled, (state) => {
    //   state.loading = false;
    //   state.userToken = false;
    //   state.user = null;
    // })
    // .addCase(logout.rejected, (state) => {
    //   state.loading = false;
    // });
    // register user reducer...
  },
});
// export default userSlice.reducer;

export const { logout } = userSlice.actions;

// export default userSlice.reducer;
export default userSlice.reducer;
