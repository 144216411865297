
import React from "react";
import { MdClose } from "react-icons/md";
import './model.css'

const CrossButton = ({performCancel}) => {
  return (
    <>
      <div
        style={{
        background:"red",
          color: "white",
          padding: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "30px",
          height: "30px",
          borderRadius:"360px",
          position:"absolute",
          top:"-10px",
          right:"-10px",
          cursor:"pointer"

        }}
        onClick={() => performCancel()}
      >
        <MdClose />
      </div>
    </>
  );
};

export default CrossButton;
