import { ROLES, STAGES } from "components/common/Constants";
import React, { Component } from "react";
import { useState } from "react";
import styled from "styled-components";

export default function ShowUpdateCount({ cards }) {
  // const [items, setItems] = useState(cards);

  // const filetrStage = (stage) => {
  //   const updatedItems = cards?.filter((curElem) => {
  //     return curElem.current_stage === stage;
  //   });
  //   setItems(updatedItems);
  //   
  // };

  const getUpdateDateFromRole = (role) => {
    switch (role) {
      case ROLES.DESIGN:
        return "designer_update_date";
      case ROLES.CUTTING:
        return "cutting_update_date";

      case ROLES.SECONDARY_PROCESS:
        return "secendory_update_date";

      case ROLES.EMBROIDARY:
        return "emboridary_update_date";

      case ROLES.PRODUCTION:
        return "production_update_date";

      case ROLES.ACCOUNT:
        return "accountent_update_date";

      default:
        return "";
    }
  };

  const countUpdatedCards = (role) => {
    const _countUpdatedCards = cards?.filter(
      (card) => card[getUpdateDateFromRole(role)]
    );
    
    return _countUpdatedCards.length;
  };

  const countUpdatedQuantity = (role) => {
    let quantity = 0;
    cards
      ?.filter((card) => card[getUpdateDateFromRole(role)])
      ?.forEach((card) => {
        quantity += parseInt(card.quantity || 0);
      });
    return quantity;
  };

  const filteredItems = cards?.filter((item) => {
    return item.current_stage !== "completed";
  });

  

  const countQuant = filteredItems?.reduce((totalQuantity, filteredItem) => {
    return filteredItem.quantity + totalQuantity;
  }, 0);

  

  return (
    <>
      <Style>
        <div>
          <div className="stages_card_show">
            <div className="content">
              Design
              <span>Cards:{countUpdatedCards(ROLES.DESIGN)}</span>
              <span>Quantity: {countUpdatedQuantity(ROLES.DESIGN)}</span>
            </div>
            <div className="content">
              Cutting
              <span>Cards:{countUpdatedCards(ROLES.CUTTING)}</span>
              <span>Quantity:{countUpdatedQuantity(ROLES.CUTTING)}</span>
            </div>
            <div className="content">
              Secondary Process
              <span>Cards: {countUpdatedCards(ROLES.SECONDARY_PROCESS)}</span>
              <span>
                Quantity: {countUpdatedQuantity(ROLES.SECONDARY_PROCESS)}
              </span>
            </div>
            <div className="content">
              Embroidary
              <span>Cards: {countUpdatedCards(ROLES.EMBROIDARY)}</span>
              <span>Quantity: {countUpdatedQuantity(ROLES.EMBROIDARY)}</span>
            </div>
            <div className="content">
              Production{" "}
              <span>Cards: {countUpdatedCards(ROLES.PRODUCTION)}</span>
              <span>Quantity: {countUpdatedQuantity(ROLES.PRODUCTION)}</span>
            </div>

            <div className="content">
              Account
              <span>Cards: {countUpdatedCards(ROLES.ACCOUNT)}</span>
              <span>Quantity: {countUpdatedQuantity(ROLES.ACCOUNT)}</span>
            </div>
            {/* <div className="content">
              Completed
              <span>Cards: {countUpdatedCards("completed")}</span>
              <span>Quantity: {countUpdatedQuantity("completed")}</span>
            </div> */}
            <div className="content">
              Total Quantity <span>{countQuant}</span>
            </div>
          </div>
        </div>
      </Style>
    </> //
  );
}

const Style = styled.div`
  text-transform: uppercase;
  margin: 1rem 2rem;
  .stages_card_show {
  
    display:flex;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: streach;
    justify-content: space-between;
  }
  .content {
    background: #ffffff;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 1.2rem;
    padding: 17px;
    font-weight: bold;

     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    span {
      font-weight: 500;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 450px) {
    .stages_card_show {
      /* display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      justify-content: center;
      grid-gap: 0.5em; */
    }
    .content {
      background: #f6f6f6;
      /* overflow: hidden; */
      /* border-radius: 10px;
      box-shadow: 0 0 6px 4px #d0d0d0;
      text-align: center;
      font-size: 1.2rem;
      padding: 1rem 0;
      width: 200px; */
      span {
        /* font-weight: bold;
        display: flex;
        justify-content: center; */
      }
    }
  }
`;
