import React, { useEffect } from "react";
import CardListing from "../CardListing/CardListing";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import Footer from "components/footer/Footer";
import { useNavigate } from "react-router-dom";
import { getJobCards, roleSelector } from "features/jobinv/JobCardSlice";
import { ROLES } from "components/common/Constants";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector(roleSelector);

  // useEffect(() => {
  //   dispatch(getJobCards());
  // }, []);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "grid",
        gridTemplateRows: "1fr auto auto",
      }}>
      <CardListing />
      {/* roles which can create new job card */}
      {(role === ROLES.ADMIN || role === ROLES.DESIGN) && (
        <Button
          style={{
            margin: "2rem",
            background: "var(--color-primary)",
            color: "var(--light)",
            fontSize: "1.2rem",
            padding: ".1rem 2rem",
            borderRadius: ".3rem",
            width: "fit-content",
          }}
          type="default"
          onClick={() => navigate("/create")}>
          Create A New Job Card
        </Button>
      )}
      <Footer />
    </div>
  );
};

export default Home;
