import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import JobcardDataService from "components/services/Data.service";
import { url } from "components/common/api";
import { message } from "antd";
import { ROLES, STAGES } from "components/common/Constants";
import appApi from "components/common/apis/appApi";

export const getJobCards = createAsyncThunk(
  "jobcard/getJobCards",
  async (_, thunkAPI) => {
    try {
      const res = await fetch(`${url}/api/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          Accept: "application/json",
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

// `${url}/api/detail/${id}`
export const getJobCard = createAsyncThunk("jobcard/getJobCard", async (id) => {
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    "content-Type": "application/json",
  };
  const response = await appApi.get(`${url}/api/detail/${id}`, {
    headers: headers,
  });
  console.log("response: ", response);

  return response.data;
});

export const CreateJobCard = createAsyncThunk(
  "jobcard/createJobCard",
  async (values) => {
    console.log(values);
    var formdata = new FormData();
    // formdata.append("client", "1");
    Object.keys(values).map((form_key) =>
      formdata.append(form_key, values[form_key] || "")
    );

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      redirect: "follow",
    };

    const response = await fetch(`${url}/api/addjobcard`, requestOptions);
    const data = await response.json();
    message.success("Job Created Successfully");
    return data;
  }
);

export const fetchClients = async () => {
  var myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    `Bearer ${localStorage.getItem("userToken")}`
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(`${url}/api/clients`, requestOptions);
  return (await response.json()) || [];
};

export const GetAllClients = createAsyncThunk(
  "jobcard/fetchAllClients",
  async () => {
    const clients = (await fetchClients()) || [];
    return clients;
  }
);

// main array of all dropdowns
export const allDropDowns = createAsyncThunk("cards/allDropdowns", async () => {
  const response = await appApi.get(`/api/array`);
  console.log(response.data);
  return response.data;
});

export const inventoryData = createAsyncThunk("cards/inventory", async () => {
  const response = await appApi.get("http://localhost:3002/data");
  console.log(response.data);
  return response.data;
});

export const updateInventory = createAsyncThunk(
  "jobcard/updateInventory",
  async (values, onUpdate) => {
    console.log(values);
    const id = values.id;
    var formdata = new FormData();

    Object.keys(values).map((form_key) =>
      formdata.append(form_key, values[form_key] || "")
    );

    var myHeaders = new Headers();
    myHeaders
      .append
      // "Authorization",
      // `Bearer ${localStorage.getItem("userToken")}`
      ();

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    console.log("UpdateJobCard: ", UpdateJobCard);

    const response = await fetch(
      `http://localhost:3002/data/${id}`,
      requestOptions
    );
    if (response.ok) {
      const data = await response.json();
      message.success("Job Updated Successfully");
      return data;
    }
    return response;
  }
);
export const UpdateJobCard = createAsyncThunk(
  "jobcard/updateJobCard",
  async (values, onUpdate) => {
    console.log(values);
    const id = values.id;
    var formdata = new FormData();

    Object.keys(values).map((form_key) =>
      formdata.append(form_key, values[form_key] || "")
    );

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    console.log("UpdateJobCard: ", UpdateJobCard);

    const response = await fetch(`${url}/api/update/${id}`, requestOptions);
    if (response.ok) {
      const data = await response.json();
      message.success("Job Updated Successfully");
      return data;
    }
    return response;
  }
);

const initialState = {
  JobCards: [],
  selectedCard: [],
  inventory: [],
  selections: {},
  loading: true,
  error: null,
  body: "",
  clients: null,
};

const JobCardSlice = createSlice({
  name: "JobCard",
  initialState,
  reducers: {
    setEdit: (state, action) => {
      state.edit = action.payload.edit;
      state.body = action.payload.body;
    },
  },
  extraReducers: {
    [getJobCards.pending]: (state) => {
      state.loading = true;
    },
    [getJobCards.fulfilled]: (state, action) => {
      state.loading = false;
      state.JobCards = action.payload;
    },
    [getJobCards.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getJobCard.pending]: (state, action) => {
      state.loading = true;
    },
    [getJobCard.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedCard = action.payload;
    },
    [getJobCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
    },
    [GetAllClients.pending]: (state, action) => {
      state.loading = true;
    },
    [GetAllClients.fulfilled]: (state, action) => {
      state.loading = false;
      state.clients = action.payload;
    },
    [GetAllClients.rejected]: (state, action) => {
      state.loading = false;
      state.error = [action.payload];
    },

    [CreateJobCard.pending]: (state) => {
      state.loading = true;
    },
    [CreateJobCard.fulfilled]: (state, action) => {
      state.loading = false;
      state.JobCards = [action.payload];
    },
    [CreateJobCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [UpdateJobCard.pending]: (state) => {
      state.loading = true;
    },
    [UpdateJobCard.fulfilled]: (state, action) => {
      state.loading = false;
      state.selectedCard = action.payload;
    },
    [UpdateJobCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [allDropDowns.pending]: (state) => {
      state.loading = true;
    },
    [allDropDowns.fulfilled]: (state, action) => {
      state.loading = false;
      state.selections = action.payload;
    },
    [allDropDowns.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [inventoryData.pending]: (state) => {
      state.loading = true;
    },
    [inventoryData.fulfilled]: (state, action) => {
      state.loading = false;
      state.inventory = action.payload;
    },
    [inventoryData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateInventory.pending]: (state) => {
      state.loading = true;
    },
    [updateInventory.fulfilled]: (state, action) => {
      state.loading = false;
      state.inventory = action.payload;
    },
    [updateInventory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setEdit } = JobCardSlice.actions;
export const getSelectedcard = (state) => state.JobCard.selectedCard;
const { reducer } = JobCardSlice;
export default JobCardSlice.reducer;

export const roleSelector = (state) => state.user?.user?.roll || null;

// export const roleSelector = (state) => ROLES.CUTTING;
