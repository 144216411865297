import Loader from "components/common/Loader";
import { getJobCard } from "features/jobinv/JobCardSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import JobCardForm from "./JobCardForm";
import { useGetJobcardQuery } from "features/store";
import { message } from "antd";

function UpdateJobcard(props) {
  const { id } = useParams();
  const { selections, loading } = useSelector((state) => state?.JobCard);
  const { data: selectedCard, isLoading, isError } = useGetJobcardQuery(id);
  console.log("selections: ", selections);
  console.log("loading: ", loading);
  console.log("JobCard: ", selectedCard);

  if (isError) message.error("Failed to fetch job card details");

  if (isLoading) return <Loader />;
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getJobCard(id));
  // }, []);

  // there is loading issue, when jobcardForm is viewed still the state shows loading

  return (
    <JobCardForm
      selections={selections}
      loading={loading}
      formValues={{ ...selectedCard, id: parseInt(id) }}
    />
  );
}

export default UpdateJobcard;
