import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "features/auth/userSlice";
import { ROLES, STAGES } from "components/common/Constants";
import { fetchClients, roleSelector } from "features/jobinv/JobCardSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const role = useSelector(roleSelector);

  const showLinkCm = () =>
    [ROLES.ADMIN, ROLES.DESIGN, ROLES.ACCOUNT].includes(role);

  const showLinkComp = () => showLinkCm();
  const reportLink = (
    <NavLink className="nav-link" to="/reports">
      Report
    </NavLink>
  );

  const authLinks = (
    <>
      <span
        className="nav-link linkss"
        to="#!"
        onClick={() => dispatch(logout())}>
        Welcome: <b>{user?.user}</b>
      </span>
      <NavLink className="nav-link" to="#!" onClick={() => dispatch(logout())}>
        Logout
      </NavLink>
    </> //
  );
  const guestLinks = (
    <>
      <NavLink className="nav-link" to="/login">
        Login
      </NavLink>
    </> //
  );
  return (
    <>
      <Style>
        <div className="nav">
          <span className="logo">
            <Link to="/">Apparels</Link>
          </span>
          <div className="links">
            <NavLink className="nav-link" to="/">
              Dashboard
            </NavLink>
            <NavLink className="nav-link" to="/inventory">
              Inventory
            </NavLink>
            {showLinkComp() && reportLink}
            {user ? authLinks : guestLinks}
          </div>
        </div>
      </Style>
    </> //
  );
};

export default Navbar;

const Style = styled.div`
  background-color: var(--color-primary);
  .logo {
    font-weight: bold;
    font-size: 1.6rem;
    color: var(--light);
    a {
      text-decoration: none;
      color: var(--light);
    }
  }
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 3rem;
  }
  .links {
    display: flex;
    align-items: space-between;
  }
  .links a.active {
    text-decoration: underline;
  }
  .links a {
    color: #fff;
    font-size: 1.2rem;
  }
  span {
    color: #fff;
    font-size: 1.2rem;
  }
  .nav-link {
    margin: 0 1rem;
    text-decoration: none;
  }
  .linkss.active {
    text-decoration: none;
  }

  @media screen and (max-width: 458px) {
    .logo {
      font-size: 1rem;
    }
    .links a {
      color: #fff;
      font-size: 0.8rem;
    }
    .nav-link {
      margin: 0 0.3rem;
      text-decoration: none;
    }
  }
`;
