import styled from "styled-components";

export const LoginStyle = styled.div`
  .error {
    background: white;
    color: rgba(255, 0, 0, 0.8);
    padding: 0.5rem;
    margin: 1rem 0;
  }

  background-color: rgba(128, 128, 128, 0.2);
  height: 100vh;
  .login-section {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; */
  }
  .login-container {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    h1 {
      font-size: 2.5rem;
      padding: 2rem 0.5rem;
    }
  }
  .forgot_password {
    background-color: rgba(255, 255, 255, 10);
    margin-top: 1rem;
    padding: 0.5rem 0;
    border-radius: 2px;
  }

  .form {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    text-align: center;
    /* width: 100%; */
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 10);
    border-radius: 2px;
    padding: 2rem 1rem;
  }
  .form input {
    width: 90%;
    padding: 0.5rem;
    margin: 0.5rem;
    border: none;
    border: 2px solid #dadada;
    border-radius: 2px;
  }
  .form input:focus {
    border: 2px solid var(--color-primary);
    outline: none;
    background: var(--light);
  }

  .form-button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const Button = styled.button`
  background-color: var(--color-primary);
  color: var(--light);
  padding: 8px 30px;
  width: 90%;
  border: none;
  border-radius: 2px;
  margin: 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
`;
